/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import {Button} from '@mui/material';
import {paths} from 'paths';
import {API_ADVERTISING_AREA_FOR_BANNER, API_STATUSES} from 'config/api/constants';
import {advertisingAreaUIModel} from 'shared/models/advertisingArea.model';
import {bannerWithRetailer} from 'shared/models/banner.model';
import {branch} from 'shared/models/branch.model';
import {specialRoles} from 'shared/models/permissions.model';
import {IRootState} from 'shared/reducers';
import {fetchBranchesList} from 'shared/reducers/branchesList';
import {setPreferredCountry} from 'shared/reducers/userProfileActions';
import {useFormatMessage} from 'utils/translate';
import {getId} from 'utils/routing';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay/LoadingOverlay';
import {CountryBannerSelector} from 'components/Selectors';
import {PaperX} from 'components/PaperX';
import AdvertisingAreasIndex from './AdvertisingAreasIndex';
import AdvertisingAreaSummary from './AdvertisingAreaSummary';

const AdvertisingAreasList = () => {
    const dispatch = useDispatch();
    const areasAPIGet = useApi('get', [], {errMsg: 'advertisingAreaList.err'});
    const translate = useFormatMessage();

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const userRoles = useSelector((state: IRootState) => state.userPermissions.specialRoles);

    const isAllowed: boolean = userRoles.includes(specialRoles.RETAILER_HIERARCHY_MANAGEMENT);

    const [bannerId, setBannerId] = useState<string>('');
    const [selectedArea, setSelectedArea] = useState<advertisingAreaUIModel>(null);
    const [branchesForArea, setBranchesForArea] = useState<branch[]>([]);

    const branchesData = useSelector((state: IRootState) => state.branchesList.data);
    const branchesStatus = useSelector((state: IRootState) => state.branchesList.status);

    useEffect(() => {
        const bannerId: string = getId();
        if (bannerId) setBannerId(bannerId);
    }, []);

    useEffect(() => {
        if (bannerId) {
            getAreasData(bannerId);
            setSelectedArea(null);
            setBranchesForArea([]);
            dispatch(fetchBranchesList(bannerId,selectedLanguage));
        }
    }, [bannerId]);

    useEffect(() => {
        if (selectedArea === null || selectedArea === undefined) {
            setBranchesForArea([]);
        } else {
            setBranchesForArea(branchesData.filter((item) => selectedArea.branchIds.find((branchId) => branchId === item.id )));
        }
    }, [selectedArea]);

    const getAreasData = (bannerId: string) => areasAPIGet.call(API_ADVERTISING_AREA_FOR_BANNER(bannerId));

    const handleBannerChange = (banner: bannerWithRetailer) => setBannerId(banner ? banner.bannerId : null);

    const handleCountryChange = (value: string) => dispatch(setPreferredCountry(value));

    const showLoading = (): boolean => branchesStatus === API_STATUSES.PENDING || areasAPIGet.status === API_STATUSES.PENDING;

    return (
      <div className="viewRoot">
          <div className="viewport">
              <LoadingOverlay show={showLoading()}/>
              <div className="viewContainer _directionCol">
                  <PaperX>
                      <CountryBannerSelector className="bannerWithCountry"
                                             country={countryMarket.preferredCountry}
                                             bannerId={bannerId}
                                             onCountryChange={handleCountryChange}
                                             onBannerChange={handleBannerChange}
                      />
                  </PaperX>
                  <div className="_directionRow">
                      <AdvertisingAreasIndex selectedArea={selectedArea} areas={areasAPIGet.data} onAreaClick={setSelectedArea} canEdit={isAllowed}/>
                      <AdvertisingAreaSummary branchesForArea={branchesForArea} selectedArea={selectedArea}/>
                  </div>
              </div>
          </div>
          <Footer
              actionsRight={
                  <>
                      {isAllowed && <>
                        <Link to={`${paths.advertisingArea}?id=${selectedArea ? selectedArea.id : ''}`}>
                            <Button variant="contained" color="primary" disabled={!selectedArea}>{translate({id: 'a.edit'})}</Button>
                        </Link>
                        <Link to={bannerId ? `${paths.advertisingArea}?bannerId=${bannerId}` : paths.advertisingArea}>
                            <Button variant="contained" color="primary">{translate({id: 'advertisingArea.create'})}</Button>
                        </Link></>}
                      <Link to={paths.masterData}>
                          <ButtonClose/>
                      </Link>
                  </>
              }
          />
      </div>
    );
};

export default AdvertisingAreasList;