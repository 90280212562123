import { combineMultiWordUnit, isNumeric } from './sortCapacities';

export const tradingUnitsComparator = (a: string, b: string, locale: string): number => {
  // compareFn(a, b) return value	sort order
  // > 0	sort a after b, e.g. [b, a]
  // < 0	sort a before b, e.g. [a, b]
  // === 0	keep original order of a and b
  if (!a && b) return 1;
  if (a && !b) return -1;
  if ((!a && !b) || (typeof a !== 'string' || typeof b !== 'string')) return 0;

  const aChunks = combineMultiWordUnit(a.split(' '));
  const bChunks = combineMultiWordUnit(b.split(' '));

  let result = 0;

  for (let i = 0; i < aChunks.length; i++) {
    const aChunk = aChunks[i];
    const bChunk = bChunks[i];

    if (aChunks.length === 2 && aChunks.length === bChunks.length) {

      if (isNumeric(aChunk) && isNumeric(bChunk)) {
        const floatA = parseFloat(aChunk);
        const floatB = parseFloat(bChunk);
        if (floatA === floatB) {
          // proceed to next chunk
          continue
        } else {
          result = floatA - floatB
          break
        }
      }

      result = aChunk.localeCompare(bChunk, locale); // for comparing units
    } else {
      // regex returned unexpected results, let's sort lexicographically
      console.warn('unexpected parsing of trading unit attribute: ', aChunks, bChunks)
      result = aChunk.localeCompare(bChunk, locale);
    }
  }
  return result;
}