export const API_LEAFLET_MANAGEMENT_LEAFLETS = 'api/leaflet-management/leaflets';
// response model: leafletModel in src/shared/models/leaflet.model.ts
// PUT payload model: leafletPayloadModel in src/shared/models/leaflet.model.ts
// POST payload model: form data, used to upload leaflet

export const API_LEAFLET_MANAGEMENT_LEAFLETS_OVERVIEW = 'api/leaflet-management/leaflets/overview';
//response model: printMediaOverviewTableRow in src/shared/models/leaflet.model.ts

export const API_LEAFLET_MANAGEMENT_FRAMES = 'api/leaflet-management/frames';
// response model: frame in src/shared/models/frame.model.ts

export const API_LEAFLET_MANAGEMENT_ADVERTISEMENTS = 'api/leaflet-management/advertisements';
// response model: NONE.
// POST payload model: framePromotionsPayload in src/shared/models/promotion.model.ts

export const API_GET_ADVERTISEMENT_CREATORS = (countryId: string) => `api/delegation/advertisement-management/advertisement/creators?countryId=${countryId}`;
// response model: string[]

export const API_ADVERTISEMENTS_OVERVIEW = 'api/delegation/advertisement-management/advertisement/overview';
// response model: advertisementsOverviewResponseModel in src/shared/models/advertisement.model.ts
// GET params: frameFilters in src/shared/models/advertisement.model.ts

export const API_ADVERTISEMENTS_OVERVIEW_USER = 'api/delegation/advertisement-management/advertisement/user';
// response model: advertisementsOverviewResponseModel in src/shared/models/advertisement.model.ts
// GET params: myFrameFilters in src/shared/models/advertisement.model.ts

export const API_ADVERTISEMENTS_OVERVIEW_MY_TEAM = 'api/delegation/advertisement-management/advertisement/my-team';
// response model: advertisementsOverviewResponseModel in src/shared/models/advertisement.model.ts
// GET params: frameFilters in src/shared/models/advertisement.model.ts

export const API_LEAFLET_MANAGEMENT_PUBLICATION_OVERVIEW = '/api/leaflet-management/leaflets/publication/upload';
// response model: {leafletId: string}
// POST payload model: form data with country param, used to upload publication overview

export const API_LEAFLET_MANAGEMENT_LEAFLET_DELETE = (leafletId: string) => `api/leaflet-management/leaflets/${leafletId}`;
//response model: NONE, payload model: NONE, just DELETE call

export const API_LEAFLET_MANAGEMENT_LEAFLET_FRAME_DESCRIPTION_DETAILS = (leafletId: string, pageNumber: number) => `api/leaflet-management/leaflets/${leafletId}/${pageNumber}/frameDescriptionDetails`;
// response model: leafletModelWithPageData in src/shared/models/leaflet.model.ts
// @TODO check what's the content of pageData field, esp themeSeasons

export const API_LEAFLET_MANAGEMENT_STATS_PROMOTIONS = (countryId: string) => `api/leaflet-management/statistics/promotions?countryId=${countryId}`;
//response model: statisticsResponseModel in src/shared/models/statistics.model.ts

export const API_LEAFLET_MANAGEMENT_STATS_MEDIA = (countryId: string) => `api/leaflet-management/statistics/media?countryId=${countryId}`;
//response model: statisticsResponseModel in src/shared/models/statistics.model.ts

export const API_IMAGE_MANAGEMENT_FRAMES = 'api/image-management/frames';
// response model: image blob

export const API_IMAGE_FOR_PRODUCT = (productId: string) => `/api/leaflet-management/products/${productId}/frames`;
// response model: string[]

export const API_MASTER_DATA_RETAILERS = 'api/master-data/retailers';
// response model: dictRetailersResponseModel in src/shared/models/dict.model.ts
// PUT POST payload: retailer in src/shared/models/retailer.model.ts

export const API_BRANCHES_FOR_RETAILER = (retailerId: string, lang: string) => `api/master-data/retailers/${retailerId}/branches?lang=${lang}`;
// response model: branchPerRetailerResponseModel in src/shared/models/dict.model.ts

export const API_BRANCHES_FOR_BANNER = (bannerId: string, lang: string) => `api/master-data/banners/${bannerId}/branches?lang=${lang}`;
// response model: branchesPerBannerResponseModel in src/shared/models/dict.model.ts

export const API_BANNER_DETAILS = (bannerId: string) => `api/master-data/banners/${bannerId}`;
// response model: banner in src/shared/models/banner.model.ts

export const API_MASTER_DATA_BANNERS = 'api/master-data/banners';
// response model none;
// PUT POST payload: banner in src/shared/models/banner.model.ts

export const API_BRANCH_DETAILS = (branchId: string, lang: string) => `api/master-data/branches/${branchId}?lang=${lang}`;
// response model: branchDetailsResponseModel in src/shared/models/branch.model.ts

export const API_MASTER_DATA_BRANCHES = 'api/master-data/branches';
// response model: none;
// PUT POST payload: branchDetailsResponseModel in src/shared/models/branch.model.ts

export const API_REGIONS = 'api/master-data/regions';
// response model: regionResponseModel in src/shared/models/region.model.ts

export const API_MASTER_DATA_NIELSEN_REGIONS = 'api/master-data/nielsenRegions'; // @TODO cant do anything as nielsen regions not available right now

export const API_DICTIONARIES = 'api/master-data/dictionaries';
// response model: dictionariesResponseModel in src/shared/models/dictionaries.model.ts

export const API_DICTIONARY_BY_ID = (dictionaryType: string) => `api/master-data/dictionaries/${dictionaryType}`;
// response model: dictionaryWithElements in src/shared/models/dictionaries.model.ts
// @TODO  can we maybe change it to dictionaryId in swagger?

export const API_DICTIONARY_ELEMENT_BY_ID = (dictionaryElementId: string) => `api/master-data/dictionary-elements/${dictionaryElementId}`;
// response model: dictionaryElement in src/shared/models/dictionaries.model.ts

export const API_MASTER_DATA_DICTIONARY_ELEMENTS = 'api/master-data/dictionary-elements';
// response model: NONE.
// PUT / POST payload model: dictionaryElementPayload in src/shared/models/dictionaries.model.ts

export const API_MASTER_DATA_INDUSTRY = 'api/master-data/dictionary/industry';
// response model: dictionaryWithElements in src/shared/models/dictionaries.model.ts

export const API_GET_BRANDS = (marketId: string): string => `api/product-data/brands?marketId=${marketId}`;
// response model: brandsResponseModel in src/shared/models/brand.model.ts

export const API_GET_BRANDS_LIST = '/api/product-data/brands/search';
// response model: brandsResponseModel in src/shared/models/brand.model.ts

export const API_GET_BRAND = (brandId: string): string => `api/product-data/brands/${brandId}`;
// response model: brand in src/shared/models/brand.model.ts

export const API_GET_STRUCTURED_BRAND = (structuredBrandId: string): string => `api/product-data/brands/structured/${structuredBrandId}`;
// response model: structuredBrand in src/shared/models/brand.model.ts

export const API_PRODUCT_DATA_STRUCTURED_BRAND_POST = 'api/product-data/brands/structured';
// POST payload model: structuredBrandPayload in src/shared/models/brand.model.ts
// response model: structuredBrandResponse in src/shared/models/brand.model.ts

export const API_PRODUCT_DATA_STRUCTURED_BRAND_PUT = (structuredBrandId: string) => `api/product-data/brands/structured/${structuredBrandId}`;
// PUT payload model: structuredBrandPayload in src/shared/models/brand.model.ts

export const API_CREATE_BRAND = 'api/product-data/brands';
// post payload model: basicBrandPayload in src/shared/models/brand.model.ts
// response model: basicBrandResponse in src/shared/models/brand.model.ts
// duplicate response model: basicBrandDuplicateResponseModel in src/shared/models/brand.model.ts

export const API_UPDATE_BRAND = (brandId: string): string => `api/product-data/brands/${brandId}`;
// PUT payload model: basicBrandPayload

export const API_GET_PRODUCT_DETAILS = (productId: string, lang: string) => `api/product-data/products/${productId}?lang=${lang}`;
// response model: productDetailsResponseModel in src/shared/models/product.model.ts

export const API_PRODUCT_DATA_PRODUCTS = 'api/product-data/products';
// POST response model: productPOSTResponseModel in src/shared/models/product.model.ts
// PUT / POST payload model: productPayload in src/shared/models/product.model.ts

export const API_PRODUCT_DATA_PRODUCTS_DETAILED_RESPONSE = (lang: string) => `api/product-data/products?returnProductDetails=true&lang=${lang}`;
// POST response model: productDetailsResponseModel in src/shared/models/product.model.ts
// PUT / POST payload model: productPayload in src/shared/models/product.model.ts

export const API_PRODUCT_DATA_PRODUCTS_BULK_UPDATE = 'api/product-data/products/bulk-update';
// PATCH payload model: bulkProductUpdatePayload in src/shared/models/product.model.ts

export const API_PRODUCT_DATA_PRODUCTS_SUPPLIER_BULK_UPDATE = 'api/product-data/products/bulk-update/supplier';
// PATCH payload model: bulkProductBrandSupplierUpdatePayload in src/shared/models/product.model.ts

export const API_PRODUCT_DATA_PRODUCTS_BRAND_BULK_UPDATE = 'api/product-data/products/bulk-update/brand';
// PATCH payload model: bulkProductBrandSupplierUpdatePayload in src/shared/models/product.model.ts

export const API_GET_FILTERED_PRODUCTS = 'api/product-data/products/filter';
// response model: filteredProductsResponseModel in src/shared/models/product.model.ts
// get params model: fetchProductsAPI in src/shared/models/product.model.ts

export const API_EXPORT = 'api/export';
//POST payload: fetchProductsAPI in src/shared/models/product.model.ts
//POST payload: fetchProductsAPIAdvanced in src/shared/models/product.model.ts

export const API_EXPORT_STATISTICS = '/api/export/statistic';

export const API_BPCO_DETAILS = (bpcoId: string, lang: string): string => `api/product-data/bpcos/${bpcoId}?lang=${lang}`;
// response model: BPCOResponseModel in src/shared/models/BPCO.model.ts
// @TODO revise gtins and characteristics types

export const API_GET_BPCO_DESCRIPTIONS_LIST = 'api/product-data/bpco-descriptions/filter';
// response model: BPCODescriptionResponseModel in src/shared/models/bpco.model.ts

export const API_BPCO_DESCRIPTIONS = 'api/product-data/bpco-descriptions';
// response model: BPCODescriptionSingleResponseModel in src/shared/models/bpco.model.ts
// PUT payload model: BPCODescriptionUpdatePayload in src/shared/models/bpco.model.ts
// POST payload model: BPCODescriptionPayload in src/shared/models/bpco.model.ts

export const API_PRODUCT_DATA_BPCOS = (bpcoId: string) => `api/product-data/bpcos/${bpcoId}`;
// response model: NONE. just payload for PUT method
// PUT payload model: BPCOPayloadModel in src/shared/models/BPCO.model.ts

export const API_PRODUCT_DATA_BPCOS_CREATE = 'api/product-data/bpcos';
// response model: NONE.
// POST payload model: BPCOPayloadModel in src/shared/models/BPCO.model.ts

export const API_PRODUCT_DATA_BPCOS_SEARCH = 'api/product-data/bpcos/search';
// response model: productSearchResultResponse in src/shared/models/product.model.ts

export const API_BPCO_WITH_SUPPLIER_SEARCH = 'api/product-data/bpcos/master-data-search';
// response model: productWithSupplierSearchResultResponse in src/shared/models/product.model.ts

export const API_GET_PRODUCT_LINES = 'api/product-data/product-lines';
//response model: productLineResponseModel in src/shared/models/productLine.model.ts

export const API_PRODUCT_DATA_PRODUCT_LINES = 'api/product-data/product-lines';
// response model: NONE.
// POST payload model: productLinePayloadModel in src/shared/models/productLine.model.ts

export const API_PRODUCT_DATA_PRODUCT_LINE = (productLineId: string) => `api/product-data/product-lines/${productLineId}`;
// response model: productLine in src/shared/models/productLine.model.ts
// PUT payload model: productLinePayloadModel in src/shared/models/productLine.model.ts

export const API_PRODUCT_DATA_GET_DESCRIPTION = (productDescriptionId: string, lang: string): string => `api/product-data/product-descriptions/${productDescriptionId}?lang=${lang}`;
// response model: productDescriptionResponseModel in src/shared/models/productDescription.model.ts

export const API_PRODUCT_DATA_DESCRIPTION = 'api/product-data/product-descriptions';
// PUT payload model: productDescriptionPayload in src/shared/models/productDescription.model.ts
// POST payload model: productDescriptionUpdatePayload in src/shared/models/productDescription.model.ts
// PATCH payload model: productDescriptionReplacePayload in src/shared/models/productDescription.model.ts
export const API_GET_FILTERED_PRODUCT_DESCRIPTIONS = 'api/product-data/product-descriptions/filter';
// response model: filteredProductDescriptionsResponseModel in src/shared/models/productDescription.model.ts

export const API_COUNT_PRODUCTS_FOR_DESCRIPTION = (productDescriptionId: string): string => `api/product-data/product-descriptions/${productDescriptionId}/products/count`;
// countProductsForDescriptionsResponse in src/shared/models/productDescription.model.ts

export const API_PRODUCT_REPLACE = (productId: string): string => `api/product-data/products/${productId}`;
// PATCH payload model : {replacementProductId: string}

export const API_PAGE_IMAGE = (leafletId: string, pageNumber: number) => `api/leaflet-management/leaflets/${leafletId}/pages/${pageNumber}/content`;
// response model: image blob

export const API_KEY_ACCOUNTS = 'api/master-data/key-accounts';
// response model: NONE. just payload for POST & PUT methods

export const API_KEY_HIERARCHY = 'api/master-data/retailer-hierarchy';
// response model: keyAccountHierarchyResponseModel in src/shared/models/hierarchy.model.ts

export const API_BANNER_AND_RETAILER = 'api/master-data/banners/banner-and-retailer';
// response model: bannersWithRetailerResponseModel in src/shared/models/banner.model.ts

export const API_GET_ADVERTISING_AREA = (advertisingAreaId: string) => `api/master-data/advertising-areas/${advertisingAreaId}`;
// response model: advertisingArea in src/shared/models/advertisingArea.model.ts

export const API_ADVERTISING_AREAS = 'api/master-data/advertising-areas';
// response model: NONE.
// PUYT / POST ayload model: advertisingAreaPayloadModel in src/shared/models/advertisingArea.model.ts

export const API_ADVERTISING_AREA_FOR_BANNER = (bannerId: string) => `api/master-data/banners/${bannerId}/advertising-areas`;
// response model: advertisingArea in src/shared/models/advertisingArea.model.ts

export const API_REPRESENTATIVE_BRANCH_FOR_BANNER = (bannerId: string) => `api/master-data/banners/${bannerId}/branches/representative`;
// response model: representativeBranchResponseModel in src/shared/models/representativeBranch.model.ts

export const API_GET_FRANCHISE_OWNER = (franchiseOwnerId: string) => `api/master-data/franchise-owners/${franchiseOwnerId}`;
// response model: franchiseOwner in src/shared/models/franchiseOwner.model.ts

export const API_FRANCHISE_OWNERS = 'api/master-data/franchise-owners';
// response model: NONE. just payload for POST & PUT methods
// PUT payload: franchiseOwner in src/shared/models/franchiseOwner.model.ts
// POST payload: framchiseOwnerPOSTPayloadModel in src/shared/models/franchiseOwner.model.ts

export const API_FRANCHISE_OWNER_FOR_RETAILER = (retailerId: string) => `api/master-data/retailers/${retailerId}/franchise-owners`;
// response model: franchiseOwnersResponseModel in src/shared/models/franchiseOwner.model.ts

export const API_LITERALS_SEARCH = 'api/master-data/literals';
// response model: genericSearchResponseModel in src/shared/models/search.model.ts

export const API_LITERALS = 'api/master-data/literals';
// response model: NONE
// PUT payload model: literalObject in src/shared/models/literal.model.ts
// POST payload model: literalObject in src/shared/models/literal.model.ts

export const API_GET_LITERAL = (literalId: string) => `api/master-data/literals/${literalId}`;
// response model: literalObject in src/shared/models/literal.model.ts

export const API_GET_ATTRIBUTE = (attributeId: string) => `api/master-data/attributes/${attributeId}`;
// response model: attributeDetails in src/shared/models/attribute.model.ts

export const API_ATTRIBUTES = 'api/master-data/attributes';
// response model: NONE.
// PUT / POST payload model: fullAttributePayloadModel in src/shared/models/attribute.model.ts

export const API_ATTRIBUTE_SLIM = (attributeId: string, lang: string) => `api/slim-data/attributes/${attributeId}?lang=${lang}`;
// response model: slimAttributeResponseModel in src/shared/models/attribute.model.ts

export const API_ATTRIBUTES_SEARCH = 'api/master-data/attributes';
// response model: genericSearchResponseModel in src/shared/models/search.model.ts

export const API_ATTRIBUTE = (attributeId: string, lang: string) => `api/master-data/attri/${attributeId}?lang=${lang}`;
// response model: attributeResponseModel in src/shared/models/attribute.model.ts

export const API_BRANCHES_WITH_BANNERS = (bannerId: string) => `api/master-data/banners/${bannerId}/branches`;
// response model: branch[] in src/shared/models/branch.model.ts

export const API_CATEGORY_SEARCH = 'api/product-slim-data/categories';
// response model: categorySearchResponseModel in src/shared/models/category.model.ts

export const API_CATEGORY_ELASTIC_SEARCH = 'api/product-slim-data/categories/search';
// response model: categorySearchResponseObject[] in src/shared/models/category.model.ts

export const API_GET_CATEGORY_BRICK_DETAILS = (id: string, lang: string) => `api/product-data/category-brick/${id}?lang=${lang}`;
// response model: categoryBrick in src/shared/models/categoryBrick.model.ts

export const API_CATEGORY_BRICK_DETAILS = (id: string) => `api/product-data/category-brick/${id}`;
// response model: none
// PUT payload model: apiCategoryBrickDetailsPayload in src/shared/models/categoryBrick.model.ts

export const API_BRICK_DEPENDENT_ATTRIBUTE = (brickId: string, attributeId: string, lang: string) => `api/product-data/category-brick/${brickId}/attributes/${attributeId}/details?lang=${lang}`;
// response model: attributeResponseModel in src/shared/models/attribute.model.ts

export const API_CATEGORY_BRICK_ATTRIBUTES_DETAILS = (id:string) => `api/product-data/category-brick/${id}/attributes`;
// response model: categoryBrick in src/shared/models/categoryBrick.model.ts

export const API_CATEGORIES_HIERARCHY = 'api/product-slim-data/categories/hierarchy';
// response model: categoryHierarchyResponseModel in src/shared/models/category.model.ts


export const API_GET_CATEGORY_DETAILS = (categoryId: string): string => `api/product-data/categories/${categoryId}`;
// response model: categoryBEModel in src/shared/models/category.model.ts

export const API_CATEGORY_DETAILS = 'api/product-data/categories';
// PUT / POST payload model: categoryBEModel in src/shared/models/category.model.ts

export const API_UPDATE_BRICK = (brickId: string): string => `api/product-data/categories/${brickId}`;
// POST payload model: categoryBEModel in src/shared/models/category.model.ts

export const API_GET_FRAMES_FOR_LEAFLET = (leafletId:string) => `api/leaflet-management/leaflets/${leafletId}/frames`;
// response model: framesResponseModel in src/shared/models/frame.model.ts

export const API_GET_REMARKED_FRAMES_FOR_LEAFLET = (leafletId: string) => `api/leaflet-management/leaflets/${leafletId}/frames/remarked`;
// response model: framesResponseModel in src/shared/models/frame.model.ts

export const API_PROMOTIONS_FOR_FRAME = 'api/leaflet-management/frames/promotions';
// response model: none
// PUT payload model: framePromotionsPutPayload in src/shared/models/promotion.model.ts

export const API_GET_PROMOTIONS_FOR_FRAME = (frameId?: string) => `api/leaflet-management/frames/${frameId}/promotions`;
// response model: promotionsForFrameResponseModel in src/shared/models/frame.model.ts

export const API_GET_PROMOTIONS_FOR_MEDIA = (mediumId: string) => `api/delegation/advertisement-management/spots/${mediumId}/advertisements`;
// response model: promotionsForSpotResponseModel in src/shared/modesl/media.model.ts

export const API_PROMOTIONS_FOR_MEDIA = 'api/delegation/advertisement-management/advertisement';
// response model: none
// PUT payload model: mediumPromotionsPayload in src/shared/models/media.model.ts

export const API_GET_NEXT_FRAME = (countryId: string, tabId: string) => `api/leaflet-management/leaflets/pages/frames/next?countryId=${countryId}&tabId=${tabId}`;
// response model: frame in src/shared/models/frame.model.ts

export const API_GET_NEXT_FRAME_FOR_LEAFLET = (leafletId: string, tabId: string) => `api/leaflet-management/leaflets/${leafletId}/pages/frames/next?tabId=${tabId}`;
// response model: frame in src/shared/models/frame.model.ts

export const API_GET_NEXT_REPORTED_FRAME = (frameId: string, tabId: string) => `/api/leaflet-management/frames/locked/${frameId}?tabId=${tabId}`;
// response model: frame in src/shared/models/frame.model.ts

export const API_SKIP_FRAME = (frameId: string) => `api/leaflet-management/frames/${frameId}/skip`;
// response model: none
// PUT payload model: none, just path param

export const API_REPORT_FRAME = (frameId: string) => `api/leaflet-management/frames/${frameId}/report`;
// response model: none
// PUT payload model: reportFramePayloadModel in src/shared/models/frame.model.ts

export const API_REMARK_ADVERTISEMENT = (frameId: string) => `api/leaflet-management/frames/${frameId}/remark`;
// response model: none
// POST payload model: reportAdvertisementPayloadModel in src/shared/models/frame.model.ts

export const API_FRAME_PROPOSAL_ACTIVITY_TRACKER = (frameId: string, activityType: string) => `api/leaflet-management/frames/${frameId}/activity-tracker/${activityType}`;
// response model: none, only POST method to track activity of proposals

export const API_FRAMES_PER_PAGE_PER_LEAFLET = (leafletId: string, pageNumber: number) => `api/leaflet-management/leaflets/${leafletId}/pages/${pageNumber}/frames`;
// response model: framesPerPagePerLeafletResponseModel in src/shared/models/frame.model.ts
// @TODO: see comments in type definition!
// PUT / POST payload mode: framesPerPagePerLeafletPayloadModel in src/shared/models/frame.model.ts

export const API_GET_IF_PAGE_DUPLICATED = (leafletId: string, pageNumber: number) => `api/leaflet-management/leaflets/${leafletId}/pages/${pageNumber}/connected`;
// response model: isPageConnectedResponseModel in src/shared/models/page.model.ts

export const API_GET_ADVERTISEMENTS_TABLE = (leafletId: string, pageNumber: number, lang: string) => `api/leaflet-management/leaflets/${leafletId}/pages/${pageNumber}/advertisements?lang=${lang}`;
// response model: advertisementsTableResponseModel in src/shared/models/promotion.model.ts
// @TODO check promotionTableRowBE type as there are some inconsistencies on product level ex. missing active and alternativeProductId fields

export const API_GET_NEXT_PAGE_META = (countryId: string, tabId: string): string =>  `api/leaflet-management/leaflets/pages/next?countryId=${countryId}&tabId=${tabId}`;
// response model: frameProposalsResponseModel in src/shared/models/frame.model.ts

export const API_GET_NEXT_PAGE_META_OF_LEAFLET = (leafletId: string, tabId: string) => `api/leaflet-management/leaflets/${leafletId}/pages/next?tabId=${tabId}`;
// response model: frameProposalsResponseModel in src/shared/models/frame.model.ts

export const API_SKIP_PAGE = (leafletId: string, pageNumber: number) => `api/leaflet-management/leaflets/${leafletId}/pages/${pageNumber}/skip`;
// response model: none
// PUT payload: none, only path params

export const API_DELETE_PAGE_CONNECTION = (leafletId: string, pageNumber: number) => `api/leaflet-management/leaflets/${leafletId}/pages/${pageNumber}/connection`;
// response model: none, only DELETE method

export const API_GET_LEAFLET_PROGRESS = (leafletId: string) => `api/leaflet-management/leaflets/${leafletId}/progressIndicator`;
// response model: leafletProgress in src/shared/models/leaflet.model.ts

export const API_MULTIPLE_LEAFLET_PROGRESS = 'api/leaflet-management/leaflets/progressIndicator';
// POST response model: leafletProgress in src/shared/models/leaflet.model.ts
// POST payload: string[]

export const API_PRODUCT_SEARCH = 'api/product-data/products/search';
// response model: productSearchResultResponse in src/shared/models/product.model.ts

export const API_PRODUCT_WITH_SUPPLIER_SEARCH = '/api/product-data/products/master-data-search';
// response model: productWithSupplierSearchResultResponse in src/shared/models/product.model.ts

export const API_PRODUCT_BULK_UPDATE_PRE_VALIDATION = 'api/product-data/products/bulk-update/validate-duplicates';
// response model: bulkProductUpdateDuplicateCheckResponse in src/shared/models/product.model.ts
// payload model bulkProductUpdatePayload in src/shared/models/product.model.ts

export const API_PRODUCT_BULK_UPDATE_BRAND_SUPPLIER_PRE_VALIDATION = 'api/product-data/products/bulk-update/validate-duplicates/brand';
// response model: bulkProductUpdateDuplicateCheckResponse in src/shared/models/product.model.ts
// payload model bulkProductBrandSupplierUpdatePayload in src/shared/models/product.model.ts

export const API_PRODUCT_BULK_DESCRIPTION_CHANGE_GET_RELATED_ATTRIBUTES = 'api/product-data/product-descriptions/bulk-update/related-attributes';
//response model: productBulkDescriptionChangeGetRelatedAttributesResponse in src/shared/models/product.model.ts
//payload model: productBulkDescriptionChangeGetRelatedAttributesPayload in src/shared/models/product.model.ts

export const API_PRODUCT_BULK_DESCRIPTION_CHANGE_PRE_VALIDATION = 'api/product-data/product-descriptions/bulk-update/validate-duplicates';
// payload model: productBulkDescriptionChangePayload in src/shared/models/product.model.ts
// response model: bulkProductUpdateDuplicateCheckResponse in src/shared/models/product.model.ts

export const API_PRODUCT_BULK_DESCRIPTION_CHANGE = 'api/product-data/product-descriptions/bulk-update';
// payload model: productBulkDescriptionChangePayload in src/shared/models/product.model.ts

export const API_PRODUCT_REPORT = (productId: string): string =>`api/product-data/products/${productId}/report`;
// payload model: reportProductPayload in src/shared/models/product.model.ts

export const API_GET_REPORTED_PRODUCTS = (marketId: string, lang: string): string =>`api/product-data/products/reported?marketId=${marketId}&lang=${lang}`;
//response model: reportedProductsResponseModel[]  in src/shared/models/product.model.ts

export const API_FIX_REPORTED_PRODUCT = (productId: string): string => `api/product-data/products/${productId}/fix`;
//response model:  none, only PATCH method

export const API_RELEASE_PAGE_LOCK = '/api/leaflet-management/leaflets/pages/release';
// response model: none, only DELETE method

export const API_RENEW_PAGE_LOCK = (leafletId: string, pageNumber: number): string => `/api/leaflet-management/leaflets/pages/renewLock?leafletId=${leafletId}&pageNumber=${pageNumber}`;
// response model: none, only PUT  method

export const API_RELEASE_LEAFLET_LOCK = '/api/leaflet-management/leaflets/release';
// response model: none, only DELETE method

export const API_RELEASE_PRODUCT_LOCK = '/api/product-data/release';
// response model: none, only DELETE method

export const API_RELEASE_MEDIUM_LOCK = '/api/spot-management-service/spots/release';
// response model: none, only DELETE method

export const API_GET_LEAFLETS_WITH_REPORTED_FRAMES = (country: string) => `/api/leaflet-management/leaflets/withReportedFrames?country=${country}`;
// response model: printMediaOverviewTableRow[] in src/shared/models/leaflet.model.ts

export const API_GET_LEAFLETS_WITH_REMARKED_ADVERTISEMENTS = (country: string) => `/api/leaflet-management/leaflets/withRemarkedFrames?country=${country}`;
// response model: printMediaOverviewTableRow[] in src/shared/models/leaflet.model.ts

export const API_GET_REPRESENTATIVE_BRANCHES_FOR_BANNER = (bannerId: string) => `api/leaflet-management/leaflets/representative-branch/details?bannerId=${bannerId}`;
// response model: representativeBranchesForBanner in src/shared/models/banner.model.ts

export const API_FETCH_EXTERNAL_GTIN_DATA = (country: string, lang: string) => `/api/product-data/gtin/details?country=${country}&lang=${lang}`;
// POST payload model: externalGtinDataPayloadModel in src/shared/models/gtin.model.ts
// POST response model: externalGtinDataResponseModel in src/shared/models/gtin.model.ts

export const API_GET_CONNECTED_FRAMES_COUNT = (frameId: string) => `/api/leaflet-management/frames/${frameId}/duplicates/count`;
// response model: connetedFramesCountResponse in src/shared/models/frame.model.ts

// @TODO this endpoint was temporary taken over to provide promotions suggestions. in the end we will need both
export const API_PRODUCT_SUGGESTIONS = (frameId: string) => `/api/leaflet-management/frames/${frameId}/similar`;
// response model: productSuggestionResponseModel in src/shared/models/frame.model.ts

export const API_PROMOTIONS_SUGGESTIONS = (frameId: string, lang: string, countryId: string) => `/api/leaflet-management/frames/${frameId}/similar?lang=${lang}&countryId=${countryId}`;
// response model: promotionSuggestionsResponseModel in src/shared/models/promotion.model.ts

export const API_IMPORT_GTINS_BLOCKING = '/api/product-data/products/import/gtins/blocking';
// response model: importGtinsSummaryResponseModel in src/shared/models/gtin.model.ts

export const API_GET_PRODUCTS_WITHOUT_GTINS = (lang: string, marketId: string) => `/api/product-data/qs/products-without-gtins?lang=${lang}&marketId=${marketId}`;
// response model: filteredProductsResponseModel in src/shared/models/product.model.ts

export const API_GET_PRODUCTS_DUPLICATED_GTINS = (lang: string) => `/api/product-data/qs/duplicated-gtins?lang=${lang}`;
// response model: productsDuplicatedGtinsResponse in src/shared/models/product.model.ts

export const API_PRODUCT_LOCK = (productId: string) => `api/product-data/lock/${productId}`;
// response model: none, only POST method to lock the product

export const API_DELETE_FRAME = (frameId: string): string => `api/leaflet-management/frames/${frameId}`;

export const API_REPORT_PAGE = (leafletId: string, pageNumber: number) => `api/leaflet-management/leaflets/${leafletId}/pages/${pageNumber}/report`
// PUT payload model: reportPagePayload in src/shared/models/page.model.ts

export const API_GET_REPORTED_PAGES = (leafletId: string) => `api/leaflet-management/leaflets/${leafletId}/reportedPages`
// response model: reportedEntity[] in src/shared/models/_common.model.ts

export const API_GET_RETAILER_HQ_LIST = (retailerId: string): string => `api/master-data/retailers/${retailerId}/retailer-headquarters`
// response model: retailerHQListItem[] in src/shared/models/retailerHQ.model.ts

export const API_GET_RETAILER_HQ = (id: string): string => `api/master-data/retailer-headquarters/${id}`;
// response model: retailerHQ in src/shared/models/retailerHQ.model.ts

export const API_UPDATE_RETAILER_HQ = (id: string): string => `api/master-data/retailer-headquarters/${id}`;
// put payload model: retailerHQPayload in src/shared/models/retailerHQ.model.ts

export const API_CREATE_RETAILER_HQ = 'api/master-data/retailer-headquarters';
// post payload model: retailerHQPayload in src/shared/models/retailerHQ.model.ts
                                                    
export const API_PROMOTION_MANAGEMENT_PROMOTIONS = 'api/delegation/advertisement-management/advertisement';
// POST payload model: mediumPromotionsPayload in src/shared/models/media.model.ts

export const API_ADVERTISEMENTS_MEDIA_OVERVIEW = (mediumId: string, lang: string) => `api/delegation/advertisement-management/spots/${mediumId}/advertisements/details?lang=${lang}`;
// response model: advertisementsMediaTableResponseModel in src/shared/models/product.model.ts

export const API_MEDIA_SPOT_AUDIOFILE = (country: string, filename: string) => `/api/spot-management-service/spots/content/${country}/${filename}`;
// response model: string($binary) - audiofile

export const API_MEDIA_SPOT_DELETE = (id: string): string => `/api/spot-management-service/spots/${id}`;
// response model: NONE, payload model: NONE, just DELETE call

export const API_MEDIA_GET_SPOT_FOR_DESCRIBE  = (id: string): string =>  `/api/spot-management-service/spots/describe/${id}`;
// response model: spotResponseModel in /src/shared/models/media.model.ts

export const API_MEDIA_GET_NEXT_SPOT_FOR_DESCRIBE = (countryId: string) => `/api/spot-management-service/spots/describe/next?countryId=${countryId}`;
// response model: spotResponseModel in /src/shared/models/media.model.ts

export const API_MEDIA_GET_NEXT_SPOT_FOR_VERIFICATION = (countryId: string) => `/api/spot-management-service/spots/verification/next?countryId=${countryId}`;
// response model: spotVerificationNextResponseModel in /src/shared/models/media.model.ts

export const API_SPOTS_OVERVIEW = 'api/spot-management-service/spots/overview';
// response model: spotOverviewRow[] in /src/shared/models/media.model.ts

export const API_SPOT_AND_BROADCAST = 'api/spot-management-service/spots';
// get payload model: spotResponseModel in /src/shared/models/media.model.ts
// put payload model: spotPutPayloadModel in /src/shared/models/media.model.ts

export const API_SPOT_AND_BROADCAST_UPLOAD = 'api/spot-management-service/spots/upload'
// post payload model: broadcastSpotPostPayloadModel in /src/shared/models/media.model.ts

export const API_GET_SUPPLIERS = (marketId: string): string => `api/product-data/suppliers?marketId=${marketId}`;
// response model: [supplier] in src/shared/models/supplier.model.ts

export const API_SUPPLIER = (supplierId: string): string => `api/product-data/suppliers/${supplierId}`;
// response model: supplier in src/shared/models/supplier.model.ts
// PUT payload: supplierPutPayloadModel in src/shared/models/supplier.model.ts

export const API_SUPPLIER_CREATE = 'api/product-data/suppliers';
// POST payload: supplierPostPayloadModel in src/shared/models/supplier.model.ts

export const API_SUPPLIER_SEARCH = 'api/product-data/suppliers/search';
// response model: supplierSearchResult[] in src/shared/models/supplier.model.ts

export const API_GET_SUPPLIERS_FOR_BRAND = (brandId: string): string => `api/product-data/suppliers/for-brand/${brandId}`;
// response model: basicSupplierWithUsageCount[] in src/shared/model/supplier.model.ts

export const API_GET_TAXES_DICTIONARY = 'api/product-data/taxes';
// response model: taxesPerCountry[] in src/shared/models/taxes.model.ts

export const API_GET_TAXES_FOR_BRICK = (brickId: string): string => `/api/product-data/category-bricks/${brickId}/taxes`;
// response model: taxItemWithCountry[] in src/shared/models/taxes.model.ts

export const API_GET_USER_PERMISSIONS = '/api/role-management/users/currentUser/permissions';
// response model: currentUserPermissionsResponse in src/shared/models/permissions.model.ts

export enum API_STATUSES {
    NEW = 'NEW', // awaiting API call to start processing request
    PENDING = 'PENDING', // req in progress either upload or download, so it's no longer 'loading' status
    ERROR = 'ERROR', // req finished with error
    IDLE = 'IDLE', // req completed succesfully, either upload or download, so it's no longer 'loaded' status
    CANCELLED = 'CANCELLED', // API call cancelled by user or app either directly (ex. press 'abort' button) or indirectly (ex leaving view with API call pending)
    PRECONDITION_FAILED = 'PRECONDITION_FAILED', // precondition failed for api
    DUPLICATED = 'DUPLICATED' // tried to upload and api returned that medium was already uploaded and would be a duplicate
}

export enum ERROR_RESPONSES {
    IMPORT_GTIN_INVALID_FILE_EXTENSION = 'IMPORT_GTIN_INVALID_FILE_EXTENSION',
    LEAFLET_ALREADY_EXISTS = 'LEAFLET_ALREADY_EXISTS',
    LEAFLET_LOCKED = 'LEAFLET_LOCKED'
}
