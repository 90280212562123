import './PromotionsSuggestion.scss';

import {useState} from 'react';
import {useFormatMessage} from 'utils/translate';
import {Button} from '@mui/material';
import {PaperX} from 'components/PaperX';
import {FramePreview} from 'components/gfx';
import {promotionSuggestionsForFrame} from 'shared/models/promotion.model';
import PromotionItem from './PromotionItem';
import FrameDescriptionReportDialog, {reportIssuesType} from 'modules/Advertisement/FrameDescription/FrameDescriptionReportDialog';

interface PromotionsSuggestionProps {
    data: promotionSuggestionsForFrame,
    refetch: () => void,
    onClick: (frameId: string, forceEraseAndOverwrite: boolean) => void
}
const PromotionsSuggestion = (props: PromotionsSuggestionProps) => {
    const {frameId, promotions} = props.data;
    const translate = useFormatMessage();

    const [isReportDialogOpen, setIsReportDialogOpen] = useState<boolean>(false);

    const handleReportPromotionSuccess = () => {
        props.refetch();
        setIsReportDialogOpen(false);
    }

    return (
        <PaperX key={frameId} className="promotionsSuggestionRoot _scrollY _fullWidth" >
            <div className="promotionsBtnContainer">
                <Button variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => setIsReportDialogOpen(true)}>
                            {translate({id: 'frameDescription.reportAdvertisement'})}
                </Button>
                <Button variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => props.onClick(frameId, true)}>
                            {translate({id: 'frameDescription.eraseAndOverwriteAction'})}
                </Button>
            </div>
            <div className="promotionsListDetails" onClick={() => props.onClick(frameId, false)}>
                <FramePreview frameId={frameId}/>
                {promotions.map((promotion, index) => <PromotionItem key={promotion.id}
                                                                     isLast={promotions.length -1 === index}
                                                                     data={promotion}/>)
                }
            </div>
            <FrameDescriptionReportDialog open={isReportDialogOpen}
                                          frameId={frameId}
                                          onClose={() => setIsReportDialogOpen(false)}
                                          onSuccess={handleReportPromotionSuccess}
                                          reportType={reportIssuesType.advertisement}
                />
        </PaperX>
    );
};

export default PromotionsSuggestion;