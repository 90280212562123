/* eslint-disable react-hooks/exhaustive-deps */
import './HeaderData.scss';

import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {parseISO} from 'date-fns';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { paths } from 'paths';
import { useApi } from 'utils/axiosHooks/axiosHooks';
import { getId } from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import {mapOfMultiselectAttributes} from 'shared/models/attributeComponent.model';
import {
    headerData,
    leafletMetaData,
    leafletModel,
    leafletPayloadModel,
    printMediaType,
    pageCountryThemedWeeks,
    pageThemeSeason,
    pageValidity,
    pageValidityBE,
    pagePromotionTypes,
    publicationOverviewDataModel,
    validateResult
} from 'shared/models/leaflet.model';
import {branch} from 'shared/models/branch.model';
import {commonAttributeDataModel} from 'shared/models/attributeComponent.model';
import {bannerWithRetailer} from 'shared/models/banner.model';
import { IRootState } from 'shared/reducers';
import { fetchBranchesList } from 'shared/reducers/branchesList';
import {dateFormat} from 'shared/models/validity.model';
import {handyAttributes} from 'shared/handyAttributes';
import {API_LEAFLET_MANAGEMENT_LEAFLETS, API_STATUSES, ERROR_RESPONSES} from 'config/api/constants';
import {PaperX} from 'components/PaperX';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import ButtonClose from 'components/Buttons/ButtonClose';
import Footer from 'components/Footer';
import LeafletBrowser from 'components/gfx/LeafletBrowser/LeafletBrowser';
import {ValiditySelector, BannerSelector, PriceModeSelector} from 'components/Selectors';
import {GoFullScreen} from 'components/gfx';
import {Attribute} from 'components/Attribute';
import LanguageSelector from 'components/Selectors/LanguageSelector/LanguageSelector';
import {ConfirmDialog} from 'components/ConfirmDialog';
import CountryDisplayAsInput from 'components/Displays/CountryDisplayAsInput';
import {getDefaultLeafletLanguageForCountry, languages} from 'modules/Dictionaries/Dictionary';
import BranchesSelectionDialog from './BranchesSelectionDialog';
import PageValidities from './PageValidity/PageValidities';
import {
    checkValiditesForNulls,
    exportPageValidities,
    importPageValidities,
    validateValidities
} from './PageValidity/pageValiditiesIO';
import AdvertisingAreaDialog from './AdvertisingAreaDialog';
import LealeftRepresentativeBranch from './LealeftRepresentativeBranch';
import {lockLevels, lockSetter, releaseLeafletLock } from 'utils/lockUtils';
import {DuplicateDialogMsg} from './DuplicateDialogMsg';
import PageThemeSeasons from './PageThemeSeason/PageThemeSeasons';
import {exportPageSeasonThemes, importPageSeasonThemes, validateThemeSeasons} from './PageThemeSeason/PageThemeSeasonIO';
import PagePromotionTypes from './PagePromotionTypes/PagePromotionTypes';
import PublicationOverview from './PublicationOverview/PublicationOverview';
import {exportPagePromotionTypes, importPagePromotionTypes, validatePromotionType} from './PagePromotionTypes/PromotionTypesIO';
import PageCountryThemedWeeks from './PageCountryThemedWeeks/PageCountryThemedWeeks';
import {exportPageCountryThemedWeeks, importPageCountryThemedWeeks, validateCountryThemedWeeks} from './PageCountryThemedWeeks/CountryThemedWeeksIO';

const defaultLeafletType = 'bd71f835-e82a-4a73-9079-10f1ef1bfc32';

const headerDataTemplate: headerData = {
    bannerId: '',
    bannerName: '',
    branches: [],
    countryThemedWeeks: [],
    isGross: true,
    language: 'en',
    leafletRepresentativeBranchId: '',
    leafletRepresentativeBranchName: '',
    leafletTypes: [],
    pageCountryThemedWeeks: [],
    pageValidities: [],
    pageThemeSeasons: [],
    pagePromotionTypes: [],
    promotionTypes: [],
    themeSeasons: [],
    validFrom: '',
    validTo: ''
};

const metaDataTemplate: leafletMetaData = {
    fileName: '',
    numberOfPages: 0
};

function buildLeafletPropsTemplate (): mapOfMultiselectAttributes {
    const template: mapOfMultiselectAttributes = {};
    template[handyAttributes.leafletType] = {id: handyAttributes.leafletType, literals: [{literalId: defaultLeafletType}]};
    template[handyAttributes.themeSeason] = {id: handyAttributes.themeSeason, literals: []};
    template[handyAttributes.countryThemedWeeks] = {id: handyAttributes.countryThemedWeeks, literals: []};
    template[handyAttributes.promotionType] = {id: handyAttributes.promotionType, literals: []};
    return template;
}

function restoreProperties (leafletTypes: string[], themeSeasons: string[], themedWeeks: string[], promoType: string[]): mapOfMultiselectAttributes {
    const properties: mapOfMultiselectAttributes = {};
    if (leafletTypes?.length) {
        properties[handyAttributes.leafletType] = {
            id: handyAttributes.leafletType,
            literals: leafletTypes.map((item) => ({literalId: item}))};
    } else {
        properties[handyAttributes.leafletType] = {id: handyAttributes.leafletType, literals: [{literalId: defaultLeafletType}]};
    }
    properties[handyAttributes.themeSeason] = {
        id: handyAttributes.themeSeason,
        literals: themeSeasons ? themeSeasons.map((item) => ({literalId: item})) : []
    };
    properties[handyAttributes.countryThemedWeeks] = {
        id: handyAttributes.countryThemedWeeks,
        literals: themedWeeks ? themedWeeks.map((item) => ({literalId: item})) : []
    };
    properties[handyAttributes.promotionType] = {
        id: handyAttributes.promotionType,
        literals: promoType ? promoType.map((item) => ({literalId: item})) : []
    }
    return properties;
}

const headerDataLocalStorageKey: string = 'clipboard';

const HeaderData = ({history}) => {
    const dateFns = new AdapterDateFns();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();

    const leafletAPIGet = useApi('get', {}, {errMsg: 'headerData.err', failureMsg: 'headerData.errLocked'});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const branchesList = useSelector((state: IRootState) => state.branchesList.data);
    const branchesStatus = useSelector((state: IRootState) => state.branchesList.status);
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const [leafletId, setLeafletId] = useState<string>();
    const [mediumType, setMediumType] = useState<printMediaType>(printMediaType.LEAFLET);
    const [headerData, setHeaderData] = useState<headerData>(headerDataTemplate);
    const [metaData, setMetaData] = useState<leafletMetaData>(metaDataTemplate);
    const [publicationOverviewData, setPublicationOverviewData] = useState<publicationOverviewDataModel[]>([]);

    const [language, setLanguage] = useState<string>()
    const [country, setCountry] = useState<string>('');
    const [bannerId, setBannerId] = useState<string>(null);
    const [branchesData, setBranchesData] = useState<branch[]>([]);

    const [leafletProperties, setLeafletProperties] = useState<mapOfMultiselectAttributes>(buildLeafletPropsTemplate());

    const [validityFrom, setValidityFrom] = useState<Date>(null);
    const [validityTo, setValidityTo] = useState<Date>(null);

    const [pageValidities, setPageValidities] = useState<pageValidity[]>([]);
    const [pageThemeSeasons, setPageThemeSeasons] = useState<pageThemeSeason[]>([])
    const [pagePromotionTypes, setPagePromotionTypes] = useState<pagePromotionTypes[]>([]);
    const [pageCountryThemedWeeks, setPageCountryThemedWeeks] = useState<pageCountryThemedWeeks[]>([]);

    const [branchesDialogOpen, setBranchesDialogOpen] = useState<boolean>(false);
    const [advertisingAreaDialogOpen, setAdvertisingAreaDialogOpen] = useState<boolean>(false);
    const [duplicateDialogOpen, setDuplicateDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        const leafletId: string = getId();
            if (leafletId) {
                localStorage.setItem('leafletBrowserFullScreen', leafletId);
                lockSetter(lockLevels.leaflet);
                leafletAPIGet.call(`${API_LEAFLET_MANAGEMENT_LEAFLETS}/${leafletId}?acquireLock=true`);
            }
    }, []);

    useEffect(() => {
        if (leafletAPIGet.status === API_STATUSES.IDLE) {
            const leaflet: leafletModel = {...leafletAPIGet.data};
            const {country, headerData, leafletId, metaData, mediumType, publicationOverview} = leaflet;
            setCountry(country);
            setLeafletId(leafletId);
            setLeafletProperties(restoreProperties(headerData.leafletTypes, headerData.themeSeasons, headerData.countryThemedWeeks, headerData.promotionTypes));
            // if no information about price mode is available, set it to gross (true) by default, as per MPM-2665 story
            if (headerData.isGross === null) {
                headerData.isGross = true;
            }
            if (headerData.bannerId) {
                setHeaderData(headerData);
                setBannerId(headerData.bannerId);
            }
            if (headerData.validFrom) setValidityFrom(parseISO(headerData.validFrom));
            if (headerData.validTo) setValidityTo(parseISO(headerData.validTo));
            if (headerData.language) {
                setLanguage(headerData.language);
            } else {
                setLanguage(getDefaultLeafletLanguageForCountry(country));
            }
            if (mediumType) setMediumType(mediumType);
            if (metaData) setMetaData(metaData);
            if (publicationOverview) setPublicationOverviewData(publicationOverview);
            setPageValidities(importPageValidities(headerData.pageValidities));
            setPageThemeSeasons(importPageSeasonThemes(headerData.pageThemeSeasons));
            setPagePromotionTypes(importPagePromotionTypes(headerData.pagePromotionTypes));
            setPageCountryThemedWeeks(importPageCountryThemedWeeks(headerData.pageCountryThemedWeeks));
        }
        else if (leafletAPIGet.status === API_STATUSES.ERROR && leafletAPIGet.errorCode === 409) {
            history.push(paths.printMediaOverview);
        }
    }, [leafletAPIGet.status]);

    useEffect(() => {
        return history.listen(location => (location.pathname === paths.printMediaOverview) && releaseLeafletLock());
    }, []);

    useEffect(() => {
        if (bannerId !== headerData.bannerId) {
            setHeaderData({...headerData, bannerId, leafletRepresentativeBranchId: '', branches:[]});
        } else {
            setHeaderData({...headerData, bannerId });
        }
        if (bannerId) {
            dispatch(fetchBranchesList(bannerId,selectedLanguage));
        } else {
            setBranchesData([]);
        }
    }, [bannerId]);

    useEffect(() => {
        bannerId && setBranchesData(branchesList);
    }, [branchesList]);

    const saveHeaderData = (overrideDuplicate: boolean) => {
        setIsLoading(true);
        let url = `${API_LEAFLET_MANAGEMENT_LEAFLETS}/${leafletId}`;
        if (overrideDuplicate) {
            url += '?saveWithDuplicates=true';
        }
        axios.put(url, buildSaveObject())
            .then(() => {
                history.push(paths.printMediaOverview);
                enqueueSnackbar(`${translate({id: 'headerData.succ'})}`, {variant: 'success', persist: false});
                setIsLoading(false);
            })
            .catch((e) => {
                if (e?.response?.data?.message === ERROR_RESPONSES.LEAFLET_ALREADY_EXISTS) {
                    setDuplicateDialogOpen(true)
                } else {
                    console.log(e);
                    enqueueSnackbar(`${translate({id: 'headerData.saveErr'})}`, {variant: 'error', persist: false});
                }
                setIsLoading(false);
            });
    }

    const handleBannerChange = (banner: bannerWithRetailer) => setBannerId(banner ? banner.bannerId : null);

    const handleBranchesSelectionChange = (branches: string[]) => {
        // check also if removed branch was set as a leaflet representative branch
        const hasRepresentative: string = branches.find((item) => item === headerData.leafletRepresentativeBranchId);
        if (hasRepresentative === undefined) {
            setHeaderData({...headerData, branches, leafletRepresentativeBranchId: ''});
        } else {
            setHeaderData({...headerData, branches});
        }
    };

    const cantSave = (): boolean => !headerData.bannerId ||  headerData.branches.length === 0 || !headerData.leafletRepresentativeBranchId
        || leafletProperties[handyAttributes.leafletType].literals.length === 0
        || validityFrom === null || validityTo === null || !language;

    const goToLeaflets = () => history.push(paths.printMediaOverview);

    const showLoading = (): boolean => leafletAPIGet.status === API_STATUSES.PENDING || isLoading;

    const buildSaveObject = (): leafletPayloadModel => {
        let vFrom: Date = validityFrom || validFromModified ? validityFrom :  leafletDetail && headerData.validFrom ? new Date(headerData.validFrom) : null;
        let vTo: Date = validityTo || validToModified ? validityTo :  leafletDetail && headerData.validTo ? new Date(headerData.validTo) : null;

        const saveObj: leafletPayloadModel = {
            bannerId: headerData.bannerId,
            branches: headerData.branches,
            countryThemedWeeks: leafletProperties[handyAttributes.countryThemedWeeks].literals.map((item) => item.literalId),
            isGross: headerData.isGross,
            language: language,
            leafletRepresentativeBranchId: headerData.leafletRepresentativeBranchId,
            leafletTypes: leafletProperties[handyAttributes.leafletType].literals.map((item) => item.literalId),
            pageCountryThemedWeeks: [],
            pageThemeSeasons: [],
            pagePromotionTypes: [],
            pageValidities: [],
            promotionTypes: leafletProperties[handyAttributes.promotionType].literals.map((item) => item.literalId),
            themeSeasons: leafletProperties[handyAttributes.themeSeason].literals.map((item) => item.literalId),
            validFrom: vFrom ? dateFns.formatByString(vFrom, dateFormat) : null,
            validTo: vTo ? dateFns.formatByString(vTo, dateFormat) : null,
        };

        if (pageValidities?.length > 0) {
            saveObj.pageValidities = exportPageValidities(pageValidities);
        }

        if (pageThemeSeasons?.length > 0) {
            saveObj.pageThemeSeasons = exportPageSeasonThemes(pageThemeSeasons);
        }
        
        if (pagePromotionTypes?.length) {
            saveObj.pagePromotionTypes = exportPagePromotionTypes(pagePromotionTypes);
        }

        if (pageCountryThemedWeeks?.length) {
            saveObj.pageCountryThemedWeeks = exportPageCountryThemedWeeks(pageCountryThemedWeeks);
        }

        return saveObj;
    };

    const handleLeafletSave = (overrideDuplicate: boolean) => {
        const exportedValidities: pageValidityBE[] = exportPageValidities(pageValidities);
        const validationResultValidities: validateResult = validateValidities(exportedValidities);
        const validationResultValiditiesEmpty: validateResult = checkValiditesForNulls(exportedValidities);
        const validationResultThemeSeason: validateResult = validateThemeSeasons(exportPageSeasonThemes(pageThemeSeasons));
        const validationResultPromotionTypes: validateResult = validatePromotionType(exportPagePromotionTypes(pagePromotionTypes));
        const validationResultCountryThemedWeeks: validateResult = validateCountryThemedWeeks(exportPageCountryThemedWeeks(pageCountryThemedWeeks));
        if (validationResultValidities.hasError) {
            enqueueSnackbar(`${translate({id: 'headerData.duplicateLeafletValidityPages'})} ${validationResultValidities.duplicatePages}`,
                {variant: 'error', persist: false});
        } else if (validationResultValiditiesEmpty.hasError) {
            enqueueSnackbar(translate({id: 'headerData.errLeafletValidityPages'}),
                {variant: 'error', persist: false});
        } else if (validationResultThemeSeason.hasError) {
            enqueueSnackbar(`${translate({id: 'headerData.duplicateLeafletThemeSeasonsPages'})} ${validationResultThemeSeason.duplicatePages}`,
                {variant: 'error', persist: false});
        } else if (validationResultPromotionTypes.hasError) {
            enqueueSnackbar(`${translate({id: 'headerData.duplicateLeafletPromotionTypePages'})} ${validationResultPromotionTypes.duplicatePages}`,
                {variant: 'error', persist: false});
        } else if (validationResultCountryThemedWeeks.hasError) {
            enqueueSnackbar(`${translate({id: 'headerData.duplicateLeafletCountryThemedWeeeks'})} ${validationResultCountryThemedWeeks.duplicatePages}`,
                {variant: 'error', persist: false});
        } else {
            saveHeaderData(overrideDuplicate);
        }
    };

    const handleCopyLeaflet = () => {
        const clipboardBuilder: string = JSON.stringify(buildSaveObject());
        localStorage.setItem(headerDataLocalStorageKey, clipboardBuilder);
        enqueueSnackbar(`${translate({id: 'headerData.copied'})}`, {variant: 'info', persist: false});
    }

    const handlePasteLeaflet = () => {
        //@TODO could reuse code from 'load headerdata' section to avoid incoherence
        const clipboard: leafletPayloadModel = JSON.parse(localStorage.getItem(headerDataLocalStorageKey));
        if (clipboard) {
            setValidityFrom(parseISO(clipboard.validFrom));
            setValidityTo(parseISO(clipboard.validTo));
            setBannerId(clipboard.bannerId);
            setLeafletProperties(restoreProperties(clipboard.leafletTypes, clipboard.themeSeasons, clipboard.countryThemedWeeks, clipboard.promotionTypes));
            const restoredHeaderData: headerData = {...clipboard, bannerName: '', leafletRepresentativeBranchName: '', pageThemeSeasons: clipboard.pageThemeSeasons, pagePromotionTypes: clipboard.pagePromotionTypes, pageCountryThemedWeeks: clipboard.pageCountryThemedWeeks};
            setHeaderData(restoredHeaderData);
            setLanguage(clipboard.language);
            if (metaData.numberOfPages) setPageValidities(importPageValidities(clipboard.pageValidities));
            if (headerData.pageCountryThemedWeeks) setPageCountryThemedWeeks(importPageCountryThemedWeeks(clipboard.pageCountryThemedWeeks));
            if (headerData.pageThemeSeasons) setPageThemeSeasons(importPageSeasonThemes(clipboard.pageThemeSeasons));
            if (headerData.pagePromotionTypes) setPagePromotionTypes(importPagePromotionTypes(clipboard.pagePromotionTypes));
        }
        else enqueueSnackbar(`${translate({id: 'headerData.copiedErr'})}`, {variant: 'error', persist: false});
    }

    // TODO: Refactor
    const leafletDetail: string = '';
    const [validFromModified, setValidFromModified] = useState<boolean>(false);
    const [validToModified, setValidToModified] = useState<boolean>(false);

    const handleLeafletPropertyChange = (key: string, value: commonAttributeDataModel) => {
        const newLeafletProperties = {...leafletProperties};
        newLeafletProperties[key] = value;
        setLeafletProperties(newLeafletProperties);
    };

    const addPageValidity = () => {
        const pgValidities: pageValidity[] = [...pageValidities];
        pgValidities.push({pageNumber:'1', validFrom: null, validTo: null, modified: false});
        setPageValidities(pgValidities);
    };

    const handlePageValidityChange = (validities: pageValidity[]) => setPageValidities(validities);

    const handleSelectArea = (branchesOfArea:branch[], representativeBranchId: string) => {
        setAdvertisingAreaDialogOpen(false);
        setHeaderData({...headerData, branches: branchesOfArea.map((item) => item.id), leafletRepresentativeBranchId: representativeBranchId});
    };

    const handleRepresentativeBranchChange = (representativeBranchId: string) => setHeaderData({...headerData, leafletRepresentativeBranchId:representativeBranchId})

    const handleLanguageChange = (lang: string) => setLanguage(lang);

    const handleDuplicateConfirm = () => {
        setDuplicateDialogOpen(false);
        saveHeaderData(true);
    };

    const addPageSeasonTheme = () => {
        const pgSeasonThemes: pageThemeSeason[] = [...pageThemeSeasons];
        pgSeasonThemes.push({pageNumber:'1', themeSeasons: {id: null, literals: []}, modified: false});
        setPageThemeSeasons(pgSeasonThemes);
    };

    const addPagePromotionType = () => {
        const pgPromotionTypes: pagePromotionTypes[] = [...pagePromotionTypes];
        pgPromotionTypes.push({pageNumber: '1', promotionTypes: {id: null, literals: []}, modified: false});
        setPagePromotionTypes(pgPromotionTypes);
    };

    const addPageCountryThemedWeeeks = () => {
        const pgCountryThemedWeeks: pageCountryThemedWeeks[] = [...pageCountryThemedWeeks];
        pgCountryThemedWeeks.push({pageNumber: '1', themedWeeks: {id: null, literals: []}, modified: false});
        setPageCountryThemedWeeks(pgCountryThemedWeeks);
    };

    const handlePageThemeSeasonChange = (seasonTheme:pageThemeSeason[]) => setPageThemeSeasons(seasonTheme);

    const handlePagePromotionChange = (promotionType: pagePromotionTypes[]) => setPagePromotionTypes(promotionType);

    const handlePageCountryThemedWeeks = (countryThemedWeek: pageCountryThemedWeeks[]) => setPageCountryThemedWeeks(countryThemedWeek);

    const handlePriceModeChange = (value: boolean) => {
        const newHeaderData: headerData = {...headerData};
        newHeaderData.isGross = value;
        setHeaderData(newHeaderData);
    };

return (
    <div className="viewRoot leafletDescriptionRoot">
        <div className="viewport">
        <LoadingOverlay show={showLoading()} />
        <div className="viewContainer _directionCol">
            <PaperX>
                <div className="_formRowDouble noMargins">
                    <div className="countryBannerSelectorRoot">
                        <CountryDisplayAsInput compact required value={country}/>
                        <div className="banner">
                            <BannerSelector bannerId={headerData.bannerId} onBannerChange={handleBannerChange} countryId={country}/>
                        </div>
                    </div>
                    <div>
                        <PriceModeSelector onChange={(value) => handlePriceModeChange(value)} isGross={headerData?.isGross}/>
                    </div>
                </div>
            </PaperX>
            <div className="_directionRow">
                <PaperX className="_fullHeight headerDataContainer _scrollY">
                    <div>
                        <LealeftRepresentativeBranch branches={branchesData} leafletRepresentativeBranchId={headerData.leafletRepresentativeBranchId}/>
                    </div>
                    <div className="_formRow">
                        <div className="branchesRow">
                            <div>
                                <FormattedMessage id="b.branches"/>: <span>{headerData.branches.length}</span><FormattedMessage id="a.of"/><span>{branchesData.length}</span>
                            </div>
                            <Button onClick={() => setBranchesDialogOpen(true)} variant="contained" color="primary" disabled={bannerId === null} size="small">
                                <FormattedMessage id="headerData.selectBranches"/>
                            </Button>
                            <Button onClick={() => setAdvertisingAreaDialogOpen(true)} variant="contained" color="primary" disabled={bannerId === null} size="small">
                                <FormattedMessage id="headerData.selectByAdvArea"/>
                            </Button>
                        </div>
                    </div>
                    <div className="_formRowDouble">
                        <div>
                            <Attribute id={handyAttributes.leafletType} data={leafletProperties[handyAttributes.leafletType]} onChange={handleLeafletPropertyChange} required multiselect disableAutoselect/>
                        </div>
                        <div>
                            <Attribute id={handyAttributes.promotionType} data={leafletProperties[handyAttributes.promotionType]} onChange={handleLeafletPropertyChange} multiselect disableAutoselect/>
                        </div>
                    </div>
                    <div className="_formRowDouble">
                        <div>
                            <LanguageSelector language={language} onLanguageChange={handleLanguageChange} required={true} languageSet={languages} label={"printMediaOverview.selectLanguage"}/>
                        </div>
                        <div className="attributePosition">
                            <Attribute id={handyAttributes.themeSeason} data={leafletProperties[handyAttributes.themeSeason]} onChange={handleLeafletPropertyChange} multiselect disableAutoselect/>
                        </div>
                    </div>
                    <div className="_formRowDouble">
                            <div>
                                <ValiditySelector id={1}
                                                required
                                                validityFrom={validityFrom || validFromModified ? validityFrom : leafletDetail &&
                                                    headerData.validFrom ? new Date(headerData.validFrom) : null }
                                                validityTo={validityTo || validToModified? validityTo : leafletDetail &&
                                                    headerData.validTo ? new Date(headerData.validTo) : null}

                                                onValidityFromChange={(id, date) => { setValidFromModified(true); setValidityFrom(date)}}
                                                onValidityToChange={(id, date) => { setValidToModified(true); setValidityTo(date)}}
                                />
                            </div>
                            <div className="attributePosition">
                                <Attribute id={handyAttributes.countryThemedWeeks} data={leafletProperties[handyAttributes.countryThemedWeeks]} onChange={handleLeafletPropertyChange} multiselect disableAutoselect/>
                            </div>
                    </div>
                    <div className='_directionRow'>                                
                        <div className="pageLevelColumn _directionCol">
                            {pageValidities.length > 0 && <p><FormattedMessage id="headerData.validityPerPage"/>:</p>}
                            <PageValidities validities={pageValidities} numberOfPages={metaData.numberOfPages} onValidityChange={handlePageValidityChange}/>
                            <div className="_formRow">
                                <Button variant="outlined" color="primary" onClick={addPageValidity} disabled={!metaData.numberOfPages}>
                                    <FormattedMessage id="headerData.addPageValidity"/>
                                </Button>
                            </div>
                            {pagePromotionTypes.length > 0 && <p className="addPageTitle"><FormattedMessage id="headerData.promotionTypesPerPage"/>:</p>}
                            <PagePromotionTypes pagePromotionTypes={pagePromotionTypes} numberOfPages={metaData.numberOfPages} onPromotionTypesChange={handlePagePromotionChange}/>
                            <div className="_formRow">
                                <Button variant="outlined" color="primary" onClick={addPagePromotionType} disabled={!metaData.numberOfPages}>
                                    <FormattedMessage id="headerData.addPagePromotionType"/>
                                </Button>
                            </div>
                            {pageThemeSeasons.length > 0 && <p><FormattedMessage id="headerData.seasonThemePerPage"/>:</p>}
                            <PageThemeSeasons pageThemeSeasons={pageThemeSeasons} numberOfPages={metaData.numberOfPages} onThemeSeasonChange={handlePageThemeSeasonChange}/>
                            <div className="_formRow">
                                <Button variant="outlined" color="primary" onClick={addPageSeasonTheme} disabled={!metaData.numberOfPages}>
                                    <FormattedMessage id="headerData.addPageSeasonTheme"/>
                                </Button>
                            </div>
                            {pageCountryThemedWeeks.length > 0 && <p className="addPageTitle"><FormattedMessage id="headerData.countryThemedWeeksPerPage"/>:</p>}
                            <PageCountryThemedWeeks pageCountryThemedWeeks={pageCountryThemedWeeks} numberOfPages={metaData.numberOfPages} onCountryThemedWeeksChange={handlePageCountryThemedWeeks}/>
                            <div className="_formRow">
                                <Button variant="outlined" color="primary" onClick={addPageCountryThemedWeeeks} disabled={!metaData.numberOfPages}>
                                    <FormattedMessage id="headerData.addPageCountryThemedWeeks"/>
                                </Button>
                            </div>
                        </div>
                        <div className="_directionCol">
                            {mediumType === printMediaType.PRESS_ADVERTISEMENT && <PublicationOverview data={publicationOverviewData}/>}
                        </div>
                    </div>
                </PaperX>
                <PaperX className="_fullHeight leafletBrowserContainer">
                    <LeafletBrowser leaflet={leafletAPIGet.data} showFileName hideColorPicker hideReportPage/>
                    <GoFullScreen path={`${paths.leafletBrowserFullScreen}?id=${leafletId}`}/>
                </PaperX>
            </div>
        </div>
        </div>
        <Footer actionsLeft = {
            <>
                <Button color="secondary" variant="outlined"
                        onClick={handleCopyLeaflet}>
                    <FormattedMessage id="a.copy"/>
                </Button>
                <Button color="secondary" variant="outlined"
                        onClick={handlePasteLeaflet}>
                    <FormattedMessage id="a.paste"/>
                </Button>
            </>
        }
            actionsRight = {
            <>
                <Button color="primary" variant="contained"
                        onClick={() => handleLeafletSave(false)}
                        disabled={cantSave()}>
                    <FormattedMessage id="a.save"/>
                </Button>
                <ButtonClose onClick={goToLeaflets}/>
            </>
            }
        />
        <BranchesSelectionDialog open={branchesDialogOpen}
                                 busy={branchesStatus === API_STATUSES.PENDING}
                                 branches={branchesData}
                                 selectedBranches={headerData.branches}
                                 onSelectionChange={handleBranchesSelectionChange}
                                 onClose={() => setBranchesDialogOpen(false)}
                                 leafletRepresentativeBranchId={headerData.leafletRepresentativeBranchId}
                                 onRepBranchChange={handleRepresentativeBranchChange}
        />
        <AdvertisingAreaDialog open={advertisingAreaDialogOpen}
                               busy={branchesStatus === API_STATUSES.PENDING}
                               bannerId={bannerId}
                               branches={branchesData}
                               onSelectArea={handleSelectArea}
                               onCheckboxClick={handleSelectArea}
                               onClose={() => setAdvertisingAreaDialogOpen(false)}
        />
        <ConfirmDialog open={duplicateDialogOpen}
                       onConfirm={handleDuplicateConfirm}
                       onCancel={() => setDuplicateDialogOpen(false)}
                       message={<DuplicateDialogMsg/>}
                       confirmLabelId="a.confirm"
                       cancelLabelId="a.close"
        />
    </div>
  );
};
export default HeaderData;