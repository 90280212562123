export const paths = {
  describeFrame: '/describeFrame',
  describeFrameLeafletBrowser: '/describeFrameLeaflet',
  describeSpot: '/describeSpot',
  enterHeaderData: '/enterHeaderData',
  framing: '/framing',
  home: '/',
  keyHierarchy: '/keyHierarchy',
  localStorage1: '/test',
  localStorage2: '/test2',
  masterData: '/masterData',
  printMediaOverview: '/printMediaOverview',
  productsList: '/productsList',
  productBulkChange: '/productBulkChange',
  productBulkDescription: '/productBulkDescriptionChange',
  productBulkSupplier: '/productBulkSupplierAssignment',
  productDetails: '/productDetails',
  productDetailsClone: '/productDetailsClone',
  productDetailsNew: '/productDetailsNew',
  branchesList: '/branchesList',
  branchDetails: '/branchDetails',
  advertisingArea: '/advertisingArea',
  advertisingAreasList: '/advertisingAreasList',
  franchiseOwner: '/franchiseOwner',
  franchiseOwnersList: '/franchiseOwnersList',
  gtin: '/gtin',
  leafletBrowserFullScreen: '/leafletBrowser',
  dictionary: '/dictionary',
  dictionaryEdit: '/editDictionary',
  literalCreate: '/createLiteral',
  literal: '/literal',
  attribute: '/attribute',
  attributeCreate: '/createAttribute',
  BPCODetails: '/BPCODetails',
  BPCODescription: '/BPCODescription',
  BPCODescriptionList: '/BPCODescriptionList',
  BPCOList: '/BPCOList',
  BPCODetailsNew: '/BPCODetailsNew',
  brands: '/brands',
  newBrand: '/newBrand',
  editBrand: '/editBrand',
  editStructuredBrand: '/editStructuredBrand',
  newStructuredBrand: '/newStructuredBrand',
  productLineList: '/productLineList',
  productLine: '/productLine',
  statistics: '/statistics',
  advertisementsOverview: '/advertisementsOverviewPrintMedia',
  advertisementsOverviewPromotions: '/advertisementsOverviewPromotions',
  advertisementsSpotOverview: '/advertisementsSpotOverview',
  advertisementBrowser: '/advertisementBrowser',
  advertisementBrowserMedia: '/advertisementBrowserMedia',
  category: '/categories',
  categoriesAttributes: '/categoriesAttributes',
  categoriesHierarchy: '/categoriesHierarchy',
  categoryDetails: '/categoryDetails',
  brickDetails: '/brickDetails',
  reportedAdvertisementsBrowser: '/reportedAdvertisementsBrowser',
  reportedIssues: '/reportedIssues',
  reportedFramesBrowser: '/reportedFramesBrowser',
  fixReportedFrames: '/fixReportedFrames',
  framesOverview: '/framesOverview',
  productDescription: '/productDescription',
  productDescriptionReplace: '/productDescriptionReplace',
  productDescriptionList: '/productDescriptionList',
  myAdvertisements: '/myAdvertisements',
  myTeamsAdvertisements: '/myTeamsAdvertisements',
  retailerHQList: '/retailerHQ',
  retailerHQDetails: '/retailerHQDetails',
  spotOverview: '/spotOverview',
  radioSpotHeaderData: '/radioSpotHeaderData',
  tvSpotHeaderData: '/tvSpotHeaderData',
  spotHeaderData: '/spotHeaderData',
  suppliers: '/suppliers',
  supplierDetails: '/supplierDetails',
};

export const pathLabels = {
  advertisementsOverview: 'nav.overviewAdvertisements',
  attribute: 'attributes.label',
  attributeCreate: 'attributes.new',
  brands: 'b.brands',
  category: 'b.category',
  describeFrame: 'printMediaOverview.describeFrame',
  describeFrameLeafletBrowser: 'printMediaOverview.describeFrame',
  describeSpot: 'media.spotDescribe',
  dictionary: 'dictionary.dict',
  enterHeaderData: 'printMediaOverview.headerDataCTA',
  framesOverview: 'nav.overviewFrames',
  framing: 'a.framing',
  masterData: 'masterData.basicMasterData',
  myTeamsAdvertisements: 'nav.myTeamsAdvertisements',
  printMediaOverview: 'printMediaOverview.overview',
  productLine: 'b.productLine',
  radioSpotHeaderData: 'b.enterHeaderData',
  spotHeaderData: 'b.enterHeaderData',
  spotOverview: 'nav.mediumSpot',
  statistics: 'nav.overviewQuality',
  tvSpotHeaderData: 'b.enterHeaderData'
};