import {Client} from '@stomp/stompjs';
import {WS_PAGE_LOCK} from 'config/webSocket/brokerURL';
import {pageLockCurrentFrame} from 'config/webSocket/destinations';

export const connectWebSocketPageLock = async (
    accessToken: () => Promise<string>,
    sendCurrentData: (client: Client) => void,
    onMessage: (msg) => void
): Promise<Client | null> => {
    try {
        let token: string = await accessToken();
        let formattedToken: string = `Bearer ${token}`;
        const tokenErrorMsg: string = 'ExecutorSubscribableChannel';

        const stompClient = new Client({
            brokerURL: WS_PAGE_LOCK,
            connectHeaders: {'Authorization': formattedToken,},
            onConnect: () => {
                stompClient.subscribe(pageLockCurrentFrame, (message) => {
                    const parsedMsg = JSON.parse(message.body);
                    onMessage(parsedMsg);
                });
                sendCurrentData(stompClient);
            },
            onStompError: async (frame) => {
                console.error(`STOMP error: ${frame.headers['message']}`)
                if (frame.headers['message']?.includes(tokenErrorMsg)) {
                    try {
                        token = await accessToken();
                        stompClient.configure({connectHeaders: {'Authorization': formattedToken,},});
                        stompClient.activate();
                    } catch (error) {
                        console.error(`Error refreshing token: ${error}`);
                    }
                }
            },
        });
        stompClient.activate();
        return stompClient;
      } catch (error) {
          console.error(`Error fetching token: ${error}`);
          return null;
      }
};