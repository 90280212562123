/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { paths, pathLabels } from 'paths';
import { IRootState } from 'shared/reducers';
import { useFormatMessage } from 'utils/translate';

const TitleSetter = () => {
    const translate = useFormatMessage();
    const location: string = useLocation();
    const leafletDialogOpen = useSelector((state: IRootState) => state.leafletUpload.dialogOpen);
    const spotDialogOpen = useSelector((state: IRootState) => state.spotUpload.dialogOpen);
    const pathName: string = window.location.pathname;
    const defaultTitle: string = 'MPM Data Entering';

    function setterFunction() {
        for (let i in paths) {  
            if (pathName === paths[i]) {
                document.title = (pathLabels[i] !== undefined) ? translate({id: pathLabels[i]}) : defaultTitle;
            }
        }
    }
    useEffect(() => {
        if (leafletDialogOpen) {
            document.title = translate({id: 'leafletUploadNavButton.leafletUploadCTA'});
        } else if (spotDialogOpen) {
            document.title = translate({id: 'spotUploadNavButton.spotUploadCTA'});
        } else {
            setterFunction();
        }
    }, [location, leafletDialogOpen, spotDialogOpen]);

    return null
};

export default TitleSetter;