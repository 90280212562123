import axios, {AxiosError} from 'axios';
import {API_GET_USER_PERMISSIONS, API_STATUSES} from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import {currentUserPermissionsResponse, currentUserPermissionsReducerModel} from 'shared/models/permissions.model';

enum ACTION_TYPES {
    START_FETCHING_PERMISSIONS = 'START_FETCHING_PERMISSIONS',
    ON_SUCCESS_PERMISSIONS = 'ON_SUCCESS_PERMISSIONS',
    ON_ERROR_PERMISSIONS = 'ON_ERROR_PERMISSIONS'
}

export function fetchUserPermissions() {
    return function (dispatch) {
        dispatch({
            type: ACTION_TYPES.START_FETCHING_PERMISSIONS
        });
        axios.get<currentUserPermissionsResponse>(API_GET_USER_PERMISSIONS).then((resp) => {
            if (responseValidation(resp.data)) {
                dispatch({
                    type: ACTION_TYPES.ON_SUCCESS_PERMISSIONS,
                    payload: {
                        username: resp.data.username,
                        specialRoles: resp.data.specialRoles
                    }
                });
            } else {
                dispatch({type: ACTION_TYPES.ON_ERROR_PERMISSIONS});
            }
        }).catch((e: AxiosError) => {
            console.error(e);
            dispatch({type: ACTION_TYPES.ON_ERROR_PERMISSIONS});
        });
    };
}

const initialState: currentUserPermissionsReducerModel = {
    status: API_STATUSES.NEW,
    username: '',
    specialRoles: []
};

export type UserPermissionsState = Readonly<typeof initialState>;

const userPermissionsReducer = (state: UserPermissionsState = initialState, action): UserPermissionsState => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCHING_PERMISSIONS:
            return {...state, status: API_STATUSES.PENDING};
        case ACTION_TYPES.ON_SUCCESS_PERMISSIONS:
            return {
                ...state,
                status: API_STATUSES.IDLE,
                username: action.payload.username,
                specialRoles: action.payload.specialRoles
            };
        case ACTION_TYPES.ON_ERROR_PERMISSIONS:
            return {...state, status: API_STATUSES.ERROR};
        default:
            return state;
    }
};

export default userPermissionsReducer;
