import './ProductsListSearchResults.scss'

import {useState} from 'react';
import {Button} from '@mui/material';
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material';
import {GetContextMenuItemsParams, MenuItemDef} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {RowClickedEvent} from 'ag-grid-community';
import {API_PRODUCT_WITH_SUPPLIER_SEARCH} from 'config/api/constants';
import {
    productSearchResult,
    productWithSupplierSearchResult,
    shortCharacteristic
} from 'shared/models/product.model';
import {useFormatMessage} from 'utils/translate';
import {getFromLocalStorage, getObjectFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {ActiveFlagCell} from 'components/AgTable/renderers';
import ProductSearch from 'components/ProductSearch/ProductSearch';
import ToggleTemplates from 'components/Controls/ToggleTemplates';
import {PaperX} from 'components/PaperX';
import ProductPreview from 'components/gfx/ProductPreview/ProductPreview';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {MarketSelector} from 'components/Selectors';
import {
    extractMainAttributes
} from 'modules/Advertisement/AdvertisementsOverview/AdvertisementsTable';
import {CategoryTooltip, MainAttributesTooltip} from 'modules/MasterData/Product/Tooltips';
import ProductReplacementDrawer from './ProductReplacement/ProductReplacementDrawer';
import ProductListLinkButton from './ProductListLinkButton';


export type productSearchTableRow = {
    brand: string,
    brandId: string, //used only in specific scenarios, like bulk supplier/brand change
    category: string,
    characteristics: shortCharacteristic[],
    description: string,
    gtin?: string,
    id: string,
    isTemplate: boolean,
    mainAttributes: string,
    relatedPromotionsCount: number
}

interface productWithSupplierSearchTableRow extends productSearchTableRow{
    supplierName: string
}

interface productsListSearchResultsProps {
    onRowClick: (id:string) => void,
    isHiddenContextMenu: boolean
}

export const productsListSearchQueryStrLocalStorageKey: string = 'productsListViewQueryStr';

export function transformResponse (productSearchResponse: productSearchResult[]): productSearchTableRow[] {
    return productSearchResponse
        .map((item) => ({...item, mainAttributes: extractMainAttributes(item.characteristics)}));
}

export function transformResponseWithSuppliers (productSearchResponse: productWithSupplierSearchResult[]): productWithSupplierSearchTableRow[] {
    return productSearchResponse
        .map((item) => ({...item, mainAttributes: extractMainAttributes(item.characteristics)}));
}

const localStorageShowTemplates = 'showTemplates_masterData';

const ProductsListSearchResults = ({onRowClick, isHiddenContextMenu}: productsListSearchResultsProps) => {
    const translate = useFormatMessage();
    const [productsTableData, setProductsTableData] = useState<productWithSupplierSearchTableRow[]>([]);
    const [showTemplates, setShowTemplates] = useState<boolean>(() => {
        const storedShowTemplates = getObjectFromLocalStorage(localStorageShowTemplates);
        return storedShowTemplates !== null ? storedShowTemplates : true;
    });
    const restoredQueryStr: string = getFromLocalStorage(productsListSearchQueryStrLocalStorageKey);

    const [isReplacementDialogOpen, setIsReplacementDialogOpen] = useState<boolean>(false);
    const [idOfProduct2Replace, setIdOfProduct2Replace] = useState<string>(null);

    const columnDefs: ColDef[] = [
        { field: 'brand', headerName: translate({id: 'b.brand'}), flex: 1, lockVisible: true},
        { field: 'supplierName', headerName: translate({id: 'b.supplier'}), flex: 1 },
        { field: 'description', headerName: translate({id: 'b.descr'}), flex: 1 },
        { field: 'actions', headerName: translate({ id: 'a.actions' }), flex: 1, cellRenderer: (params) => 
            <Button 
                color="secondary" 
                variant="outlined" 
                size="small"
                endIcon={params.node.expanded ? <ArrowDropUp /> : <ArrowDropDown/>}
                onClick={() => {
                    params.node.setExpanded(!params.node.expanded);
                    params.api.refreshCells({rowNodes: [params.node], force: true});
                }}
            >
                {translate({id: params.node.expanded ? 'product.hideImages' : 'product.showImages'})}
            </Button>
        },
        { field: 'category', headerName: translate({id: 'b.category'}), flex: 1,
            tooltipField: 'category',
            tooltipComponent: CategoryTooltip
        },
        { field: 'mainAttributes', headerName: translate({id: 'attributes.label' }), flex: 3,
            tooltipField: 'characteristics',
            tooltipComponent: MainAttributesTooltip
        },
        { field: 'gtin', headerName: translate({id: 'b.gtin'}), flex: 1},
        { field: 'relatedPromotionsCount', headerName: translate({id: 'product.relatedPromotions'}), flex: 1,
            cellRenderer: (params) => <ProductListLinkButton id={params.node.data.id} label={params.node.data.relatedPromotionsCount}/>
        },
        { field: 'isTemplate', headerName: translate({id: 'product.isTemplate' }),
            width: 160, cellClass: 'active-flag-cell',
            cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>)
        },
    ];

    const getContextMenu = (rowData: GetContextMenuItemsParams): MenuItemDef[] => {
        if (isHiddenContextMenu) return [];
        return [
            {
                name: translate({id: 'a.replace'}),
                action: () => handleReplacementDrawerOpen(rowData.node.data.id),
                // from this view we get only active items, but watch on filters view - need to disable also on inactive items
                disabled: rowData.node.data.isTemplate
            }
        ]
    };

    const handleReplacementDrawerOpen = (productId: string) => {
        setIdOfProduct2Replace(productId);
        setIsReplacementDialogOpen(true);
    };

    const handleReplacementDrawerClose = (hasBeenReplaced: boolean) => {
        if (hasBeenReplaced) {
            // remove product that has been replaced
            setProductsTableData(productsTableData.filter((item) => item.id !== idOfProduct2Replace));
        }
        setIdOfProduct2Replace(null);
        setIsReplacementDialogOpen(false);
    };

    const handleToggleTemplate = (value: boolean) => {
        setShowTemplates(value);
        storeInLocalStorage(localStorageShowTemplates, JSON.stringify(value));
    };

    const handleRowClick = (e: RowClickedEvent) => {
        const htmlTargetEl = e.event.target as HTMLElement;
        if (htmlTargetEl.className.split(' ').includes('ag-cell')) onRowClick(e.data.id);
        else return;
    }

    return (
        <>
            <div className="viewContainer _directionCol productsListSearchResultsRoot">
                <PaperX>
                    <div className="_formRowDouble noMargins">
                        <ProductSearch<productWithSupplierSearchResult> onNewData={(data) => setProductsTableData(transformResponseWithSuppliers(data))}
                                       queryStrStorageKey={productsListSearchQueryStrLocalStorageKey}
                                       restoredQueryStr={restoredQueryStr}
                                       searchUrl={API_PRODUCT_WITH_SUPPLIER_SEARCH}
                                       templatesExcluded={!showTemplates}
                        />
                        <div className="marketTemplateContainer">
                            <MarketSelector/>
                            <ToggleTemplates value={showTemplates} onChange={(newValue) => handleToggleTemplate(newValue)}/>
                        </div>
                    </div>
                </PaperX>
                <PaperX className="_fullHeight _fullWidth _fullTable _customTooltipPositionFix">
                    <AgTable
                        rowData={productsTableData}
                        defaultColDef={{
                            resizable: true,
                            sortable: true,
                            suppressMenu: false
                        }}
                        tooltipShowDelay={500}
                        columnDefs={columnDefs}
                        onRowClicked={handleRowClick}
                        masterDetail={true}
                        detailCellRenderer={(rowData) => <ProductPreview productId={rowData.data.id}/>}
                        getContextMenuItems={(rowData) => getContextMenu(rowData)}
                        suppressContextMenu={false}
                        localStorageColumnsKey={localStorageColumnsKeys.productsListSearchResults}
                    />
                </PaperX>
            </div>
            <ProductReplacementDrawer data={productsTableData}
                                      id={idOfProduct2Replace}
                                      onClose={(hasBeenReplaced) => handleReplacementDrawerClose(hasBeenReplaced)}
                                      open={isReplacementDialogOpen}
            />
        </>
    );
};

export default ProductsListSearchResults;