import './SpotOverviewActions.scss';
import {Link} from 'react-router-dom';
import {ArrowForward} from '@mui/icons-material';
import {Button} from '@mui/material';
import {paths} from 'paths';
import {spotOverviewRow, spotStatus} from 'shared/models/media.model';
import {useFormatMessage} from 'utils/translate';
import {mediumType} from 'shared/models/leaflet.model';

const quickActions = {
    EDIT_HEADER_DATA: 'EDIT_HEADER_DATA',
    VERIFY: 'VERIFY',
    DESCRIBE_SPOT: 'DESCRIBE_SPOT',
    NO_ACTION: 'NO_ACTION'
};

export const spotStatusLabels = {
    [spotStatus.REGISTERED]: 'media.statusRegistered',
    [spotStatus.PENDING]: 'media.statusPending',
    [spotStatus.VERIFIED]: 'media.statusVerified',
    [spotStatus.DONE]: 'media.statusDone'
}

interface SpotOverviewQuickActionProps {
    quickAction: string,
    id: string,
    mediumType: mediumType
}

function SpotOverviewQuickAction(props: SpotOverviewQuickActionProps) {
    const translate = useFormatMessage();
    const {id, quickAction, mediumType: mediumTypeProp} = props;

    const verifyLabelId = mediumTypeProp === mediumType.TV_SPOT ? 'media.tvSpotVerify' : 'media.radioSpotVerify';
    const describeLabelId = mediumTypeProp === mediumType.TV_SPOT ? 'media.tvSpotDescribe' : 'media.radioSpotDescribe';

    switch (quickAction) {
        case quickActions.EDIT_HEADER_DATA:
            return (
                <Link to={`${paths.spotHeaderData}?id=${id}`}>
                    <Button size="small" variant="outlined" endIcon={<ArrowForward/>} color="secondary">{translate({id: 'printMediaOverview.headerDataCTA'})}</Button>
                </Link>);
        case quickActions.VERIFY:
            return (
                <Link to={`${paths.spotHeaderData}?id=${id}`}>
                    <Button size="small" variant="outlined" endIcon={<ArrowForward/>} color="secondary">{translate({id: verifyLabelId})}</Button>
                </Link>);
        case quickActions.DESCRIBE_SPOT:
            return (
                <Link to={`${paths.describeSpot}?id=${id}&from=${paths.spotOverview}`}>
                    <Button size="small" variant="outlined" endIcon={<ArrowForward/>} color="secondary">{translate({id: describeLabelId})}</Button>
                </Link>);
        case quickActions.NO_ACTION:
            return null;
        default:
            return null;
    }
}

interface SpotOverviewActionProps {
    data: spotOverviewRow,
    value: string,
}

export function SpotOverviewAction({data, value}: SpotOverviewActionProps) {
    const translate = useFormatMessage();
    return (<div className="spotActionRoot">
        <span>{translate({id: spotStatusLabels[value]})}</span>
        <SpotOverviewQuickAction quickAction={data.quickAction} id={data.id} mediumType={data.mediumType}/>
    </div>);
}

interface SpotsOverviewLabelProps {
    value: string
}

export function SpotsOverviewLabel({value}: SpotsOverviewLabelProps) {
    const translate = useFormatMessage();

    return (<div className="overviewSpotsActionRoot">
        <span>{translate({id: spotStatusLabels[value]})}</span>
    </div>);
}