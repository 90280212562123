import {useMemo} from 'react';
import {paths} from 'paths';
import {RowClickedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {advertisementsOverviewRow} from 'shared/models/advertisement.model';
import {useFormatMessage} from 'utils/translate';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import DetailsRow from 'modules/Advertisement/AdvertisementsOverviewPromotions/DetailsRow';
import PromoType from 'modules/Advertisement/AdvertisementsOverviewPromotions/PromoType';
import PriceCell from 'modules/Advertisement/AdvertisementsOverviewPromotions/PriceCell';
import GeneralCell from 'modules/Advertisement/AdvertisementsOverviewPromotions/GeneralCell';

const buildAdvertisementsColumnDef = (translate: ({id: string}) => string, myTeamsAdvView: boolean = false): ColDef[] => {
    return [
        // leaflet:
        { field: 'bannerName', headerName: translate({ id: 'b.banner' })},
        { field: 'retailerName', headerName: translate({ id: 'b.retailer' })},
        { field: 'repBranch', headerName: translate({ id: 'b.representativeBranch' })},
        { field: 'pageNumber', headerName: translate({id: 'reportedFramesSummary.pageNo'}), width: 100},
        // product:
        { field: 'brandName', headerName: translate({ id: 'b.brand' })},
        { field: 'description', headerName: translate({ id: 'b.descr' }),
            tooltipValueGetter: (params) => params.value},
        { field: 'characteristics', headerName: translate({ id: 'attributes.label' }), sortable: false,
            tooltipValueGetter: (params) => params.value},
        { field: 'category', headerName: translate({ id: 'b.category' })}, // category as it might be other-than-brick for BPCOs
        { field: 'promotionObjectType', headerName: translate({ id: 'productSearch.type' }),
            cellRenderer: (params) => <PromoType data={params.data}/>},
        // price
        { field: 'priceCol', headerName: translate({ id: 'b.price' }), width: 240, sortable: false,
            cellRenderer: (params) => <PriceCell data={params.data}/>},
        // promotional information:
        {field: 'generalInformation', headerName: translate({ id: 'b.general' }), sortable: false,
            tooltipValueGetter: null,
            cellRenderer: (params) => <GeneralCell data={params.data}/>},
        ...(myTeamsAdvView ? [{field: 'createdBy', headerName: translate({id: 'printMediaOverview.createdBy'})}] : []),
    ];
}

interface AdvOverviewPromotionsTableProps {
    data: advertisementsOverviewRow[],
    myTeamsAdvView?: boolean
}

const AdvOverviewPromotionsTable = (props: AdvOverviewPromotionsTableProps) => {
    const translate = useFormatMessage();
    const {data, myTeamsAdvView} = props;

    const advertisementsColumnDef: ColDef[] = useMemo(() => buildAdvertisementsColumnDef(translate, myTeamsAdvView), [translate, myTeamsAdvView]);
    const handleRowClick = (rowData: RowClickedEvent) => rowData.node.setExpanded(!rowData.node.expanded);

    return (
        <AgTable
            defaultColDef={{
                resizable: true,
                sortable: true,
                tooltipValueGetter: () => translate({id: 'a.click2expand'})
            }}
            rowData={data}
            columnDefs={advertisementsColumnDef}
            suppressContextMenu={false}
            onRowClicked={(rowData: RowClickedEvent) => handleRowClick(rowData)}
            masterDetail={true}
            tooltipShowDelay={500}
            detailCellRenderer={(rowData) => <DetailsRow frameId={rowData.data.frameId}
                                                         leafletId={rowData.data.leafletId}
                                                         pageNumber={rowData.data.pageNumber}
                                                         mediumId={rowData.data.mediumId}
                                                         mediumType={rowData.data.mediumType}
                                                         from={myTeamsAdvView && paths.myTeamsAdvertisements}
            />}
            localStorageColumnsKey={!myTeamsAdvView ? localStorageColumnsKeys.advertisementsOverviewPromotions: localStorageColumnsKeys.advertisementsOverviewPromotionsWithCreator}
        />
    );
};

export default AdvOverviewPromotionsTable;