import {SelectionChangedEvent} from 'ag-grid-community';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Tooltip} from '@mui/material';
import {productSearchTableRow} from 'modules/MasterData/Product/ProductsListSearchResults';
import {MainAttributesMUITooltipContent} from 'modules/MasterData/Product/Tooltips';
import {useFormatMessage} from 'utils/translate';
import AgTable, {localStorageColumnsKeys, selectAllColDef} from 'components/AgTable/AgTable';
interface ProductBulkChangeTableProps {
    data: productSearchTableRow[],
    onSelectionChange: (selectedProducts: productSearchTableRow[]) => void,
    pagination?: boolean
}

const ProductBulkChangeTable = ({data, onSelectionChange, pagination}: ProductBulkChangeTableProps) => {
    const translate = useFormatMessage();

    const columnDefs: ColDef[] = [
        selectAllColDef,
        { field: 'brand', headerName: translate({id: 'b.brand'}), flex: 1, lockVisible: true},
        { field: 'supplierName', headerName: translate({id: 'b.supplier'}), flex: 1 },
        { field: 'description', headerName: translate({id: 'b.descr'}), flex: 1 },
        { field: 'mainAttributes', headerName: translate({id: 'attributes.label' }), flex: 3,
            cellRenderer: (params) => <Tooltip title={<MainAttributesMUITooltipContent value={params.data.characteristics}/>}>
                <div>{params.value}</div>
            </Tooltip>
        },
        { field: 'gtin', headerName: translate({id: 'b.gtin'}), flex: 1}
    ];

    return (
            <AgTable
                rowData={data}
                defaultColDef={{
                    resizable: true,
                    sortable: true,
                    suppressMenu: true
                }}
                tooltipShowDelay={0}
                columnDefs={columnDefs}
                suppressContextMenu={false}
                onSelectionChanged={(e: SelectionChangedEvent) => onSelectionChange(e.api.getSelectedNodes().map((node) => node.data))}
                rowSelection="multiple"
                pagination={pagination}
                localStorageColumnsKey={localStorageColumnsKeys.productBulkChangeTable}
            />
        );
};

export default ProductBulkChangeTable;