/* eslint-disable react-hooks/exhaustive-deps */
import './BannerSelector.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { API_STATUSES } from 'config/api/constants';
import { IRootState } from 'shared/reducers';
import { bannerWithRetailer } from 'shared/models/banner.model';
import { fetchBannersWithRetailers } from 'shared/reducers/bannersWithRetailer';
import { useFormatMessage } from 'utils/translate';

type BannerSelectorMultipleProps = {
    bannerIds: string[],
    countryId: string,
    onBannerChange: (bannerIds: bannerWithRetailer[]) => void,
    required: boolean
};

export const BannerSelectorMultiple = ({ bannerIds, countryId, onBannerChange, required }: BannerSelectorMultipleProps) => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();

    const bannersStatus = useSelector((state: IRootState) => state.bannersWithRetailer.status);
    const bannersData = useSelector((state: IRootState) => state.bannersWithRetailer.data);

    const [retailerNameOfFirstBanner, setRetailerNameOfFirstBanner] = useState<string>('');

    const [filteredBanners, setFilteredBanners] = useState<bannerWithRetailer[]>([]);

    const [selectedBanners, setSelectedBanners] = useState<bannerWithRetailer[]>([]);

    useEffect(() => {
        if (bannersStatus === API_STATUSES.ERROR) {
            dispatch(fetchBannersWithRetailers());
        }
    }, []);

    useEffect(() => {
        if (bannersStatus === API_STATUSES.NEW) {
            dispatch(fetchBannersWithRetailers());
        }
    }, [bannersStatus]);

    useEffect(() => {
        if (bannerIds.length !== 0){
            const foundBanners: bannerWithRetailer[] = [];
            bannerIds.forEach((bannerId) => bannersData.find((item) => bannerId === item.bannerId) && foundBanners.push(bannersData.find((item) => bannerId === item.bannerId)))
            setSelectedBanners(foundBanners);
        }
    },[bannersData, bannerIds]);

    useEffect(() => {
        if (selectedBanners.length !== 0 && selectedBanners) {
            const firstSelectedBanner: bannerWithRetailer = selectedBanners[0];
            setFilteredBanners(bannersData.filter((item) => item.countryId === countryId && item.retailerName === firstSelectedBanner.retailerName && item.bannerId));
        }else {
            setFilteredBanners(bannersData.filter((item) => item.countryId === countryId));
        }
    },[bannersData, countryId, selectedBanners]);

    useEffect(() => {
        if (selectedBanners.length !== 0 && selectedBanners) {
            const firstSelectedBanner: bannerWithRetailer = selectedBanners[0];
            setRetailerNameOfFirstBanner(firstSelectedBanner.retailerName);
        } else setRetailerNameOfFirstBanner(null);
        }, [selectedBanners]);

    const filterBanners = (options: bannerWithRetailer[], { inputValue }): bannerWithRetailer[] => {
        const loCased: string = inputValue ? inputValue.toLowerCase() : '';
        return options.filter((item) => item.bannerName.toLowerCase().indexOf(loCased) > -1 || item.retailerName.toLowerCase().indexOf(loCased) > -1);
    }

    const isBannerWithRetailer = (item: (bannerWithRetailer | string)): item is bannerWithRetailer => {
        return item && typeof item === "object" && "retailerName" in item && "bannerName" in item && "bannerId" in item;
    };

    const handleChange = (value: (bannerWithRetailer | string)[]) => {
        if (value.every(isBannerWithRetailer)) {
            setSelectedBanners(value);
            onBannerChange(value);
        }
    };

    const findOptionForDataId = () => {
        const matchArray = [];
        selectedBanners.forEach((dataItem) => {
            let match = filteredBanners.find((item) => item.bannerId === dataItem.bannerId);
            if (match) {
                matchArray.push(match);
            }
        });
        return matchArray;
    };

    return (
        <div className="bannerSelectorRoot">
                <Autocomplete
                    className="bannerSelectorInput"
                    multiple
                    onChange={(e, value) => handleChange(value)}
                    value={selectedBanners || []}
                    options={filteredBanners}
                    filterOptions={filterBanners}
                    isOptionEqualToValue={(option, value) => option.bannerId === value.bannerId}
                    getOptionLabel={(option) => `${option.bannerName}       -       ${option.retailerName} (${option.countryId})`}
                    renderOption={(props, option) => (
                        <li {...props} className={selectedBanners && bannerIds?.find((bannerId) => bannerId === option.bannerId) ? "bannerSelectorOption selected" : "bannerSelectorOption"} key={option['bannerId']}>
                            <span className="bannerName">{option['bannerName']}</span>
                            <span>{option['retailerName']} ({option['countryId']})</span>
                        </li>
                    )}
                    renderTags={(value, getTagProps) =>
                        findOptionForDataId().map((option, index) =>
                            <Chip color="secondary" label={option.bannerName} {...getTagProps({ index })} />
                        )
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={(selectedBanners.length !== 0) ? `${translate({id: 'b.banner'})} - ${retailerNameOfFirstBanner}` : `${translate({id: 'b.banner'})}`}
                            required={required}
                        />
                    )}
                />
                {bannersStatus === API_STATUSES.PENDING &&
                    <div className="bannerSelectorInput loading"><span>{translate({id: 'a.loading'})}</span></div>}
        </div>
    );
};

export default BannerSelectorMultiple;