interface WithPageNumber {
    pageNumber: string;
}

export function sortByPageNumber<pageCharacteristic extends WithPageNumber>(pageCharacteristics: pageCharacteristic[]): pageCharacteristic[] {
    return pageCharacteristics.sort((a, b) => {
            const numA = parseInt(a.pageNumber.split(/[-,]/)[0], 10)
            const numB = parseInt(b.pageNumber.split(/[-,]/)[0], 10)
            return numA - numB;
        }
    )
}