import './ProductBPCODisplay.scss';

import {FormattedMessage} from 'react-intl';
import {Divider} from '@mui/material';
import {handyAttributes} from 'shared/handyAttributes';
import {
    apiGetPromotionsForFrameResponsePromotionTypeBPCOType,
    apiGetPromotionsForFrameResponsePromotionTypeProductType
} from 'shared/models/frame.model';
import {characteristic} from 'shared/models/product.model';
import {handyLiterals} from "shared/handyLiterals";

interface ProductDetailsProps {
    product: apiGetPromotionsForFrameResponsePromotionTypeProductType
}

interface productBPCOPropertyProps {
    label: string,
    value: string
}

const ProductBPCOProperty = (props: productBPCOPropertyProps) => (
    <div className="productBpcoPropertyRow">
        <span className="productBpcoPropertyCol productBpcoPropertyLabel">
            <FormattedMessage id={props.label}/>:
        </span>
        <span className="productBpcoPropertyCol"> {props.value} </span>
    </div>);

const ProductBpcoCharacteristic = (props: productBPCOPropertyProps) => (
    <div className="productBpcoPropertyRow">
        <span className="productBpcoPropertyCol productBpcoPropertyLabel"> {props.label}:</span>
        <span className="productBpcoPropertyCol">{props.value}</span>
    </div>);

export const ProductDetails = ({product}: ProductDetailsProps) => {
    const {brand, category, characteristics, description} = product;

    return buildProductBpcoDisplay(brand, category, characteristics, description);
};

interface BPCODetailsProps {
    bpco: apiGetPromotionsForFrameResponsePromotionTypeBPCOType
}

export const BPCODetails = ({bpco}: BPCODetailsProps) => {
    const {brand, characteristics, category, description} = bpco;

    return buildProductBpcoDisplay(brand, category, characteristics, description);
};

const buildProductBpcoDisplay = (brand: string, category: string, characteristics: characteristic[], description: string) => {

    const filteredCharacteristics :characteristic[] = characteristics.filter((characteristic) => characteristic.valueId !== handyLiterals.gpcUnclassified && characteristic.valueId !== handyLiterals.unclassified && characteristic.valueId !== handyLiterals.unidentified && characteristic.valueId !== handyLiterals.gpcUnidentified && characteristic.id !== handyAttributes.description);

    return (
        <div className="productBpcoDisplayRoot">
            <div className="_directionCol productBpcoColumn">
                <ProductBPCOProperty label="b.brand" value={brand}/>
                <ProductBPCOProperty label="b.category" value={category}/>
                <ProductBPCOProperty label="b.descr" value={description}/>
                <Divider/>
                <div className="characteristics">
                    {filteredCharacteristics
                        .map((item) =>
                            (
                                <div key={item.id} className="span.productBpcoPropertyCol">
                                    <ProductBpcoCharacteristic label={item.name} value={item.value}/>
                                </div>
                            )
                        )
                    }
                </div>
            </div>
        </div>
    )
}