/* eslint-disable react-hooks/exhaustive-deps */
import './SpotHeaderData.scss';

import {ChangeEvent, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios, {AxiosError} from 'axios';
import {isValid, parseISO} from 'date-fns';
import {useSnackbar} from 'notistack';
import {API_SPOT_AND_BROADCAST} from 'config/api/constants';
import {Alert, Button, IconButton, TextField} from '@mui/material';
import {Attribute} from 'components/Attribute';
import ButtonClose from 'components/Buttons/ButtonClose';
import Footer from 'components/Footer';
import {PaperX} from 'components/PaperX';
import {LoadingOverlay} from 'components/LoadingOverlay/LoadingOverlay';
import {ValiditySelector} from 'components/Selectors';
import LanguageSelector from 'components/Selectors/LanguageSelector/LanguageSelector';
import {languages} from 'modules/Dictionaries/Dictionary';
import {dateToString} from 'modules/Leaflet/HeaderData/PageValidity/pageValiditiesIO';
import MediumProposals from 'modules/Medium/MediumProposals';
import MediaAudiofile from 'modules/Medium/MediaAudiofile/MediaAudiofile';
import {paths} from 'paths';
import {handyAttributes} from 'shared/handyAttributes';
import {bannerWithRetailer} from 'shared/models/banner.model';
import {commonAttributeDataModel, mapOfMultiselectAttributes} from 'shared/models/attributeComponent.model';
import {spotPutPayloadModel, spotResponseModel, spotStatus} from 'shared/models/media.model';
import {getId} from 'utils/routing';
import {lockLevels, lockSetter, releaseMediumLock} from 'utils/lockUtils';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';
import {mediumType} from 'shared/models/leaflet.model';
import {RouteComponentProps} from 'react-router';
import AgTable from 'components/AgTable/AgTable';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import BannerSelectorMultiple from 'components/Selectors/BannerSelector/BannerSelectorMultiple';
import CountryDisplayAsInput from "components/Displays/CountryDisplayAsInput";

const spotDataTemplate: spotResponseModel = {
    country: '',
    headerData: {
        bannerIds: [],
        bannerNames: [],
        countryThemedWeeks: [],
        language: '',
        themeSeasons: [],
        transcription: '',
        validFrom: '',
        validTo: ''
    },
    spotId: '',
    status: null,
    transcriptProposals: [],
    metaData: {
        sourceFile: '',
        storageFileName: ''
    },
    mediumType: mediumType.RADIO_SPOT,
    broadcastOverview: []
}

function buildSpotPropsTemplate (): mapOfMultiselectAttributes {
    const template: mapOfMultiselectAttributes = {};
    template[handyAttributes.themeSeason] = {id: handyAttributes.themeSeason, literals: []};
    template[handyAttributes.countryThemedWeeks] = {id: handyAttributes.countryThemedWeeks, literals: []};
    return template;
}

function restoreProperties (themeSeasons: string[], themedWeeks: string[]): mapOfMultiselectAttributes {
    const properties: mapOfMultiselectAttributes = {};
    properties[handyAttributes.themeSeason] = {
        id: handyAttributes.themeSeason,
        literals: themeSeasons ? themeSeasons.map((item) => ({literalId: item})) : []
    };
    properties[handyAttributes.countryThemedWeeks] = {
        id: handyAttributes.countryThemedWeeks,
        literals: themedWeeks ? themedWeeks.map((item) => ({literalId: item})) : []
    };
    return properties;
}

interface SpotHeaderDataProps {
    history: RouteComponentProps["history"];
    mediumType?: mediumType;
}

const getBroadcastPlanTableColumnDefs = (translate: Function) => {
    return [
        { field: 'date', headerName: translate({ id: 'b.date' }), flex: 1, lockVisible: true},
        { field: 'time', headerName: translate({ id: 'b.time' }), flex: 1,
            valueGetter:
                (params) => {
                    const time: string[] = params.data.time.split(':');
                    time.splice(2, 1);
                    return time.join(':');
                }
        },
        { field: 'creativeId', headerName: translate({id: 'broadcastOverview.creativeId'}), flex: 1},
        { field: 'channel', headerName: translate({ id: 'broadcastOverview.channel' }), flex: 1},
    ]
}

const SpotHeaderData = ({history, mediumType}: SpotHeaderDataProps) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [bannerIds, setBannerIds] = useState<string[]>([]);
    const [language, setLanguage] = useState<string>(undefined);
    const [spotId, setSpotId] = useState<string>(null);
    const [validityFrom, setValidityFrom] = useState<Date>(null);
    const [validityTo, setValidityTo] = useState<Date>(null);
    const [validFromModified, setValidFromModified] = useState<boolean>(false);
    const [validToModified, setValidToModified] = useState<boolean>(false);
    const [spotProperties, setSpotProperties] = useState<mapOfMultiselectAttributes>(buildSpotPropsTemplate());
    const [transcription, setTranscription] = useState<string>(spotDataTemplate.headerData.transcription);
    const [data, setData] = useState<spotResponseModel>(spotDataTemplate);
    const [selectedFile, setSelectedFile] = useState<File | string>(null);
    const [transcriptProposals, setTranscriptProposals] = useState<string[]>(null);
    const [isBroadcastPlanShown, setIsBroadcastPlanShown] = useState<boolean>(false);

    const isVerification = !!data.metaData.storageFileName;
    const hasMandatoryVerificationFields = [bannerIds,bannerIds.length !== 0, language, validityFrom, validityTo].every(Boolean);

    useEffect(() => {
        const spotId: string = getId();
        if (spotId) {
            setSpotId(spotId);
            setIsLoading(true);
            lockSetter(lockLevels.medium);
            axios.get<spotResponseModel>(`${API_SPOT_AND_BROADCAST}/${spotId}?acquireLock=true`)
                .then((response) => {
                    if (responseValidation(response.data)) {
                        setData(response.data);
                    } else {
                        enqueueSnackbar(`${translate({id: 'media.spotFetchErr'})}`, {variant: 'error', persist: false});
                        history.push(paths.spotOverview);
                    }
                })
                .catch((e: AxiosError) => {
                    if (axios.isAxiosError(e)) {
                        console.log(e);
                        if (e?.response?.status === 409) {
                            enqueueSnackbar(`${translate({id: 'media.spotLocked'})}`, {variant: 'error', persist: false});
                        } else {
                            enqueueSnackbar(`${translate({id: 'media.spotFetchErr'})}`, {variant: 'error', persist: false});
                        }
                        history.push(paths.spotOverview);
                    }
                })
                .finally(() => setIsLoading(false));
        }
        return history.listen(location => (location.pathname === paths.spotOverview) && releaseMediumLock());
    }, []);

    useEffect(() => {
        if (data.headerData.bannerIds) setBannerIds(data.headerData.bannerIds);
        if (data.headerData.language) setLanguage(data.headerData.language);
        if (data.headerData.transcription) setTranscription(data.headerData.transcription);
        if (data.headerData.validFrom) setValidityFrom(parseISO(data.headerData.validFrom));
        if (data.headerData.validTo) setValidityTo(parseISO(data.headerData.validTo));
        setSpotProperties(restoreProperties(data.headerData.themeSeasons, data.headerData.countryThemedWeeks));
        if (data.metaData.sourceFile) setSelectedFile(data.metaData.sourceFile);
        if (data.transcriptProposals) setTranscriptProposals(data.transcriptProposals);
    }, [data]);

    useEffect(() => {
        if (isVerification) {
            document.title = translate({id: 'nav.mediumVerification'});
        }
    }, [isVerification]);

    const handleLanguageChange = (lang: string) => setLanguage(lang);

    const handleSpotPropertyChange = (key: string, value: commonAttributeDataModel) => {
        const newSpotProperties: mapOfMultiselectAttributes = {...spotProperties};
        newSpotProperties[key] = value;
        setSpotProperties(newSpotProperties);
    };

    const createSpotBroadcastPayload: spotPutPayloadModel = {
        country: data.country,
        headerData: {
            mediumType: mediumType || data.mediumType,
            bannerIds: bannerIds,
            countryThemedWeeks: spotProperties[handyAttributes.countryThemedWeeks].literals.map((item) => item.literalId),
            language: language,
            themeSeasons: spotProperties[handyAttributes.themeSeason].literals.map((item) => item.literalId),
            transcription: transcription,
            validFrom: isValid(validityFrom) ? dateToString(validityFrom) : null,
            validTo: isValid(validityTo) ? dateToString(validityTo) : null
        }
    }

    const handleSelectProposal = (selectedItem: string) => setTranscription(selectedItem);

    const handleSaveClick = async () => {
        setIsLoading(true);

        axios.put(`${API_SPOT_AND_BROADCAST}/${spotId}`, createSpotBroadcastPayload)
            .then(() => {
                enqueueSnackbar(`${translate({id: 'media.spotSucc'})}`, {variant: 'success', persist: false});
                releaseMediumLock();
                history.push(paths.spotOverview);
            })
            .catch((e: AxiosError) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'media.spotSaveErr'})}`, {variant: 'error', persist: false});
            })
            .finally(() => setIsLoading(false));
    }

    const canSave = (): boolean => {
        if (isVerification && !hasMandatoryVerificationFields) {
            return false;
        } else if (data.status === spotStatus.REGISTERED) {
            return [language, transcription].every(Boolean)
        } else return [language].every(Boolean) && (!!transcription || !!selectedFile);
    }

    const handleSelectFile = (selectedFile: File) => setSelectedFile(selectedFile);

    const handleDeleteFile = () => setSelectedFile(null);

    const handleBannerChange = (banners: bannerWithRetailer[]) => {
        const newBannerIds: string[] = [];
        banners.length !== 0 && banners.forEach((newBanner) => newBannerIds.push(newBanner.bannerId));
        setBannerIds(newBannerIds);
   }

    return (
        <div className="viewRoot spotDescriptionRoot">
            <div className="viewport">
                <LoadingOverlay show={isLoading}/>
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <div className="_directionRow countryBannerSelector" >
                            <div className="countrySelector">
                                <CountryDisplayAsInput compact value={data.country}/>
                            </div>
                            <div className="_fullWidth">
                                <BannerSelectorMultiple countryId={data.country} bannerIds={bannerIds} onBannerChange={handleBannerChange} required/>
                            </div>
                        </div>
                    </PaperX>
                    <div className="_directionRow">
                        <PaperX className="spotContainer _fullWidth _scrollY">
                            <div className="_formRowDouble">
                                <LanguageSelector language={language} onLanguageChange={handleLanguageChange} languageSet={languages} label="media.spotLanguage" required/>
                                <div>
                                    <Attribute id={handyAttributes.themeSeason} data={spotProperties[handyAttributes.themeSeason]} onChange={handleSpotPropertyChange} multiselect disableAutoselect/>
                                </div>
                            </div>
                            <div className="_formRowDouble">
                                <ValiditySelector id={1}
                                                  labelFrom={translate({id: 'b.validFrom'})}
                                                  labelTo={translate({id: 'b.validTo'})}
                                                  validityFrom={validityFrom || validFromModified ? validityFrom : '' && spotDataTemplate.headerData.validFrom ? new Date(spotDataTemplate.headerData.validFrom) : null}
                                                  validityTo={validityTo || validToModified? validityTo : '' && spotDataTemplate.headerData.validTo ? new Date(spotDataTemplate.headerData.validTo) : null}
                                                  onValidityFromChange={(_id, date: Date) => {
                                                    setValidFromModified(true);
                                                    setValidityFrom(date);}}
                                                  onValidityToChange={(_id, date: Date) => {
                                                    setValidToModified(true);
                                                    setValidityTo(date);}}
                                                  required={isVerification}
                                />
                                <div>
                                    <Attribute id={handyAttributes.countryThemedWeeks} data={spotProperties[handyAttributes.countryThemedWeeks]} onChange={handleSpotPropertyChange} multiselect disableAutoselect/>
                                </div>
                            </div>
                            <div className="_formRowDouble">
                                <div>
                                    <MediaAudiofile
                                        country={data.country}
                                        filename={data.metaData.sourceFile}
                                        storageFileName={data.metaData.storageFileName}
                                        isPlayMode={!!data.metaData.storageFileName}
                                        isUploadDisabled={!!spotId}
                                        onDelete={handleDeleteFile}
                                        onSelect={handleSelectFile}
                                        mediumType={mediumType || data.mediumType}
                                    />
                                    {isVerification && !hasMandatoryVerificationFields ? <Alert severity="info">{translate({id: 'media.spotSaveInfo'})}</Alert> : null}
                                    <div className="transcriptionContainer">
                                        <TextField
                                            label={translate({id: 'media.transcription'})}
                                            rows={20}
                                            multiline
                                            fullWidth
                                            value={transcription}
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setTranscription(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {transcriptProposals && transcriptProposals.length > 0 &&
                                        <MediumProposals
                                            transcriptProposals={transcriptProposals}
                                            handleSelectProposal={handleSelectProposal}
                                        />
                                    }
                                    <>
                                        { data.broadcastOverview.length !== 0 ?
                                            <>
                                            { isBroadcastPlanShown ?
                                                    <div className="broadcastOverviewView">
                                                        <p className="broadcastOverviewTitle">
                                                            {translate({id: 'broadcastOverview.title'})}
                                                            <span className="broadcastOverviewInformation"> - {translate({id: 'media.broadcastOverviewHide'})} </span>
                                                            <IconButton onClick={() => setIsBroadcastPlanShown(false)}>
                                                                <ExpandLess/>
                                                            </IconButton>
                                                        </p>
                                                        <AgTable
                                                            defaultColDef={{
                                                                suppressMenu: false,
                                                                resizable: true,
                                                                sortable: true
                                                            }}
                                                            rowData={data.broadcastOverview}
                                                            columnDefs={getBroadcastPlanTableColumnDefs(translate)}
                                                        />
                                                    </div>
                                                    :
                                                    <div className="broadcastOverviewNoShowView">
                                                        <p className="broadcastOverviewTitle">
                                                            {translate({id: 'broadcastOverview.title'})}
                                                            <span className="broadcastOverviewInformation"> - {translate({id: 'media.broadcastOverviewShow'})} </span>
                                                            <IconButton onClick={() => setIsBroadcastPlanShown(true)}>
                                                                <ExpandMore/>
                                                            </IconButton>
                                                        </p>
                                                    </div>
                                            }
                                            </>
                                            :
                                            <div  className="broadcastOverviewNoShowView">
                                                <p className="broadcastOverviewTitle">
                                                    {translate({id: 'broadcastOverview.title'})}
                                                    <span className="broadcastOverviewInformation"> - {translate({id: 'spotUpload.statusPreconditionFailed'})} </span>
                                                </p>
                                            </div>
                                        }
                                    </>
                                </div>
                            </div>
                        </PaperX>
                    </div>
                </div>
            </div>
            <Footer actionsRight = {<>
                    <Button color="primary"
                            variant="contained"
                            onClick={handleSaveClick}
                            disabled={!canSave()}>
                        <FormattedMessage id="a.save"/>
                    </Button>
                    <ButtonClose onClick={() => history.push(paths.spotOverview)}/>
                </>}
            />
        </div>
      );
    };
export default SpotHeaderData;