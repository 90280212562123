import {Button} from "@mui/material";
import {useFormatMessage} from "utils/translate";
import {paths} from "paths";
import { Link } from 'react-router-dom';
import './BtnProductLineRenderer.scss';

interface BtnProductLineRendererProps {
    id: string,
    isHidden: boolean,
    value: string
}

export function BtnProductLineRenderer(props: BtnProductLineRendererProps) {
    const translate = useFormatMessage();
    const {id, isHidden, value} = props;

    return (
        <div className="btnProductLineRendererRoot">
            <span>{value}</span>
            {!isHidden && <Link to={`${paths.productLine}?id=${id}`}>
                <Button color="secondary" variant="outlined" size="small">{translate({id: 'a.edit'})}</Button>
            </Link>}
        </div>
    );
}


