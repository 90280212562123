import 'components/UploadDialog/UploadDialog.scss';

import {useEffect, useState} from 'react';
import {Divider, IconButton, LinearProgress, Paper} from '@mui/material';
import {Cancel, DeleteOutline, Replay} from '@mui/icons-material';
import {API_STATUSES} from 'config/api/constants';
import {countries} from 'shared/reducers/countryMarketConst';
import { useFormatMessage } from 'utils/translate';
import {leafletUploadDialogActions} from './SpotUploadDialog';
import {mediumTypeTranslationsSpots} from 'shared/models/leaflet.model';

type LeafletUploadCardProps = {
    fileName: string,
    fileSize: string,
    status: string,
    id: string,
    country: string,
    onClick: (id: string, action: leafletUploadDialogActions) => void,
    language?: string,
    mediumType?: string
};

function getStatusLabelId(status: string): string {
    switch (status){
        case API_STATUSES.NEW:
            return 'leafletUpload.statusNew';
        case API_STATUSES.PENDING:
            return 'leafletUpload.statusLoading';
        case API_STATUSES.IDLE:
            return 'leafletUpload.statusLoaded';
        case API_STATUSES.CANCELLED:
            return 'leafletUpload.statusCancelled';
        case API_STATUSES.ERROR:
            return 'a.error';
        case API_STATUSES.PRECONDITION_FAILED:
            return 'spotUpload.statusPreconditionFailed';
        case API_STATUSES.DUPLICATED:
            return 'b.duplicated';
        default:
            return 'a.error';
    }
}

const SpotUploadCard = ({fileName, fileSize, status, id, country, onClick, language, mediumType}: LeafletUploadCardProps) => {
    const translate = useFormatMessage();

    const [uploadedCountry, setUploadedCountry] = useState<string>(country);
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    useEffect(() => {
        if (isFirstLoad) {
            setUploadedCountry(country);
            setIsFirstLoad(false);
        }
    }, [isFirstLoad, country]);

    const getCountryName = () => countries.find((item) => item.id === uploadedCountry).name;

    const getActionButton = () => {
        switch (status) {
            case API_STATUSES.PENDING:
                return <IconButton color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.CANCEL)}>
                        <Cancel/>
                    </IconButton>;
            case API_STATUSES.CANCELLED:
            case API_STATUSES.ERROR:
                return [
                    <IconButton key="retryBtn" color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.RETRY)}>
                        <Replay/>
                    </IconButton>,
                    <IconButton key="removeBtn" color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.REMOVE)}>
                        <DeleteOutline/>
                    </IconButton>];
            case API_STATUSES.PRECONDITION_FAILED:
                return [
                    <IconButton key="retryBtn" color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.RETRY)}>
                        <Replay/>
                    </IconButton>,
                    <IconButton key="removeBtn" color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.REMOVE)}>
                        <DeleteOutline/>
                    </IconButton>];
            case API_STATUSES.DUPLICATED:
                return [
                    <IconButton key="removeBtn" color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.REMOVE)}>
                        <DeleteOutline/>
                    </IconButton>];
            default:
                return <IconButton color="secondary" size="small" onClick={() => handleClick(leafletUploadDialogActions.REMOVE)}>
                    <DeleteOutline/>
                </IconButton>;
        }
    };

    const handleClick = (action: leafletUploadDialogActions) => onClick(id, action);

    return (
      <Paper className="leafletUploadCardRoot" elevation={0}>
          <div className="detailsContainer">
              <div className="_directionCol labels">
                  <span>{translate({id: 'b.fileName'})}:</span>
                  <Divider/>
                  <span>{translate({id: 'leafletUpload.fileSize'})}:</span>
                  <Divider/>
                  <span>{translate({id: 'b.country'})}:</span>
                  { language ?
                      <>
                          <Divider/>
                          <span>{translate({id: 'dictionaryElement.lang'})}:</span>
                      </>
                      : null
                  }
                  { mediumType ?
                      <>
                          <Divider/>
                          <span>{translate({id: 'b.mediumType'})}:</span>
                      </>
                      : null
                  }
              </div>
              <div className="_directionCol details">
                  <div>
                      {fileName}
                  </div>
                  <Divider/>
                  <div>
                      {fileSize}
                  </div>
                  <Divider/>
                  <div>
                      {getCountryName()}
                  </div>
                  { language ?
                      <>
                          <Divider/>
                          <div>
                              {translate({id: `language.${language}`})}
                          </div>
                      </>
                      : null
                  }
                  { mediumType ?
                      <>
                          <Divider/>
                          <div>
                              {translate({id: mediumTypeTranslationsSpots[mediumType]})}
                          </div>
                      </>
                      : null
                  }
              </div>
              <div className="status">
                  {translate({id: getStatusLabelId(status)})}
              </div>
              <div className="actionButtons">
                  {getActionButton()}
              </div>
          </div>
          {status === API_STATUSES.PENDING && <LinearProgress/>}
      </Paper>
    );
};

export default  SpotUploadCard;