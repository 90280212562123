/* eslint-disable react-hooks/exhaustive-deps */
import './PromotionsTableEditDrawer.scss';

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import _ from 'lodash';
import {Button, Drawer} from '@mui/material';
import {promotionUI} from 'shared/models/promotion.model';
import {apiGetPromotionsForFrameResponsePromotionTypeProductType} from 'shared/models/frame.model';
import {commonAttributeDataModel} from 'shared/models/attributeComponent.model';
import {validity} from 'shared/models/validity.model';
import Promotion from 'components/Promotion';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';

interface PromotionsTableEditDrawerProps {
    countryId: string
    data: promotionUI,
    onClose: () => void,
    onConfirm: (data: bulkPromoUpdatePayload, overwriteAll: boolean) => void,
    open: boolean,
    eraseAndOverwrite: boolean
}

export type bulkPromoUpdatePayload = {
    promotionalPrice?: number,
    regularPrice?: number,
    relativeDiscount?: number,
    absoluteDiscount?: number,
    mpu?: number,
    promotionTypes?: commonAttributeDataModel,
    themeSeasons?: commonAttributeDataModel,
    countryThemedWeeks?: commonAttributeDataModel,
    qualitySeals?: commonAttributeDataModel,
    validityDates?: validity[],
    giveAwayBundledProducts?: apiGetPromotionsForFrameResponsePromotionTypeProductType[]
}

const PromotionsTableEditDrawer = ({countryId, data, eraseAndOverwrite, onClose, onConfirm, open}: PromotionsTableEditDrawerProps) => {

    const [templatePromo, setTemplatePromo] = useState<promotionUI>(null);

    useEffect(() => {
        if (open && data) {
            const disconnected: promotionUI = _.cloneDeep(data);
            disconnected.promotionalPrice = null;
            disconnected.regularPrice = null;
            disconnected.relativeDiscount = null;
            disconnected.absoluteDiscount = null;
            disconnected.mpu = null;
            disconnected.validityDates = [];
            disconnected.themeSeasons.literals = [];
            disconnected.qualitySeals.literals = [];
            disconnected.promotionTypes.literals = [];
            disconnected.countryThemedWeeks.literals = [];

            // according to the requirement from MPM-3270, we want to show values from leaflet/page level
            disconnected.derivedThemedWeeks[0] = [data.derivedThemedWeeks];
            disconnected.derivedThemeSeasons[0] = [data.derivedThemeSeasons];
            disconnected.derivedPromotionTypes[0] = [data.derivedPromotionTypes];

            disconnected.hasError = false;
            disconnected.giveAwayBundledProducts = [];
            disconnected.giveAwayBundledProductIds = [];
            setTemplatePromo(disconnected);
        }
    }, [open]);

    const handlePromotionUpdate = (key, value) => {
        const newPromoTemplate = {...templatePromo};
        newPromoTemplate[key] = value;
        setTemplatePromo(newPromoTemplate)
    };

    const handlePromotionError = (value) => setTemplatePromo({...templatePromo, hasError: value});

    const handleConfirmClick = () => onConfirm(makePayload(), eraseAndOverwrite);

    const makePayload = (): bulkPromoUpdatePayload => {
        if (templatePromo.hasError) {
            return null;
        }
        const {promotionalPrice, regularPrice, relativeDiscount, absoluteDiscount, mpu,
            promotionTypes, themeSeasons, countryThemedWeeks, qualitySeals, validityDates, giveAwayBundledProducts} = templatePromo;

        if (promotionalPrice || regularPrice || relativeDiscount || absoluteDiscount || mpu ||
            promotionTypes?.literals?.length || themeSeasons?.literals?.length || countryThemedWeeks?.literals?.length ||
            qualitySeals?.literals?.length || validityDates?.length || giveAwayBundledProducts.length) {

            const payload: bulkPromoUpdatePayload = {};

            if (promotionalPrice) payload.promotionalPrice = promotionalPrice;
            if (regularPrice) payload.regularPrice = regularPrice;
            if (relativeDiscount) payload.relativeDiscount = relativeDiscount;
            if (absoluteDiscount) payload.absoluteDiscount = absoluteDiscount;
            if (mpu) payload.mpu = mpu;

            if (promotionTypes?.literals?.length) payload.promotionTypes = promotionTypes;
            if (themeSeasons?.literals?.length) payload.themeSeasons = themeSeasons;
            if (countryThemedWeeks?.literals?.length) payload.countryThemedWeeks = countryThemedWeeks;
            if (qualitySeals?.literals?.length) payload.qualitySeals = qualitySeals;
            if (validityDates?.length) payload.validityDates = validityDates;
            if (giveAwayBundledProducts?.length) payload.giveAwayBundledProducts = giveAwayBundledProducts;

            return payload;
        }
        return null;
    };

    return (
        <Drawer className="promotionsTableEditDrawerRoot" open={open} onClose={onClose} anchor="right">
            <div className="viewContainer _directionRow">
                <PaperX className="_fullHeight _fullWidth _scrollY">
                    <div className="_header">
                        <FormattedMessage id={!eraseAndOverwrite ? "a.editSelected" : "frameDescription.eraseAndOverwriteAction"}/>
                    </div>
                    {templatePromo && <Promotion countryId={countryId}
                               data={templatePromo}
                               key={templatePromo.UIId}
                               mpuRequired={false}
                               onUpdatePromotion={handlePromotionUpdate}
                               onRemovePromotion={() => null}
                               onPromotionError={(error) => handlePromotionError(error)}
                               onCloneProduct={() => null}
                               onCloneBPCO={() => null}
                               onClonePromotion={() => null}
                    />}
                </PaperX>
            </div>
            <Footer
                hideNavigationMenu
                actionsRight={
                    <>
                        {templatePromo && <Button variant="contained" color="primary"
                                                onClick={handleConfirmClick}
                                                disabled={makePayload() === null}>
                            <FormattedMessage id="frameDescription.apply2selected"/>
                        </Button>}
                        <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.cancel"/></Button>
                    </>
                }
            />
        </Drawer>
    );
};

export default PromotionsTableEditDrawer;