import {FormattedMessage} from 'react-intl';

interface MyAdvertisementsInfoProps {
    advertisementsQty: number,
    manuallyCreatedAdvertisementsQty: number
}

const MyAdvertisementsInfo = ({advertisementsQty, manuallyCreatedAdvertisementsQty}: MyAdvertisementsInfoProps) => {
    return (
        <>
            <FormattedMessage id="advertisementsOverview.allAdvertisements"/>: <span className="_bold">{advertisementsQty}</span>
            <span>/</span>
            <FormattedMessage id="advertisementsOverview.manuallyCreatedAdvertisements"/>: <span className="_bold">{manuallyCreatedAdvertisementsQty}</span>
        </>
    );
};

export default MyAdvertisementsInfo;