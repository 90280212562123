
export enum mimeTypes {
    pdf = 'application/pdf',
    audio = 'audio/mpeg',
    video = 'video/mp4',
    jpg = 'image/jpg',
    jpeg = 'image/jpeg',
    csv = 'text/csv'
}
export const supportedLeafletFileTypes: string[] = [mimeTypes.pdf];

export const supportedPressAdvFileTypes: string[] = [mimeTypes.pdf, mimeTypes.jpg, mimeTypes.jpeg];

export const supportedBroadcastOrPublicationFileTypes: string[] = [mimeTypes.csv];

export const supportedSpotFileTypes: string[] = [mimeTypes.video, mimeTypes.audio];