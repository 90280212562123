/* eslint-disable react-hooks/exhaustive-deps */
import './HQList.scss';

import {ReactNode, useEffect , useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Button, Divider} from '@mui/material';
import {API_GET_RETAILER_HQ_LIST, API_STATUSES} from 'config/api/constants';
import {paths} from 'paths';
import {retailerHQListItem} from 'shared/models/retailerHQ.model';
import {branchPerRetailerResponseModel} from 'shared/models/dict.model';
import {specialRoles} from 'shared/models/permissions.model';
import {retailer} from 'shared/models/retailer.model';
import {fetchBranchesPerRetailer} from 'shared/reducers/branchsePerRetailer';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {getId} from 'utils/routing';
import Footer from 'components/Footer';
import {ButtonClose} from 'components/Buttons';
import {PaperX} from 'components/PaperX';
import {LoadingOverlay} from 'components/LoadingOverlay';
import AgTable, {localStorageColumnsKeys} from 'components/AgTable/AgTable';
import {RetailerSelector} from 'components/Selectors';
import {ActionListCard} from 'components/Cards/ActionListCard';

const RetailerHQList = () => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();
    const HQApiGet = useApi('get', [], {errMsg: 'retailerHQList.err'});

    const [retailerId, setRetailerId] = useState<string>('');
    const [selectedHQ, setSelectedHQ] = useState<retailerHQListItem>(null);
    const [branchesForHQ, setBranchesForHQ] = useState<branchPerRetailerResponseModel>([]);

    const branchesData = useSelector((state: IRootState) => state.branchesPerRetailer.data);
    const branchesStatus = useSelector((state: IRootState) => state.branchesPerRetailer.status);
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const userRoles = useSelector((state: IRootState) => state.userPermissions.specialRoles);

    const isAllowed: boolean = userRoles.includes(specialRoles.RETAILER_HIERARCHY_MANAGEMENT);

    const buildHQCardsList = (): ReactNode => {
        const response: retailerHQListItem[] = HQApiGet.data;
        return response.map((item) =>
            <ActionListCard key={item.id}
                            selectedId={selectedHQ ? selectedHQ.id : ''}
                            id={item.id}
                            item={item}
                            label={item.name}
                            onCardClick={setSelectedHQ}
                            path={paths.retailerHQDetails}
                            canEdit={isAllowed}/>
        );
    }

    useEffect(() => {
        const retailerId: string = getId();
        if (retailerId) setRetailerId(retailerId);
    }, []);

    useEffect(() => {
        if (retailerId) {
            getHQData(retailerId);
            setSelectedHQ(null);
            setBranchesForHQ([]);
            dispatch(fetchBranchesPerRetailer(retailerId,selectedLanguage));
        }
    }, [retailerId]);

    useEffect(() => {
        if (selectedHQ === null) {
            setBranchesForHQ([]);
        } else {
            setBranchesForHQ(branchesData.filter((item) => selectedHQ.branchIds.find((branchId) => branchId === item.id )));
        }
    }, [selectedHQ]);

    const getHQData = (retailerId: string) => HQApiGet.call(API_GET_RETAILER_HQ_LIST(retailerId));

    const handleRetailerChange = (retailer: retailer) => setRetailerId(retailer ? retailer.id : null);

    const branchesColumnDefs: ColDef[] = [
        { field: 'city', headerName: translate({ id: 'b.city' })},
        { field: 'postCode', headerName: translate({ id: 'b.postCode' }) },
        { field: 'street', headerName: translate({ id: 'b.street' }) },
        { field: 'houseNumber', headerName: translate({ id: 'b.houseNumber' }), flex: 0 },
        { field: 'regionName', headerName: translate({ id: 'b.region'}) },
        { field: 'nielsenRegionName', headerName: translate({ id: 'b.nielsenRegion'}) },
        { field: 'bannerName', headerName: translate({ id: 'b.banner'}) }
    ];

    const getHeaderLabel = (): ReactNode => <span>{translate({id: 'branchesOf'})}:&nbsp; {selectedHQ ? selectedHQ.name : '-'}</span>;

    const showLoading = (): boolean => branchesStatus === API_STATUSES.PENDING || HQApiGet.status === API_STATUSES.PENDING;

    return (
        <div className="viewRoot HQListRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer _directionCol">
                    <PaperX className="inputsContainer">
                        <div className="retailerWithCountry">
                            <div className="retailerContainer">
                                <RetailerSelector retailerId={retailerId} onRetailerChange={handleRetailerChange}/>
                            </div>
                        </div>
                    </PaperX>
                    <div className="_directionRow">
                        <PaperX className="ownersContainer">
                            <div className="_header"><FormattedMessage id="retailerHQ.names"/></div>
                            <Divider/>
                            <div className="ownersListContainer">
                                <ul>
                                    {buildHQCardsList()}
                                </ul>
                            </div>
                        </PaperX>
                        <PaperX className="_fullWidth _fullHeight">
                            <div className="_directionCol">
                                <div className="_header">{getHeaderLabel()}</div>
                                <AgTable
                                    rowData={branchesForHQ}
                                    defaultColDef={{
                                        sortable: true,
                                        resizable: true,
                                        floatingFilter: true,
                                        filter: 'agTextColumnFilter',
                                        floatingFilterComponentParams: {suppressFilterButton:true}
                                    }}
                                    columnDefs={branchesColumnDefs}
                                    localStorageColumnsKey={localStorageColumnsKeys.retailerHQList}
                                />
                            </div>
                        </PaperX>
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        {isAllowed && <>
                            <Link to={retailerId ? `${paths.retailerHQDetails}?id=${selectedHQ ? selectedHQ.id : '' }` : paths.retailerHQDetails}>
                                <Button variant="contained" color="primary" disabled={!selectedHQ}>{translate({id: 'a.edit'})}</Button>
                            </Link>
                            <Link to={retailerId ? `${paths.retailerHQDetails}?retailerId=${retailerId}` : paths.retailerHQDetails}>
                                <Button variant="contained" color="primary">{translate({id: 'retailerHQ.create'})}</Button>
                            </Link></>}
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default RetailerHQList;