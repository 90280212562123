import {ReactElement} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@mui/material';

interface infoDialogProps {
    message: string | ReactElement,
    onConfirm: () => void,
    open: boolean
}

const InfoDialog = (props: infoDialogProps) => {
    const {message, onConfirm, open} = props;

    return (
        <Dialog open={open}>
            <DialogTitle>
                <FormattedMessage id="b.general"/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                {message}
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onConfirm}>
                    <FormattedMessage id="a.confirm"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InfoDialog;