// @todo skipping type definitions MPM-2457 on this until  MPM-2312 is merged to dev as changes are big
import FormControl from "@mui/material/FormControl";
import {FormattedMessage} from "react-intl";
import {FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import {useFormatMessage} from "utils/translate";
import {useEffect, useState} from "react";

type leafletLanguageSelectorProps = {
    onLanguageChange: (language:string) => void,
    language?: string,
    required?: boolean,
    languageSet: string[],
    label: string,
    error?: boolean,
    disabled?: boolean
}

const LanguageSelector = ({onLanguageChange, language, required, languageSet, label, error, disabled}:leafletLanguageSelectorProps) => {
    const translate = useFormatMessage();

    const [selectedLanguage, setSelectedLanguage] = useState<string>('');

    useEffect(() => {
        if (language) {
            setSelectedLanguage(language)
        }
    },[language])


    const langList = (lang) => lang.map(item => ({
        label: translate({id: 'language.' + item}),
        value: item
    })).sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1);

    const handleLanguageChange = (e) => {
        setSelectedLanguage(e);
        onLanguageChange(e);
    }

    return (
        <div>
            <FormControl fullWidth required={disabled ? false : required} error={error} disabled={disabled}>
                <InputLabel color="primary"><FormattedMessage id={label} /></InputLabel>
                <Select
                    label={translate({id: label})}
                    value={selectedLanguage}
                    required={required}
                    onChange={(e) => handleLanguageChange(e.target.value)}
                    renderValue={(selectedLang) => translate({id: 'language.' + selectedLang})}
                    error={error}
                >
                    {
                        langList(languageSet).map((item) =>
                            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
                        )
                    }
                </Select>
                {error && <FormHelperText>{`${translate({id: 'b.descriptionLangErr'})}`}</FormHelperText>}
            </FormControl>
        </div>
    );
}

export default LanguageSelector;