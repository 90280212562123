/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from '@mui/material';
import {API_GET_SUPPLIERS_FOR_BRAND} from 'config/api/constants';
import {basic} from 'shared/models/_common.model';
import {basicSupplierWithUsageCount} from 'shared/models/supplier.model';
import {responseValidation} from 'utils/responseValidation';
import {getId, getURLParam} from 'utils/routing';
import {useFormatMessage} from 'utils/translate';
import {LoadingOverlay} from 'components/LoadingOverlay';

interface SupplierDialogProps {
    brand: basic,
    brickId: string,
    currentSupplierId?: string,
    isDescribingFrame: boolean,
    onClose: () => void,
    onRemove?: () => void,
    onChange: (supplier: basicSupplierWithUsageCount, suppliersQty: number) => void,
    open: boolean,
    brandAndBrickChangeCounter: number
}

const SupplierDialog = ({brand, brickId, currentSupplierId, isDescribingFrame, onClose, onRemove, onChange, open, brandAndBrickChangeCounter}: SupplierDialogProps) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();
    const cancelToken = useRef(null);

    const [suppliers, setSuppliers] = useState<basicSupplierWithUsageCount[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            if (cancelToken.current) cancelToken.current();
        }
    }, []);

    useEffect(() => {
        if (open && brand?.id) {
            setSuppliers([]);
            getSuppliersForBrand();
        }
    }, [open]);

    useEffect(() => {
        if (brand?.id) {
            setSuppliers([]);
            getSuppliersForBrand();
        }
    }, [brand]);

    useEffect(() => {
        const id: string = getId();
        if (brickId && (id || isDescribingFrame) && suppliers.length && brand) {
            getSuppliersForBrand();
            onChange(null, suppliers.length);
        }
    }, [brickId]);

    useEffect(() => {
        const cloneUrl = getURLParam('clone');
        if (!open) {
            if ((brandAndBrickChangeCounter || cloneUrl) && suppliers.length === 1) onChange(suppliers[0], 0);
            else if (brandAndBrickChangeCounter && suppliers.length > 1) onChange(null, suppliers.length);
        }
    }, [suppliers]);

    const getSuppliersForBrand = () => {
        setIsLoading(true);
        if (cancelToken.current) cancelToken.current();
        axios.get<basicSupplierWithUsageCount[]>(API_GET_SUPPLIERS_FOR_BRAND(brand?.id), {
            params: {...(brickId ? {brickId} : {})},
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setSuppliers(resp.data);
                } else {
                    handleError();
                }
            })
            .catch((e) => {
                if(!e.__CANCEL__) {
                    console.log(e);
                    handleError();
                }
            })
            .finally(() => setIsLoading(false));
    };

    const sortByCount = (a: basicSupplierWithUsageCount, b: basicSupplierWithUsageCount): number => b.productUsageCount - a.productUsageCount;

    const handleError = () => {
        enqueueSnackbar(`${translate({id: 'supplier.errors'})}`, {variant: 'error'});
        onClose();
    };

    return (
        <Dialog open={open} maxWidth={'sm'} fullWidth>
            <LoadingOverlay show={isLoading}/>
            <DialogTitle>
                <FormattedMessage id="supplier.select"/>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <span className="_bold"><FormattedMessage id="b.brand"/>:&nbsp;</span>{brand?.name}
                <br/>
                <span className="_bold"><FormattedMessage id="b.suppliers"/>:</span>
                {!suppliers.length ? (
                    <Typography variant="body1" align="center">
                        <FormattedMessage id="supplier.noSupAvailable"/>
                    </Typography>
                ) : (
                    <List>
                        {[...suppliers]
                            .sort(sortByCount)
                            .map((item) => (
                            <ListItem key={item.id} divider>
                                <ListItemText
                                    primary={item.name}
                                    secondary={
                                        <Typography variant="body2">
                                            {translate({id: 'supplier.usageCount'})}:{" "}
                                            <Typography component="span" variant="body2" fontWeight="bold">
                                                {brickId && item.productUsageCount !== null ? item.productUsageCount : '-'}
                                            </Typography>
                                        </Typography>
                                    }
                                />
                                <ListItemSecondaryAction>
                                    {currentSupplierId !== item.id ? (
                                        <Button onClick={() => onChange(item, suppliers.length)}>
                                            <FormattedMessage id="a.select"/>
                                        </Button>
                                    ) : (
                                        <Button onClick={() => onRemove()}>
                                            <FormattedMessage id="a.remove"/>
                                        </Button>
                                    )}
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
            <Divider/>
            <DialogActions>
                <Button variant="outlined" color="secondary" onClick={onClose}><FormattedMessage id="a.close"/></Button>
            </DialogActions>
        </Dialog>
    );
};

export default SupplierDialog;