/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import axios from 'axios';
import {CellValueChangedEvent} from 'ag-grid-community';
import {useSnackbar} from 'notistack';
import {Button, Checkbox, FormControlLabel, Tab, Tabs} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {getId} from 'utils/routing';
import {
    API_ATTRIBUTES,
    API_DICTIONARY_BY_ID,
    API_STATUSES,
    API_GET_ATTRIBUTE,
    API_GET_LITERAL
} from 'config/api/constants';
import {paths} from 'paths';
import {IRootState} from 'shared/reducers';
import {attributeDetails, fullAttributePayloadModel} from 'shared/models/attribute.model';
import {basic} from 'shared/models/_common.model';
import {
    dictionaryWithElements,
    dictionaryElement,
    translations,
    dictionaryTranslationRow, dictionary
} from 'shared/models/dictionaries.model';
import {literalObject} from 'shared/models/literal.model';
import {specialRoles} from 'shared/models/permissions.model';
import {PaperX} from 'components/PaperX';
import AgTable from 'components/AgTable/AgTable';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {dictElemColumnDefs, dictionary2arrayX} from 'modules/Dictionaries/Literals/Literal';
import AttributeLiteral from './AttributeLiterals';
import AttributeUnit from './AttributeUnit';
import {attrTypeLabels, attrTypes} from './Attribute';
import AttributeSummary from './AttributeSummary';

const AttributeCreate = ({history}) =>{
    const { enqueueSnackbar } = useSnackbar();
    const translate = useFormatMessage();
    const attributesApiGet = useApi('get', {id: null}, {errMsg: 'attributes.err'});
    const literalsApiGet = useApi('get', {id: null}, {errMsg: 'literals.err'});
    const dictionaryApiGet = useApi('get', {id: '', elements: [], translations: {}}, {errMsg: 'dictionary.err'});
    const attributeApiPost = useApi('post', {id: '', elements: [], translations: {}}, {succMsg: 'attributes.succ', errMsg: 'attributes.saveErr'});
    const attributeApiPut = useApi('put', {id: '', elements: [], translations: {}}, {succMsg: 'attributes.succ', errMsg: 'attributes.saveErr'});
    const userRoles = useSelector((state: IRootState) => state.userPermissions.specialRoles);

    const [rebuildingAttribute, setRebuildingAttribute] = useState<boolean>(false);
    const [step, setStep] = useState<number>(0);
    const [attributeId, setAttributeId] = useState<string>('');
    const [attributeNames, setAttributeNames] = useState<dictionaryTranslationRow[]>([]);
    const [attributeType, setAttributeType] = useState<attrTypes>(null);
    const [attributeLiterals, setAttributeLiterals] = useState<literalObject[]>([]);
    const [attributeUnits, setAttributeUnits] = useState<dictionaryElement[]>([]);
    const [attributeDictionaries, setAttributeDictionaries] = useState<(dictionaryWithElements | dictionary)[]>([]);
    const [attributeActive, setAttributeActive] = useState<boolean>(false);
    const [lastDict, setLastDict] = useState<dictionary>(null);
    const [lock4edit, setLock4edit] = useState<boolean>(false);

    const isAllowed: boolean = userRoles.includes(specialRoles.PRODUCT_CATEGORY_MANAGEMENT);

    // edit flow start
    useEffect(() => {
        setAttributeNames(dictionary2arrayX({en: ''}));
        const existingAttributeId: string = getId();
        if (existingAttributeId) {
            setRebuildingAttribute(true);
            setAttributeId(existingAttributeId);
            loadExistingAttribute(existingAttributeId);
        }
    }, []);

    const loadExistingAttribute = (attrId: string) => attributesApiGet.call(API_GET_ATTRIBUTE(attrId));

    const loadDictionaries = (dictionariesId: string[], type: attrTypes) => {
        Promise.all(dictionariesId.map((item)=>
            axios.get(API_DICTIONARY_BY_ID(item))
        ))
            .then((resp) => {
                const newDicts: dictionaryWithElements[] = [];
                resp.forEach((item) => newDicts.push(item.data));
                setAttributeDictionaries(newDicts);
                const units: dictionaryElement[][] = [];
                resp.forEach((item) => units.push(item.data.elements));
                setAttributeUnits(units.reduce((previousValue, currentValue)  => previousValue.concat(currentValue)));

                setTypeFromLoadedAttr(type);
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'dictionary.err'})}`, {variant: 'error', persist: false});
                abortEdit();
            })
    };

    const loadLiterals = (literals: string[], type: attrTypes) => {
        Promise.all(literals.map((item)=>
            axios.get(API_GET_LITERAL(item))
        ))
            .then((resp) => {
                const literalsResponse: literalObject[] = resp.map((item) => item.data);
                setAttributeLiterals(literalsResponse);
                setTypeFromLoadedAttr(type);
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'literals.err'})}`, {variant: 'error', persist: false});
                abortEdit();
            })
    };

    const setTypeFromLoadedAttr = (type: attrTypes) => {
        setAttributeType(type);
        setRebuildingAttribute(false);
    };

    const abortEdit = () => history.push(paths.attribute);

    useEffect(() => {
        if (attributesApiGet.status === API_STATUSES.IDLE) {
            const attributeResponse: attributeDetails = attributesApiGet.data;
            // @TODO currently DB is populated with 'null' active flag, we treat it as true, unless user explicitly change it to false
            if (attributeResponse.active === null) attributeResponse.active = true;
            setAttributeActive(attributeResponse.active);
            setAttributeNames(dictionary2arrayX(attributeResponse.translations));
            if (attributeResponse.type === attrTypes.RANGE || attributeResponse.type === attrTypes.NUMERICAL) {
                loadDictionaries(attributeResponse.unitDictionaryTypes, attributeResponse.type);
            } else if (attributeResponse.type === attrTypes.ENUMERATION) {
                loadLiterals(attributeResponse.literals, attributeResponse.type);
            } else {
                setTypeFromLoadedAttr(attributeResponse.type);
            }
        } else if (attributesApiGet.status === API_STATUSES.ERROR) {
            abortEdit();
        }
    }, [attributesApiGet.status]);

    // edit flow end

    const getDictIds = (): string[] => attributeDictionaries.map((item) => item.id);

    useEffect(() => {
        if (attributeType && isAllowed) {
            attributeType === attrTypes.FREETEXT || attributeType === attrTypes.BRICK_DEPENDENT_ENUMERATION ? setStep(3) : setStep(2);
        }
    }, [attributeType, isAllowed]);

    useEffect(() => {
        if (literalsApiGet.status === API_STATUSES.IDLE) {
            const newLiterals = [...attributeLiterals];
            newLiterals.push(literalsApiGet.data);
            setAttributeLiterals(newLiterals);
        }
    }, [literalsApiGet.status]);

    useEffect(() => {
        if (lastDict) {
            dictionaryApiGet.call(API_DICTIONARY_BY_ID(lastDict.id));
            const newDicts = [...attributeDictionaries];
            newDicts.push(lastDict);
            setAttributeDictionaries(newDicts);
        }
    }, [lastDict]);

    useEffect(() => {
        if (dictionaryApiGet.status === API_STATUSES.IDLE) {
            const dictionaryApiGetResponse: dictionaryWithElements = dictionaryApiGet.data;
            const allDictionaryItems: dictionaryElement[] = [...attributeUnits].concat(dictionaryApiGetResponse.elements);
            setAttributeUnits(allDictionaryItems);
        }
    }, [dictionaryApiGet.status]);

    useEffect(() => {
        if (attributeApiPost.status === API_STATUSES.IDLE) {
            history.push(`${paths.attribute}?id=${attributeApiPost.data.id}`);
        } else if(attributeApiPut.status === API_STATUSES.IDLE) {
            history.push(`${paths.attribute}?id=${attributeApiPut.data.id}`);
        }
    }, [attributeApiPost.status, attributeApiPut.status]);

    const handleActiveClick = () => setAttributeActive(!attributeActive);

    const handleCellChange = (event: CellValueChangedEvent) => {
        const newData: dictionaryTranslationRow[] = [...attributeNames];
        newData[event.rowIndex] = event.data;
        setAttributeNames(newData);
    };

    const handleTypeChange = (attrType: attrTypes) => setAttributeType(attrType);

    const needName = () => attributeNames.find((item) => item.lang === 'en' && item.trans === '') !== undefined;
    const needType = () => attributeType === null;
    const needContent = () => {
        if (attributeType === attrTypes.NUMERICAL || attributeType === attrTypes.RANGE) {
            return attributeDictionaries.length === 0;
        } else if (attributeType === attrTypes.ENUMERATION) {
            return attributeLiterals.length === 0;
        }
        return false;
    };
    const hasNoContent = () => attributeType === attrTypes.FREETEXT || attributeType === attrTypes.BRICK_DEPENDENT_ENUMERATION;

    const handleTabChange = (e, idx: number) => {
        setStep(idx);
        setLock4edit(false);
    }

    const handleLiteralSelect = (literal: basic) => {
        if (literal) {
            literalsApiGet.call(API_GET_LITERAL(literal.id));
        }
    };

    // disabled until roles are introduced with the rights to remove literals from attributes
    const handleLiteralClick = (literalId: string) => {
        if (!attributeId) {
            setAttributeLiterals(attributeLiterals.filter((item) => item.id !== literalId));
            enqueueSnackbar(translate({id: 'attributes.literalRemoved'}), {variant: 'success', persist: false});
        }
    };

    const addOrRemoveDictionary = (dict: dictionaryWithElements | dictionary) => {
        const dictAlreadySelected = attributeDictionaries.find((item) => item.id === dict.id);
        if (dictAlreadySelected) {
            setAttributeDictionaries(attributeDictionaries.filter((item) => item.id !== dict.id));
            setAttributeUnits(attributeUnits.filter((item) => item.dictionaryId !== dict.id));
        } else {
            setLastDict(dict);
        }
    };

    const handleDictSelect = (dict: dictionaryWithElements | dictionary) => addOrRemoveDictionary(dict);

    const buildAttributeObject = (): fullAttributePayloadModel => {
        const translations: translations = {en: ''};
        attributeNames.forEach((item) => {
            translations[item.lang] = item.trans;
        });

        const attrObj: fullAttributePayloadModel = {
            translations,
            active: attributeActive,
            type: attributeType
        };

        if (attributeType === attrTypes.ENUMERATION)
            attrObj.literals = attributeLiterals.map((item) => item.id);
        if (attributeType === attrTypes.RANGE || attributeType === attrTypes.NUMERICAL)
            attrObj.unitDictionaryTypes = getDictIds();

        return attrObj;
    };

    const handleSaveClick = () => {
        const payload: fullAttributePayloadModel = buildAttributeObject();
        if (attributeId) {
            attributeApiPut.call(`${API_ATTRIBUTES}/${attributeId}`, payload);
        } else {
            attributeApiPost.call(API_ATTRIBUTES, payload);
        }
    };

    const cantSave = (): boolean => lock4edit || needName() || needType() || needContent();

    const showLoading = (): boolean => rebuildingAttribute || literalsApiGet.status === API_STATUSES.PENDING
        || dictionaryApiGet.status === API_STATUSES.PENDING || attributeApiPost.status === API_STATUSES.PENDING
        || attributeApiPut.status === API_STATUSES.PENDING;

    const buildTypeControls = (): React.ReactNode[] => {
        const typeControls: React.ReactNode[] = [];
        for (const type in attrTypes) {
            typeControls.push(
                <FormControlLabel key={type}
                                  label={translate({id: attrTypeLabels[type]})}
                                  control={
                                      <Checkbox
                                          checked={attributeType === type}
                                          onChange={() => handleTypeChange(attrTypes[type])}
                                      />
                                  }
                />);
        }
        return typeControls;
    };

    return (
        <div className="viewRoot franchiseOwnerRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer">
                    <div className="_directionCol">
                        <PaperX>
                            {isAllowed && <Tabs value={step} onChange={handleTabChange} color="secondary">
                                <Tab label={translate({id: "attributes.step1"})}/>
                                <Tab label={translate({id: "attributes.step2"})} disabled={attributeId !== '' || needName()}/>
                                <Tab label={translate({id: "attributes.step3"})} disabled={needName() || needType() || hasNoContent()}/>
                                <Tab label={translate({id: "a.summary"})} disabled={needName() || needType() || needContent()}/>
                            </Tabs>}
                            {!isAllowed && <Tabs value={step} onChange={handleTabChange} color="secondary">
                                <Tab label={translate({id: "attributes.step1"})}/>
                            </Tabs>}
                        </PaperX>
                        {step === 0 &&
                            <PaperX className="_fullHeight _fullTable">
                                <AgTable
                                    rowData={attributeNames}
                                    defaultColDef={{suppressMenu: true, suppressMovable: true}}
                                    columnDefs={dictElemColumnDefs(translate, isAllowed)}
                                    onCellValueChanged={handleCellChange}
                                    onCellEditingStarted={() => setLock4edit(true)}
                                    onCellEditingStopped={() => setLock4edit(false)}
                                    tooltipShowDelay={1000}
                                />
                            </PaperX>
                        }
                        {step === 1 &&
                            <PaperX className="_fullHeight">
                                {buildTypeControls()}
                            </PaperX>
                        }
                        {step === 2 && attributeType === attrTypes.ENUMERATION && <AttributeLiteral onLiteralSelect={handleLiteralSelect} onRowClick={handleLiteralClick} data={attributeLiterals}/>}
                        {step === 2 && (attributeType === attrTypes.NUMERICAL || attributeType === attrTypes.RANGE) && <AttributeUnit onDictSelect={handleDictSelect} data={attributeUnits} selectedDicts={attributeDictionaries}/>}
                        {step === 3 && <AttributeSummary attr={buildAttributeObject()} literals={attributeLiterals} dictionaries={attributeDictionaries} onActiveClick={handleActiveClick}/>}
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        {isAllowed && <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={cantSave()}><FormattedMessage id="a.save"/></Button>}
                        <Link to={paths.attribute}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default AttributeCreate;
