import {useState} from 'react';
import {Link} from 'react-router-dom';
import {paths} from 'paths';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import MyAdvertisements from './MyAdvertisements';
import MyAdvertisementsInfo from './MyAdvertisementsInfo';
import {advertisementsOverviewObject} from 'shared/models/advertisement.model';

const MyAdvertisementsView = () => {
    const [advertisementsQty, setAdvertisementsQty] = useState<number>(0);
    const [manuallyCreatedAdvertisementsQty, setManuallyCreatedAdvertisementsQty] = useState<number>(0);

    const handleDataChange = (data: advertisementsOverviewObject[]) => {
        const uniqueDataByAdvertisementId: advertisementsOverviewObject[] = data.filter((item, index, self) => index === self.findIndex((obj) => obj.advertisementId === item.advertisementId));
        setAdvertisementsQty(data.length);
        setManuallyCreatedAdvertisementsQty(uniqueDataByAdvertisementId.filter(item => item.manuallyCreated === true).length);
    };

    return (
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <MyAdvertisements onDataChange={handleDataChange}/>
                </div>
            </div>
            <Footer
                actionsLeft={
                    <MyAdvertisementsInfo advertisementsQty={advertisementsQty}
                                          manuallyCreatedAdvertisementsQty={manuallyCreatedAdvertisementsQty}/>
                }
                actionsRight={
                    <Link to={paths.home}>
                        <ButtonClose/>
                    </Link>
                }
            />
        </div>
        
    );
};

export default MyAdvertisementsView;