import {useEffect, useState} from 'react';

export function useForceEraseAndOverwrite() {
    const [forceEraseAndOverwriteValues, setForceEraseAndOverwriteValues] = useState<boolean>(false);

    useEffect(() => {
        if (forceEraseAndOverwriteValues) setForceEraseAndOverwriteValues(false);
    }, [forceEraseAndOverwriteValues]);

    const triggerForceAndOverwrite = () => setForceEraseAndOverwriteValues(true);

    return {forceEraseAndOverwriteValues, triggerForceAndOverwrite};
}