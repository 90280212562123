import './CategoryAttributeCard.scss';

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Checkbox, FormControlLabel, Tooltip} from '@mui/material';
import {attributeResponseModel} from 'shared/models/attribute.model';
import {useFormatMessage} from 'utils/translate';
import {attrTypeLabels, attrTypes} from 'modules/Dictionaries/Attributes/Attribute';

interface categoryAttributeCardProps {
    activeAssignment?: boolean,
    data: attributeResponseModel,
    isChangeDisabled: boolean,
    onEdit?: (attributeId: string) => void,
    onRemove?: (attributeId: string) => void,
    onCheckboxChange?
}

const CategoryAttributeCard = (props: categoryAttributeCardProps) => {
    const {activeAssignment, data, isChangeDisabled, onCheckboxChange, onEdit, onRemove} = props;
    const translate = useFormatMessage();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [isActiveAssignment, setIsActiveAssignment] = useState<boolean>(false);

    useEffect(() => activeAssignment && setIsActiveAssignment(activeAssignment), [activeAssignment]);

    const getContentTypeLabel = () => {
        if ((data.type === attrTypes.ENUMERATION && data.literals.length) || (data.type === attrTypes.BRICK_DEPENDENT_ENUMERATION && data?.activeAssignedLiterals.length)) {
            return <FormattedMessage id="literals.label"/>;
        }
        if ((data.type === attrTypes.NUMERICAL || data.type === attrTypes.RANGE) && data?.dictionaries.length) {
            return <FormattedMessage id="attributes.selectedUnits"/>;
        }
        return null;
    };

    const listContents = () => {
        if (data.type !== attrTypes.BRICK_DEPENDENT_ENUMERATION && data?.literals.length) {
            return data.literals.map((item) => item.name).join(', ');
        }
        if (data.type === attrTypes.BRICK_DEPENDENT_ENUMERATION) {
            let result: string[] = [];
            if (data?.activeAssignedLiterals.length) {
                result.push(data.activeAssignedLiterals.map((item) => item.name).join(', '));
            }
            if (data?.inactiveAssignedLiterals.length) {
                result.push(data.inactiveAssignedLiterals.map((item) => item.name).join(', '));
            }
            return result.join(', ');
        }
        if (data?.dictionaries.length) {
           return data.dictionaries.map((item) => item.name).join(', ');
        }
        return null;
    };

    const requireLiteralsWarning = () => {
        if (data.type === attrTypes.BRICK_DEPENDENT_ENUMERATION && data?.activeAssignedLiterals.length === 0 && data?.inactiveAssignedLiterals.length === 0) {
            return <div className="summaryRow">
                <div className="literalsMissingWarning"><FormattedMessage id="category.requireLiterals"/></div>
            </div>;
        }
        return null;
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsActiveAssignment(e.target.checked);
        onCheckboxChange(e.target.checked, data.id);
    };

    return (
        <div className={showDetails ? 'summaryCardRoot categoryAttributeCardRoot expanded' : 'summaryCardRoot categoryAttributeCardRoot'}>
            <div className="summaryCardContainer">
                <div className="labels">
                    <div className="summaryRow"><FormattedMessage id="attributes.name"/></div>
                    {showDetails &&
                        <>
                            <div className="summaryRow checkbox"><FormattedMessage id="a.active"/></div>
                            <div className="summaryRow"><FormattedMessage id="attributes.type"/></div>
                            <div className="summaryRow">{getContentTypeLabel()}</div>
                        </>
                    }
                </div>
                <Tooltip title={showDetails ? '' : translate({id: 'a.details'})}>
                    <div className="values" onClick={() => setShowDetails(true)}>
                        <div className="summaryRow">
                            {data.name}
                        </div>
                        {showDetails &&
                            <>
                                <div className="summaryRow checkbox">
                                    <Checkbox checked={data.active} disabled/>
                                </div>
                                <div className="summaryRow">
                                    <FormattedMessage id={attrTypeLabels[data.type]}/>
                                </div>
                                <div className="summaryRow">{listContents()}</div>
                            </>
                        }
                        {requireLiteralsWarning()}
                    </div>
                </Tooltip>
                <div className="actions _centerY">
                    <div className="smallAttrActionsContainer">
                        {onEdit && <FormControlLabel control={
                                                    <Checkbox checked={isActiveAssignment}
                                                              onChange={handleChange}
                                                              color="primary"
                                                              size="small"
                                                              disabled={isChangeDisabled}
                                                    />}
                                          label={translate({id: 'attributes.activeAssignment'})}
                        />}
                        {data.type === attrTypes.BRICK_DEPENDENT_ENUMERATION && onEdit && !isChangeDisabled && <Button onClick={() => onEdit(data.id)} color="primary" variant="outlined"><FormattedMessage id="a.edit"/></Button>}
                        {onRemove && <Button onClick={() => onRemove(data.id)} color="primary" variant="contained" disabled><FormattedMessage id="a.remove"/></Button>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryAttributeCard;