/* eslint-disable react-hooks/exhaustive-deps */
import './ProductBulkChangeFilters.scss';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import {IRootState} from 'shared/reducers';
import {categorySelectorOption, categoryTableItem, categoryType} from 'shared/models/category.model';
import useDebounce from 'utils/debounce';
import {getFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {useFormatMessage} from 'utils/translate';
import {MarketSelector} from 'components/Selectors';
import {CategorySearch} from 'modules/MasterData/ProductCategories/CategorySearch';
import {CategoryTurboSearch} from 'modules/MasterData/Category';
import {productsListSearchQueryStrLocalStorageKey} from 'modules/MasterData/Product/ProductsListSearchResults';

interface ProductBulkChangeFiltersProps {
    brickRequired?: boolean,
    onFilterChange: (filters: productBulkChangeFilters) => void,
    onReloadClick: () => void,
    restoredQueryStrKey?: string
}

export type productBulkChangeFilters = {
    categoryBrickId?: string,
    lang?: string,
    marketId?: string,
    search?: string,
    templatesExcluded?: boolean
}

export const resultsLimit: number = 5000;

const sanitizeProductBulkChangeFilters = (filters: productBulkChangeFilters): productBulkChangeFilters => {
    const saneFilters: productBulkChangeFilters = {};
    if (filters.search && filters.search.length > 2) saneFilters.search = filters.search;
    if (filters.lang) saneFilters.lang = filters.lang;
    if (filters.categoryBrickId) saneFilters.categoryBrickId = filters.categoryBrickId;
    if (filters.marketId) saneFilters.marketId = filters.marketId;
    if (filters.templatesExcluded) saneFilters.templatesExcluded = filters.templatesExcluded;
    return Object.keys(saneFilters).length === 0 ? null : saneFilters;
};

const buildFiltersTemplate= (marketId: string, lang: string, search: string): productBulkChangeFilters => {
    return {
        categoryBrickId: null,
        lang,
        marketId,
        search,
        templatesExcluded: true
    }
};

const ProductBulkChangeFilters = (props: ProductBulkChangeFiltersProps) => {
    const {brickRequired, onFilterChange, onReloadClick, restoredQueryStrKey} = props;
    const translate = useFormatMessage();

    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const [filters, setFilters] = useState<productBulkChangeFilters>(null);
    const [dbncdFilters, setDbncdFilters] = useDebounce(filters, 500);
    const [stringifiedFilters, setStringifiedFilters] = useState<string>(''); // sometimes on filter change sanitize function return identical object (ex. when search string is too short), we dont want to trigger onFilterChange then
    const [brick, setBrick] = useState<categorySelectorOption>(null);

    const handleSearchInputChange = (val: string) => {
        setFilters({...filters, search: val});
        storeInLocalStorage(restoredQueryStrKey, val);
    };

    const handleBrickSelect = (brick: categoryTableItem) => {
        setBrick({
            id: brick.bId,
                name: brick.bName,
                type: categoryType.BRICK
        });
        setFilters({...filters, categoryBrickId: brick ? brick.bId : null});
    };

    useEffect(() => {
        let restoredQueryStr: string = '';
        if (restoredQueryStrKey) {
            restoredQueryStr = getFromLocalStorage(productsListSearchQueryStrLocalStorageKey) || '';
        }
        if (filters === null) {
            setFilters(buildFiltersTemplate(userProfile.countryMarket.market, userProfile.langData, restoredQueryStr));
        } else {
            setFilters({...filters, marketId: userProfile.countryMarket.market, search: restoredQueryStr});
        }
    }, [userProfile]);

    // watch filters
    useEffect(() => setDbncdFilters(filters), [filters]);
    useEffect(() => {
        if (dbncdFilters) {
            const saneFilters = sanitizeProductBulkChangeFilters(dbncdFilters);
            const stringifiedSaneFilters = JSON.stringify(saneFilters);
            if (stringifiedSaneFilters !== stringifiedFilters) {
                setStringifiedFilters(stringifiedSaneFilters);
                onFilterChange(saneFilters);
            }
        }
    }, [dbncdFilters]);

    return !filters ? null :
        <div className={brickRequired ? 'productBulkChangeFiltersRoot' : 'productBulkChangeFiltersRoot noBrick'}>
            {brickRequired && <CategoryTurboSearch onCategorySelect={(brick) => handleBrickSelect(brick)}
                                                   remoteSearch
                                                   customLauncher={
                                                       <CategorySearch value={brick}
                                                                       onCategorySelect={() => null}
                                                                       categoryType={categoryType.BRICK}
                                                                       required
                                                       ></CategorySearch>
                                                   }/>
            }
            <div className="searchWithReload">
                <TextField onChange={(e) => handleSearchInputChange(e.target.value)}
                           value={filters.search || ''}
                           fullWidth
                           label={translate({id: 'b.product'})}
                           required
                           InputProps={{
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <SearchIcon/>
                                   </InputAdornment>
                               )
                           }}
                />
                <IconButton color="secondary"
                            disabled={(brickRequired === true && !filters.categoryBrickId) || !filters.search}
                            onClick={onReloadClick}>
                    <RefreshIcon fontSize='small'/>
                </IconButton>
            </div>
            <MarketSelector/>
        </div>
};

export default ProductBulkChangeFilters;