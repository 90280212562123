import axios from 'axios';
import {API_STATUSES, API_MEDIA_GET_NEXT_SPOT_FOR_VERIFICATION} from 'config/api/constants';
import {spotVerificationNextResponseModel} from 'shared/models/media.model';

enum ACTION_TYPES {
    START_FETCHING_NEXT_SPOT_TO_VERIFY = 'START_FETCHING_NEXT_SPOT_TO_VERIFY',
    ON_SUCCESS_NEXT_SPOT_TO_VERIFY = 'ON_SUCCESS_NEXT_SPOT_TO_VERIFY',
    ON_ERROR_NEXT_SPOT_TO_VERIFY = 'ON_ERROR_NEXT_SPOT_TO_VERIFY'
}

export type nextSpotToVerify = {
    status: API_STATUSES,
    data: spotVerificationNextResponseModel
}

export function fetchNextSpotToVerify (country: string) {
    return function (dispatch) {
        dispatch({
            type: ACTION_TYPES.START_FETCHING_NEXT_SPOT_TO_VERIFY
        });
        axios.get(API_MEDIA_GET_NEXT_SPOT_FOR_VERIFICATION(country)).then((resp) => {
            if (resp.status === 200) {
                dispatch({
                    type: ACTION_TYPES.ON_SUCCESS_NEXT_SPOT_TO_VERIFY,
                    payload: { data: resp.data }
                });
            } else {
                dispatch({type: ACTION_TYPES.ON_ERROR_NEXT_SPOT_TO_VERIFY});
            }
        }).catch(() => {
            dispatch({type: ACTION_TYPES.ON_ERROR_NEXT_SPOT_TO_VERIFY})
        })
    }
}

const initialState: nextSpotToVerify = {
    status: API_STATUSES.NEW,
    data: null
};

export type NextSpotToVerifyState = Readonly<typeof initialState>;
 
const nextSpotToVerifyReducer = (state: NextSpotToVerifyState = initialState, action): NextSpotToVerifyState => {
    switch (action.type) {
        case ACTION_TYPES.START_FETCHING_NEXT_SPOT_TO_VERIFY:
            return {...state, status: API_STATUSES.PENDING};
        case ACTION_TYPES.ON_SUCCESS_NEXT_SPOT_TO_VERIFY:
            return {...state, status: API_STATUSES.IDLE, data: action.payload.data};
        case ACTION_TYPES.ON_ERROR_NEXT_SPOT_TO_VERIFY:
            return {...state, status: API_STATUSES.ERROR};
        default:
            return state;
    }
};

export default nextSpotToVerifyReducer;