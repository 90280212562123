import {API_STATUSES} from 'config/api/constants';
    
export type currentUserPermissionsResponse = {
    username: string,
    specialRoles: string[]
}

export type currentUserPermissionsReducerModel = currentUserPermissionsResponse & {
    status: API_STATUSES
}

export enum specialRoles {
    'PERSONAL_STATISTICS' = 'PERSONAL_STATISTICS',
    'PRODUCT_CATEGORY_MANAGEMENT' = 'PRODUCT_CATEGORY_MANAGEMENT',
    'PRODUCT_MANAGEMENT' = 'PRODUCT_MANAGEMENT',
    'RETAILER_HIERARCHY_MANAGEMENT' = 'RETAILER_HIERARCHY_MANAGEMENT'
}