import { ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, MenuItemProps } from '@mui/material';
import {OpenInNew} from '@mui/icons-material';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {AnyAction} from 'redux';

import { releaseIfLocked } from 'utils/lockUtils';
import { useFormatMessage } from 'utils/translate';
import { dialogIdentifier, getNavigationSections, menuItemIdentifier, NavigationItem, NavigationSection } from './NavigationMenuItems';
import { leafletUploadDialogToggle } from 'shared/reducers/leafletUpload';
import { IRootState } from 'shared/reducers';
import { spotUploadDialogToggle } from 'shared/reducers/spotUpload';
import { isSomeEnum } from 'utils/isSomeEnum';
import {specialRoles} from 'shared/models/permissions.model';
import { useLocation } from 'react-router';
import { paths } from 'paths';
import {useEffect, useState} from 'react';
import { fetchNextSpotToVerify } from 'shared/reducers/nextSpotToVerify';

type NavigationMenuProps = {
    anchorEl: HTMLElement | null,
    onClose: () => void,
};

type NavigationMenuItemProps = MenuItemProps & {
    item: NavigationItem,
};

type ContextMenuItemType = {
    action: AnyAction,
    mouseX: number,
    mouseY: number,
    params: string,
    path: string
}

const NavigationMenuItem = ({ item, children, ...restOfProps }: NavigationMenuItemProps) => {
    const dispatch = useDispatch();
    const translate = useFormatMessage();
    const history = useHistory();
    const location = useLocation();

    const [contextMenu, setContextMenu] = useState<ContextMenuItemType | null>(null);

    const isNotDialogIdentifier: <T>(e: T) => boolean = isSomeEnum(dialogIdentifier);
    const isNotUploadDialog: boolean = !isNotDialogIdentifier(item.id);
    const matchesCurrentRoute: boolean = !!useRouteMatch({
        path: item.path,
        exact: true,
    }) && isNotUploadDialog;
    const leafletUploadOpen: boolean = useSelector((state: IRootState) => state.leafletUpload.dialogOpen);
    const disableLeafletUpload: boolean = leafletUploadOpen && item.id === dialogIdentifier.leafletUpload
    const spotUploadOpen: boolean = useSelector((state: IRootState) => state.spotUpload.dialogOpen);
    const disableSpotUpload: boolean = spotUploadOpen && item.id === dialogIdentifier.spotUpload
    const nextSpotIdToVerify: string = useSelector((state: IRootState) => state.nextSpotToVerify.data?.spotId);
    const disableVerification: boolean = item.id === menuItemIdentifier.mediumVerification && (!nextSpotIdToVerify || (location.pathname === paths.spotHeaderData && location.search.includes(`id=${nextSpotIdToVerify}`)));

    const handleItemClick = (e: React.MouseEvent<HTMLLIElement>) => {
        releaseIfLocked();
        let params: string = item.params;
        const isCtrlCommandKeyPressed: boolean = e.ctrlKey || e.metaKey;
        !isCtrlCommandKeyPressed && dispatch(leafletUploadDialogToggle(false));
        !isCtrlCommandKeyPressed && dispatch(spotUploadDialogToggle(false));
        if (item.id === menuItemIdentifier.mediumVerification && nextSpotIdToVerify) {
            params = `?id=${nextSpotIdToVerify}`
        }
        if (isCtrlCommandKeyPressed) {
            if (!item.action) window.open(`${item.path}${params ?? ''}`, '_blank');
        } else history.push(`${item.path}${params ?? ''}`);
        if (item.action && !isCtrlCommandKeyPressed) {
            dispatch(item.action);
        }
    };

    const handleContextMenu = (e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault();
        const {action, path, params} = item;
        setContextMenu(
          contextMenu === null
            ? {
                mouseX: e.clientX - 2,
                mouseY: e.clientY - 4,
                path,
                params,
                action
              }
            : null,
        );
    }

    const handleCloseContextMenu = () => setContextMenu(null);
    
    const handleOpenInNewTab = () => {
        let params: string = contextMenu.params;
        const path: string = contextMenu.path;
        if (item.id === menuItemIdentifier.mediumVerification && nextSpotIdToVerify) {
            params = `?id=${nextSpotIdToVerify}`
        }
        if (path) window.open(`${path}${params ?? ''}`, '_blank');
        handleCloseContextMenu();
      };

    return (
        <>
            <MenuItem onClick={handleItemClick}
                      onContextMenu={handleContextMenu}
                      {...restOfProps} 
                      disabled={matchesCurrentRoute || disableLeafletUpload || disableSpotUpload || disableVerification}
            >
                {children}
            </MenuItem>

            <Menu open={contextMenu !== null}
                  onClose={(handleCloseContextMenu)}
                  anchorReference="anchorPosition"
                  anchorPosition={
                      contextMenu !== null
                          ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                          : undefined
                  }
            >
                <MenuItem onClick={handleOpenInNewTab}
                          disabled={!!contextMenu?.action}>
                    <ListItemIcon>
                        <OpenInNew fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={translate({id: 'nav.contextMenuOpenNewTab'})}/>
                </MenuItem>
            </Menu>

        </>
    );
};

const NavigationMenuSection = ({ headingLabelId, items }: NavigationSection) => {
    const translate = useFormatMessage();

    return (
        <>
            <ListSubheader>{translate({ id: headingLabelId })}</ListSubheader>
            {items.map((item) => (
                <NavigationMenuItem key={item.path} item={item}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText>{translate({ id: item.labelId })}</ListItemText>
                </NavigationMenuItem>
            ))}
        </>
    );
};

export const NavigationMenu = ({ anchorEl, onClose }: NavigationMenuProps) => {

    const dispatch = useDispatch();
    const country = useSelector((state: IRootState) => state.userProfile.countryMarket.preferredCountry);
    const userRoles = useSelector((state: IRootState) => state.userPermissions.specialRoles);

    const hasPersonalStatisticsRoles: boolean = userRoles.includes(specialRoles.PERSONAL_STATISTICS);
    const navigationSections = getNavigationSections(hasPersonalStatisticsRoles);

    useEffect(() => {
        dispatch(fetchNextSpotToVerify(country));
    }, [country, dispatch])

    return (
        <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
            {navigationSections.map(({ ...props }) => (
                <NavigationMenuSection key={props.headingLabelId} {...props} />
            ))}
        </Menu>
    );
};
