import './BranchDetails.scss';

/* eslint-disable react-hooks/exhaustive-deps */
import {ReactNode, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { History } from 'history';
import { Button, TextField } from '@mui/material';
import { ArrowForward, OpenInNew } from '@mui/icons-material';
import {
    API_STATUSES,
    API_MASTER_DATA_BRANCHES,
    API_REPRESENTATIVE_BRANCH_FOR_BANNER,
    API_BRANCH_DETAILS
} from 'config/api/constants';
import { paths } from 'paths';
import { handyDictionaryEntries } from 'shared/handyDictionaryEntries';
import {branchDetailsResponseModel} from 'shared/models/branch.model';
import {representativeBranch, representativeBranchResponseModel} from 'shared/models/representativeBranch.model';
import {bannerWithRetailer} from 'shared/models/banner.model';
import { IRootState } from 'shared/reducers';
import {basic} from 'shared/models/_common.model';
import {setPreferredCountry} from 'shared/reducers/userProfileActions';
import { useApi } from 'utils/axiosHooks/axiosHooks';
import { useFormatMessage } from 'utils/translate';
import { getURLParam, getId } from 'utils/routing';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import { PaperX } from 'components/PaperX';
import { LoadingOverlay } from 'components/LoadingOverlay/LoadingOverlay';
import ActiveCheckbox from 'components/ActiveCheckbox';
import { CountryBannerSelector, RegionSelector, NielsenRegionSelector } from 'components/Selectors';
import TypeOfBranchSelector from 'components/Selectors/TypeOfBranchSelector/TypeOfBranchSelector';

const branchTemplate: branchDetailsResponseModel = {
    active: false,
    advertisingAreas: [],
    bannerId: '',
    city: '',
    franchiseOwner: [],
    houseNumber: '',
    id: '',
    latitude: '',
    longitude: '',
    nielsenRegionId: '',
    postCode: '',
    regionId: '',
    street: '',
    retailerHeadquarter: undefined,
    typeOfBranchId: handyDictionaryEntries.typeOfBranchStandard,
    version: null
};

type branchDetailsProps = {
    history: History
}

const findRepresentativeBranch = (branches: representativeBranchResponseModel, branchId: string): representativeBranch =>
    branches.find((item:representativeBranch) => item.branchId === branchId);

const BranchDetails = (props: branchDetailsProps) => {
    const translate = useFormatMessage();
    const dispatch = useDispatch();

    const branchAPIGet = useApi('get', branchTemplate, {errMsg: 'branchDetails.err'});
    const branchAPIPost = useApi('post', branchTemplate, {errMsg: 'branchDetails.saveErr', succMsg: 'branchDetails.succ'});
    const branchAPIPut = useApi('put', branchTemplate, {errMsg: 'branchDetails.saveErr', succMsg: 'branchDetails.succ', failureMsg: 'branchDetails.cantDeactivate'});

    const repBranchesApiGet = useApi('get', []);

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);

    const [ selectedBranch, setSelectedBranch ] = useState<branchDetailsResponseModel>(branchTemplate);
    const [ bannerId, setBannerId ] = useState<string>('');
    const [ nielsenDisabled, setNielsenDisabled ] = useState<boolean>(false);

    const [newBranch, setNewBranch] = useState<boolean>(false);

    const [representativeBranch, setRepresentativeBranch] = useState<representativeBranch>(null);

    const [returnPath, setReturnPath] = useState<string>(paths.masterData);

    const [hasPostCodeError, setHasPostCodeError] = useState<boolean>(false);

    useEffect(() => {
        const bannerId: string = getURLParam('bannerId');
        const id: string = getId();
        const fromUrl: string = getURLParam('from');
        setBannerId(bannerId);
        if (id) {
            branchAPIGet.call(API_BRANCH_DETAILS(id, selectedLanguage));
            repBranchesApiGet.call(API_REPRESENTATIVE_BRANCH_FOR_BANNER(bannerId));
        }
        if (fromUrl) {
            setReturnPath(fromUrl);
        }
        const newBranch: string = getURLParam('new');
        if (newBranch){
            setNewBranch(true);
        }
    }, []);

    useEffect(() => {
        if (branchAPIGet.status === API_STATUSES.IDLE) {
            setSelectedBranch(branchAPIGet.data);
        }
    }, [branchAPIGet.status]);

    useEffect(() => {
        if (repBranchesApiGet.status === API_STATUSES.IDLE) {
            testRepresentative(getId());
        }
    }, [repBranchesApiGet.status]);

    useEffect(() => {
        if (countryMarket.preferredCountry === 'DE' || countryMarket.preferredCountry === 'AT') {
            setNielsenDisabled(false);
        } else {
            setNielsenDisabled(true);
        }
        handleChange('nielsenRegionId', '');
    }, [countryMarket]);

    useEffect(() => {
        const newBranch: branchDetailsResponseModel = {...selectedBranch};
        newBranch.bannerId = bannerId;
        setSelectedBranch(newBranch);
    }, [bannerId]);

    const testRepresentative = (branchId:string) => {
        const repBranch: representativeBranch = findRepresentativeBranch(repBranchesApiGet.data, branchId);
        if (repBranch) {
            setRepresentativeBranch(repBranch);
        }
    };

    const buildLists = (prop): ReactNode => {
        const path = prop === selectedBranch.advertisingAreas ? paths.advertisingArea : paths.franchiseOwner;
        if (prop.length === 0) {
            return <li>-</li>;
        }
        return prop.map((item) =>(
            <li key={item.id}>
                <span>{item.name}</span>
                <Link to={`${path}?id=${item.id}`} target="_blank" className="icon">
                    <OpenInNew/>
                </Link>
            </li>));
    }

    const buildRetailerHQLink = (retailerHQ: basic): ReactNode => {
        if (retailerHQ) {
            return <li>
                <span>{retailerHQ.name}</span>
                <Link to={`${paths.retailerHQDetails}?id=${retailerHQ.id}`} target="_blank" className="icon">
                    <OpenInNew/>
                </Link>
            </li>
        }
        return <li>-</li>;
    }

    const handleCountryChange = (value: string) => dispatch(setPreferredCountry(value));
    const handleBannerChange = (banner: bannerWithRetailer) => {
        setBannerId(banner?.bannerId);
    };
    const handleRegionChange = (value: string) => handleChange('regionId', value);
    const handleNielsenChange = (value: string) => handleChange('nielsenRegionId', value);

    const handleChange = (key: string, value: string | boolean) => {
        if (typeof value === 'string' && key === 'postCode') {
            setHasPostCodeError(value.length > 15);
        }

        const newBranch: branchDetailsResponseModel = {...selectedBranch};
        newBranch[key] = value;
        setSelectedBranch(newBranch);
    };

    const disableSave = (): boolean => selectedBranch && (
        !selectedBranch.postCode || hasPostCodeError|| !selectedBranch.city ||
        !selectedBranch.street || !selectedBranch.houseNumber ||
        !selectedBranch.latitude || !selectedBranch.longitude ||
        !selectedBranch.regionId
    );

    const handleBranchPropertyChange = (key: string, value: string) => {
        const newBranchLocal: branchDetailsResponseModel = {...selectedBranch};
        newBranchLocal[key] = value;
        setSelectedBranch(newBranchLocal);
    };

    const handleSaveClick = () => {
        if (selectedBranch.id){
            branchAPIPut.call(`${API_MASTER_DATA_BRANCHES}/${selectedBranch.id}?lang=${selectedLanguage}`, selectedBranch);
        } else {
            branchAPIPost.call(`${API_MASTER_DATA_BRANCHES}`, selectedBranch);
        }
    };

    useEffect(() => {
        if (branchAPIPut.status === API_STATUSES.IDLE || branchAPIPost.status === API_STATUSES.IDLE) {
            props.history.push(`${paths.branchesList}?id=${bannerId}&from=${returnPath}`);
        }
    }, [branchAPIPut.status, branchAPIPost.status]);

    const showLoading = (): boolean => branchAPIPut.status === API_STATUSES.PENDING || branchAPIPost.status === API_STATUSES.PENDING || branchAPIGet.status === API_STATUSES.PENDING || repBranchesApiGet.status === API_STATUSES.PENDING;

    return  (
        <div className="viewRoot branchDetailsRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <CountryBannerSelector
                            country={countryMarket.preferredCountry}
                            bannerId={bannerId}
                            onCountryChange={handleCountryChange}
                            onBannerChange={handleBannerChange}
                        />
                    </PaperX>
                    <div className="_directionRow">
                        <PaperX className="_fullWidth _fullHeight">
                            <div className="_header">
                                {translate({id: 'b.general'})}
                            </div>
                            <br/>
                            <div className="_formRow">
                                <TypeOfBranchSelector
                                      value={selectedBranch.typeOfBranchId}
                                      required
                                      onChange={(typeOfBranchId) => handleBranchPropertyChange('typeOfBranchId', typeOfBranchId)}
                                />
                            </div>
                            <div className="_formRow">
                                <ActiveCheckbox tabIndex={-1} value={selectedBranch.active} onChange={(key, value) => handleChange(key, value)} disabled={representativeBranch !== null}/>
                            </div>
                            {representativeBranch !== null &&
                                <div className="_formRow">
                                    <div>
                                        *&nbsp;{translate({id: 'branchDetails.isRep'})}
                                        <Link to={`${paths.advertisingArea}?id=${representativeBranch.advertisingAreaId}`} target="_blank">
                                            <Button color="secondary" size="small" variant="outlined" endIcon={<ArrowForward/>}>
                                                {representativeBranch.advertisingAreaName}
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            }
                            {!newBranch ? <div>
                                <div className="textField">
                                    <span className="branchDetailsLabel">
                                        <FormattedMessage id="b.advertisingAreas"/>
                                    </span>:&nbsp;
                                    <ul>
                                        {buildLists(selectedBranch.advertisingAreas)}
                                    </ul>
                                </div>
                                <div>
                                    <span className="branchDetailsLabel">
                                        <FormattedMessage id="b.franchiseOwner"/>
                                    </span>:&nbsp;
                                    <ul>
                                        {buildLists(selectedBranch.franchiseOwner)}
                                    </ul>
                                </div>
                                <div>
                                    <span className="branchDetailsLabel">
                                        <FormattedMessage id="b.retailerHQ"/>
                                    </span>:&nbsp;
                                    <ul>
                                        {buildRetailerHQLink(selectedBranch.retailerHeadquarter)}
                                    </ul>
                                </div>
                            </div> : null }
                        </PaperX>
                        <PaperX className="_fullWidth _fullHeight">
                            <div className="_header">
                                {translate({id: 'b.address'})}
                            </div>
                            <br/>
                            <div className="_formRowDouble">
                                <TextField className="_fullWidth"
                                    value={selectedBranch.postCode}
                                    onChange={(e) => handleChange('postCode', e.target.value)}
                                    label={translate({id: 'b.postCode'})}
                                    required
                                    error={hasPostCodeError}
                                    helperText={hasPostCodeError && translate({id: 'branchDetails.postCodeError'})}
                                />
                                <TextField className="_fullWidth"
                                    value={selectedBranch.city}
                                    onChange={(e) => handleChange('city', e.target.value)}
                                    label={translate({id: 'b.city'})}
                                    required
                                />
                            </div>
                            <div className="_formRowDouble">
                                <TextField className="_fullWidth"
                                    value={selectedBranch.street}
                                    onChange={(e) => handleChange('street', e.target.value)}
                                    label={translate({id: 'b.street'})}
                                    required
                                />
                                <TextField className="_fullWidth"
                                    value={selectedBranch.houseNumber}
                                    onChange={(e) => handleChange('houseNumber', e.target.value)}
                                    label={translate({id: 'b.houseNumber'})}
                                    required
                                />
                            </div>
                        </PaperX>
                        <PaperX className="_fullWidth _fullHeight">
                            <div className="_header">
                                {translate({id: 'b.location'})}
                            </div>
                            <br/>
                            <div className="_formRowDouble">
                                <RegionSelector
                                    country={countryMarket.preferredCountry}
                                    regionId={selectedBranch.regionId}
                                    onRegionChange={handleRegionChange}
                                    required
                                />
                                <NielsenRegionSelector
                                    country={countryMarket.preferredCountry}
                                    nielsenRegionId={selectedBranch.nielsenRegionId}
                                    onNielsenChange={handleNielsenChange}
                                    disabled={nielsenDisabled}
                                />
                            </div>
                            <div className="_formRowDouble">
                                <TextField className="_fullWidth"
                                    value={selectedBranch.latitude}
                                    onChange={(e) => handleChange('latitude', e.target.value)}
                                    label={translate({id: 'b.latitude'})}
                                    required
                                />
                                <TextField className="_fullWidth"
                                    value={selectedBranch.longitude}
                                    onChange={(e) => handleChange('longitude', e.target.value)}
                                    label={translate({id: 'b.longitude'})}
                                    required
                                />
                            </div>
                        </PaperX>
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button onClick={handleSaveClick} color="primary" variant="contained" disabled={disableSave()}>
                            {translate({id: 'a.save'})}
                        </Button>
                        <Link to={`${paths.branchesList}?id=${bannerId}&from=${returnPath}`}>
                            <ButtonClose />
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default BranchDetails;