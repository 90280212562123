import {ACTION_TYPES as LEAFLET_ACTION_TYPES} from './leafletUpload';
export const ACTION_TYPES ={
    SPOT_UPLOAD_DIALOG_TOGGLE: 'SPOT_UPLOAD_DIALOG_TOGGLE'
};

export const spotUploadDialogToggle = (dialogOpen: boolean) => ({
    type: ACTION_TYPES.SPOT_UPLOAD_DIALOG_TOGGLE,
    payload: {dialogOpen}
});

type spotUploadDialogState = {
    dialogOpen: boolean
}

const initialState: spotUploadDialogState = {
    dialogOpen: false
};

export type SpotUploadState = Readonly<typeof initialState>;

const spotUploadReducer = (state: SpotUploadState = initialState, action): SpotUploadState => {
    switch (action.type) {
        case ACTION_TYPES.SPOT_UPLOAD_DIALOG_TOGGLE:
            return {...state, dialogOpen: action.payload.dialogOpen};
        case LEAFLET_ACTION_TYPES.LEAFLET_UPLOAD_DIALOG_TOGGLE:
            if (!action.payload.dialogOpen) return state;
            return {...state, dialogOpen: false};
        default:
            return state;
    }
};

export default spotUploadReducer;