import { combineReducers } from 'redux';
import storagePileState, { StoragePileState } from './storagePile';
import keyHierarchy, { KeyHierarchyState } from './keyHierarchy';
import bannersWithRetailer, { BannersWithRetailerState } from './bannersWithRetailer';
import retailers, { RetailersState } from './retailers';
import leafletUpload, { LeafletUploadState } from './leafletUpload';
import dictDictionaries, { DictDictionariesState } from './dictDictionaries';
import branchesList, { BranchesListState } from './branchesList';
import branchesPerRetailer, { BranchesPerRetailerState } from './branchsePerRetailer';
import userPermissions, { UserPermissionsState } from './userPermissions';
import userProfile, { UserProfileState } from './userProfile';
import userSettings, { UserSettingsState } from './userSettings';
import categoryHierarchy, { CategoryHierarchyState } from './categoryHierarchy';
import dictTaxes, {DictTaxesState} from './dictTaxes';
import spotUpload, {SpotUploadState} from './spotUpload';
import nextSpotToVerify, {NextSpotToVerifyState} from './nextSpotToVerify';

export interface IRootState {
  readonly storagePileState: StoragePileState,
  readonly keyHierarchy: KeyHierarchyState,
  readonly bannersWithRetailer: BannersWithRetailerState,
  readonly retailers: RetailersState,
  readonly leafletUpload: LeafletUploadState,
  readonly spotUpload: SpotUploadState,
  readonly dictDictionaries: DictDictionariesState,
  readonly dictTaxes: DictTaxesState,
  readonly branchesList: BranchesListState,
  readonly branchesPerRetailer: BranchesPerRetailerState,
  readonly userPermissions: UserPermissionsState,
  readonly userProfile: UserProfileState,
  readonly userSettings: UserSettingsState,
  readonly categoryHierarchy: CategoryHierarchyState,
  readonly nextSpotToVerify: NextSpotToVerifyState,
}

const rootReducer = combineReducers<IRootState>({
  storagePileState,
  keyHierarchy,
  bannersWithRetailer,
  retailers,
  leafletUpload,
  spotUpload,
  dictDictionaries,
  dictTaxes,
  branchesList,
  branchesPerRetailer,
  userPermissions,
  userProfile,
  userSettings,
  categoryHierarchy,
  nextSpotToVerify
});

export default rootReducer;