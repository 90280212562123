/* eslint-disable react-hooks/exhaustive-deps */
import './MediaDescription.scss';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ButtonClose from 'components/Buttons/ButtonClose';
import Footer from 'components/Footer';
import MediaSummary, {buildMediaSummary} from 'components/MediaSummary/MediaSummary';
import Promotion from 'components/Promotion';
import {LoadingOverlay} from 'components/LoadingOverlay/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import {v4 as uuidv4} from 'uuid';
import {useSnackbar} from 'notistack';
import {paths} from 'paths';
import _ from 'lodash';
import {Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import {
    API_MEDIA_GET_SPOT_FOR_DESCRIBE,
    API_MEDIA_GET_NEXT_SPOT_FOR_DESCRIBE,
    API_PROMOTION_MANAGEMENT_PROMOTIONS,
    API_STATUSES
} from 'config/api/constants';
import {getURLParam} from 'utils/routing';
import {lockLevels, lockSetter, releaseMediumLock} from 'utils/lockUtils';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import {BPCOResponseModel} from 'shared/models/BPCO.model';
import {IRootState} from 'shared/reducers';
import {getCurrencyForCountry} from 'shared/reducers/userProfile';
import {mediumPromotionsPayload, promotionMediumType, spotResponseModel, spotTemplate} from 'shared/models/media.model';
import {productDetailsResponseModel, productSearchResultUI} from 'shared/models/product.model';
import {promotionUI} from 'shared/models/promotion.model';
import BPCODrawer, {BPCODrawerProps} from 'modules/Advertisement/FrameDescription/BPCODrawer/BPCODrawer';
import MediaDescriptionSummaryDrawer from 'modules/Advertisement/MediaDescription/MediaDescriptionSummaryDrawer';
import MediaAudiofile from 'modules/Medium/MediaAudiofile/MediaAudiofile';
import ProductDrawer, {productDrawerProps} from 'modules/Advertisement/FrameDescription/ProductDrawer/ProductDrawer';
import ProductSearch from 'modules/Advertisement/FrameDescription/ProductSearch/ProductSearch';
import {BPCODetails2productSearchResultUI, productDetails2productSearchResultUI} from 'modules/Advertisement/FrameDescription/frameDescriptionUtils';
import {arePromotionsValid} from 'modules/Advertisement/FrameDescription/FrameDescriptionMVP';
import {createMediumDescriptionPayload, makePromotionOfProductSearch} from 'modules/Advertisement/AdvertisementsOverview/advertisementsOverviewIO';
import {extendOrOverwritePromotionType, newBPCODrawerProps, newProductDrawerProps} from 'modules/Advertisement/FrameDescription/FrameDescriptionMVP';

const MediaDescription = ({history}) => {
    const translate = useFormatMessage();
    const {enqueueSnackbar} = useSnackbar();

    const spotDataApiGet = useApi('get', {errMsg: 'media.spotFetchErr'});
    const mediaApiPost = useApi('post', {}, {errMsg: 'frameDescription.saveErr', succMsg: 'frameDescription.succ'});

    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);

    const [isSummaryDrawerOpen, setIsSummaryDrawerOpen] = useState<boolean>(false);
    const [returnPath, setReturnPath] = useState<string>(paths.home);
    const [productDrawerProperties, setProductDrawerProperties] = useState<productDrawerProps>(newProductDrawerProps);
    const [BPCODrawerProperties, setBPCODrawerProperties] = useState<BPCODrawerProps>(newBPCODrawerProps);
    const [promotions, setPromotions] = useState<promotionUI[]>([]);
    const [promotionUIId, setPromotionUIId] = useState<string>(null);
    const [mediumData, setMediumData] = useState<spotResponseModel>(spotTemplate);
    const [mediumId, setMediumId] = useState<string>(null);
    const [promoMediumType, setPromoMediumType] = useState<promotionMediumType>(null); 
    const [mediaHeaderDataSummary, setMediaHeaderDataSummary] = useState(null);

    useEffect(() => {
        const fromUrl = getURLParam('from');
        if (fromUrl) setReturnPath(fromUrl);
        const extSpotId = getURLParam('id');
        spotDataApiGet.call(extSpotId ? API_MEDIA_GET_SPOT_FOR_DESCRIBE(extSpotId) : API_MEDIA_GET_NEXT_SPOT_FOR_DESCRIBE(countryMarket.preferredCountry));
        lockSetter(lockLevels.medium);
        return history.listen(location => {
            if (location.pathname === paths.home || location.pathname === paths.spotOverview) {
                releaseMediumLock();
            }
        })
    }, []);

    useEffect(() => {
        if (spotDataApiGet.status === API_STATUSES.IDLE) {
            if (spotDataApiGet.data) {
                const response = spotDataApiGet.data;
                if (response.spotId) {
                    setMediumId(response.spotId);
                    setPromoMediumType(response.mediumType);
                }
                const {headerData} = response;
                setMediumData(spotDataApiGet.data);
                setMediaHeaderDataSummary(buildMediaSummary(headerData));
            } else {
                setMediumData(spotTemplate);
                enqueueSnackbar(translate({id: 'media.errNoMedia'}), {variant: 'warning'});
                goBack();
            }
        }
        if (spotDataApiGet.status === API_STATUSES.ERROR && spotDataApiGet.errorCode === 409) {
            enqueueSnackbar(`${translate({id: 'media.spotLocked'})}`, {variant: 'error', persist: false});
            history.push(paths.spotOverview);
        }
    }, [spotDataApiGet.status]);

    useEffect(() => {
        if (mediaApiPost.status === API_STATUSES.IDLE) {
            const extLeafletId = getURLParam('id');
            if (extLeafletId) {
                goBack();
            } else {
                spotDataApiGet.call(API_MEDIA_GET_NEXT_SPOT_FOR_DESCRIBE(countryMarket.preferredCountry));
            }
            setPromotions([]);

            setMediumId(null);
            setPromoMediumType(null);
            setMediumData(spotTemplate);

        }
    }, [mediaApiPost.status]);

    const handleSubmit = () => {
        const payload: mediumPromotionsPayload = createMediumDescriptionPayload(mediumId, promoMediumType, promotions);
        mediaApiPost.call(API_PROMOTION_MANAGEMENT_PROMOTIONS, payload);
        setIsSummaryDrawerOpen(false);
    };

    const handleProductSelect = (selectedProduct: productSearchResultUI) => {
        const newPromotions = [...promotions];
        newPromotions.push(makePromotionOfProductSearch(selectedProduct, mediumData?.headerData?.themeSeasons, mediumData?.headerData?.countryThemedWeeks, null, getCurrencyForCountry(mediumData?.country)));
        setPromotions(newPromotions);
        enqueueSnackbar(`${translate({id: 'b.productAdded'})}`, {variant: 'success', persist: false});
    };

    const handlePromotionClone = (promotionUIId: string) => {
        const matchedPromotion = promotions.find((item) => item.UIId === promotionUIId);
        const clonedPromotion: promotionUI = _.cloneDeep(matchedPromotion);
        if (clonedPromotion) {
            clonedPromotion.UIId = uuidv4();
            setPromotions([...promotions, clonedPromotion]);
        }
    };

    const handlePromotionUpdate = (key:string, value: any, id: string) => {
        const newPromotions = [...promotions];
        const targetIndex = newPromotions.findIndex((promotion) => promotion.UIId === id);
        newPromotions[targetIndex][key] = value;
        setPromotions(newPromotions);
    };

    const handlePromotionRemoval = (promotionId: string) => {
        const newPromotions = [...promotions].filter((promotion) => promotion.UIId !== promotionId);
        setPromotions(newPromotions);
    };

    const handlePromotionError = (error: boolean, promotionId: string) => handlePromotionUpdate('hasError', error, promotionId);

    const isBusy = () => spotDataApiGet.status === API_STATUSES.PENDING || mediaApiPost.status === API_STATUSES.PENDING;

    const openNewProductDrawer = () => setProductDrawerProperties({...newProductDrawerProps, open: true});

    const closeProductDrawer = () => setProductDrawerProperties({...newProductDrawerProps, open: false});

    const openNewBPCODrawer = () => setBPCODrawerProperties({...newBPCODrawerProps, open: true});

    const closeBPCODrawer = () => setBPCODrawerProperties({...newBPCODrawerProps, open: false});

    const openCloneProductDialog = (productId: string, promotionUIId: string) => {
        setPromotionUIId(promotionUIId);
        setProductDrawerProperties({
            clone: true,
            isNewProduct: false,
            open: true,
            productId
        });
    };

    const openCloneBPCODialog = (BPCOId: string, promotionUIId: string) => {
        setPromotionUIId(promotionUIId);
        setBPCODrawerProperties({
            clone: true,
            isNewBPCO: false,
            open: true,
            BPCOId
        });
    };

    const goBack = () => history.push(returnPath);

    const canSave = () => mediumData && mediumId && promoMediumType && arePromotionsValid(promotions) && !isBusy();

    const handleProductCreated = (createdProduct: productDetailsResponseModel, extendOrOverwrite: extendOrOverwritePromotionType) => {
        if (extendOrOverwrite.extend) {
            handleProductSelect(productDetails2productSearchResultUI(createdProduct));
        } else {
            try {
                const updatePromotion: promotionUI[] = [...promotions];
                const index: number = updatePromotion.findIndex((promotion) => promotion.UIId === promotionUIId);
                const productDetails: productSearchResultUI = productDetails2productSearchResultUI(createdProduct);
                const clonedPromotion: promotionUI = _.cloneDeep(updatePromotion[index]);

                Object.assign(clonedPromotion, productDetails);
                updatePromotion[index] = {
                    ...clonedPromotion,
                    productId: productDetails.id,
                    id: clonedPromotion.UIId
                };
                setPromotions(updatePromotion);
                enqueueSnackbar(`${translate({id: 'b.productAdded'})}`, {variant: 'success', persist: false});
            } catch (e) {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'frameDescription.errCantReuse'})}`, {variant: 'error', persist: false});
            }
        }
        setPromotionUIId(null);
        closeProductDrawer();
    };

    const handleBPCOCreated = (createdBPCO: BPCOResponseModel, extendOrOverwrite: extendOrOverwritePromotionType) => {
        if (extendOrOverwrite.extend) {
            handleProductSelect(BPCODetails2productSearchResultUI(createdBPCO));
        } else {
            const updatePromotion: promotionUI[] = [...promotions];
            const index: number = updatePromotion.findIndex((promotion) => promotion.UIId === promotionUIId);
            const bpcoDetails: productSearchResultUI = BPCODetails2productSearchResultUI(createdBPCO);
            const clonedPromotion: promotionUI = _.cloneDeep(updatePromotion[index]);

            Object.assign(clonedPromotion, bpcoDetails);
            updatePromotion[index] = {
                ...clonedPromotion,
                bpcoId: bpcoDetails.id,
                id: clonedPromotion.UIId
            };
            setPromotions(updatePromotion);
            enqueueSnackbar(`${translate({id: 'b.bpcoAdded'})}`, {variant: 'success', persist: false});
        }
        setPromotionUIId(null);
        closeBPCODrawer();
    };

    const handleTableRowClick = (id: string) => {
        const selectedRow: HTMLElement = document.getElementById(id);
        if (selectedRow) {
            setIsSummaryDrawerOpen(false);
            selectedRow.scrollIntoView();
            selectedRow.getElementsByTagName('input')[0].focus();
        }
    };

    return (
        <div className="viewRoot mediaDescriptionRoot">
            <div className="viewport">
                <LoadingOverlay show={isBusy()}/>
                <div className="viewContainer _directionRow">
                    <div className="_directionCol leftCol">
                        <div className="_growRelative">
                            <div className="_aboveDrawer">
                                <PaperX className="_fullHeight">
                                    <div className="_formRow audiofileRow">
                                        <MediaAudiofile
                                            country={mediumData.country}
                                            filename={mediumData.metaData.sourceFile}
                                            storageFileName={mediumData.metaData.storageFileName}
                                            isPlayMode={true}
                                            mediumType={mediumData.mediumType}
                                        />
                                    </div>
                                    <div className="_formRow">
                                        <div className="transcriptionReadContainer">
                                                <Typography variant="overline">{translate({id: 'media.transcription'})}</Typography>
                                                <div className='transcriptValue'>
                                                    <Typography variant="body1" paragraph>
                                                        {mediumData.headerData.transcription}
                                                    </Typography>
                                                </div>
                                        </div>
                                    </div>
                                </PaperX>
                            </div>
                        </div>
                        <PaperX className="weldBottom">
                            <MediaSummary data={mediaHeaderDataSummary}/>
                        </PaperX>
                        <PaperX className="weldTop"/>
                    </div>
                    <div className="advertisementContainer _directionCol">
                        <PaperX>
                            <ProductSearch countryId={mediumData.country}
                                           onProductClick={handleProductSelect}
                                           selectedItemsIds={promotions.map((item) => item.productId || item.bpcoId)}
                            />
                        </PaperX>
                        <PaperX className="_fullHeight _fullWidth _scrollY">
                            {promotions.map((item) =>
                                <Promotion countryId={mediumData.country} data={item} key={item.UIId}
                                           onUpdatePromotion={handlePromotionUpdate}
                                           onRemovePromotion={handlePromotionRemoval}
                                           onPromotionError={(error, promotionId) => handlePromotionError(error, promotionId)}
                                           onCloneProduct={(productId, promotionUIId) => openCloneProductDialog(productId, promotionUIId)}
                                           onCloneBPCO={(bpcoId, promotionUIId) => openCloneBPCODialog(bpcoId, promotionUIId)}
                                           onClonePromotion={(promotionUIId) => handlePromotionClone(promotionUIId)}
                                />)}
                        </PaperX>
                        <ProductDrawer {...productDrawerProperties}
                                       onClose={closeProductDrawer}
                                       onCreate={(newProduct, extendOrOverwrite) => handleProductCreated(newProduct, extendOrOverwrite)}
                        />
                        <BPCODrawer {...BPCODrawerProperties}
                                    onClose={closeBPCODrawer}
                                    onCreate={(newBPCO, extendOrOverwrite) => handleBPCOCreated(newBPCO, extendOrOverwrite)}
                        />
                    </div>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        <Button variant="outlined" color="primary" onClick={() => setIsSummaryDrawerOpen(true)}>{translate({id: 'a.summary'})}</Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!canSave()}>{translate({id: 'a.submit'})}</Button>
                        <Button variant="outlined" color="primary" tabIndex={-1} onClick={openNewProductDrawer}>{translate({id: 'productSearch.createProduct'})}</Button>
                        <Button variant="outlined" color="primary" tabIndex={-1} onClick={openNewBPCODrawer}>{translate({id: 'productSearch.createBPCO'})}</Button>
                        <ButtonClose onClick={goBack}/>
                    </>
                }
            />
            <MediaDescriptionSummaryDrawer open={isSummaryDrawerOpen}
                                           onClose={() => setIsSummaryDrawerOpen(false)}
                                           onRowClick={handleTableRowClick}
                                           handleSubmit={handleSubmit}
                                           canSave={canSave()}
                                           headerData={mediumData.headerData}
                                           promotions={promotions}
            />
        </div>
    );
};

export default MediaDescription;
