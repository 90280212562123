/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {Button, TextField} from '@mui/material';
import {API_SUPPLIER, API_SUPPLIER_CREATE} from 'config/api/constants';
import {paths} from 'paths';
import {specialRoles} from 'shared/models/permissions.model';
import {supplier, supplierPostPayloadModel, supplierPutPayloadModel} from 'shared/models/supplier.model';
import {IRootState} from 'shared/reducers';
import {basic} from 'shared/models/_common.model';
import {useFormatMessage} from 'utils/translate';
import {getId} from 'utils/routing';
import {responseValidation} from 'utils/responseValidation';
import BrandSearch from 'modules/MasterData/Brand/BrandSearch';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';
import {ButtonClose} from 'components/Buttons';
import ActiveCheckbox from 'components/ActiveCheckbox';
import {TypeOfBrandSelector} from 'components/Selectors';
import {
    BrandDisplay,
    BrandSmartDisplay,
    MarketDisplay,
    StructuredBrandSmartDisplay
} from 'components/Displays';

function buildSupplierTemplate (marketId: string): supplier {
    return {
        active: true,
        brandIds: [],
        marketId,
        name: '',
        structuredBrandIds: [],
        typeOfBrandId: ''
    }
}

const SupplierDetails = ({history}) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [supplier, setSupplier] = useState<supplier>(null);
    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const userRoles = useSelector((state: IRootState) => state.userPermissions.specialRoles);

    const isAllowed: boolean = userRoles.includes(specialRoles.PRODUCT_MANAGEMENT);

    const [brands2Add, setBrands2Add] = useState<basic[]>([]);

    useEffect(() => {
        const id = getId();
        if (id) {
            getSupplier(id);
        } else {
            setSupplier(buildSupplierTemplate(countryMarket.market));
        }
    }, []);

    const notifyError = (msgKey: string) => enqueueSnackbar(`${translate({id: msgKey})}`, {variant: 'error', persist: false});
    const onLoadFailed = () => {
        notifyError('supplier.error');
        history.push(paths.suppliers);
    };

    const getSupplier = (id: string) => {
        setIsLoading(true);
        axios.get<supplier>(API_SUPPLIER(id))
            .then((response) => {
                if (responseValidation(response.data)) {
                    setSupplier(response.data)
                    setIsLoading(false);
                } else {
                    onLoadFailed();
                }
            })
            .catch((e) => {
                console.log(e);
                onLoadFailed();
            });
    };

    const updateSupplier = (key: string, value: string | boolean) => {
        const newSup = {...supplier};
        newSup[key] = value;
        setSupplier(newSup);
    };

    const handleSaveClick = () => {
        setIsLoading(true);
        if (supplier?.id) {
            const {active, name, typeOfBrandId} = supplier;
            const brands: string[] = supplier.brandIds.concat(supplier.structuredBrandIds).concat(brands2Add.map((item) => item.id));
            const payload: supplierPutPayloadModel = {active, name, typeOfBrandId, brandIds: Array.from(new Set(brands).values())};
            axios.put(API_SUPPLIER(supplier.id), payload)
                .then(() => {
                    history.push(paths.suppliers);
                    enqueueSnackbar(`${translate({id: 'supplier.updated'})}`, {variant: 'success', persist: false});
                })
                .catch((e) => {
                    console.log(e);
                    notifyError('supplier.errorUpdate');
                })
                .finally(() => setIsLoading(false));
        } else {
            const {active, marketId, name, typeOfBrandId} = supplier;
            const brands: string[] = supplier.brandIds.concat(supplier.structuredBrandIds).concat(brands2Add.map((item) => item.id));
            const payload: supplierPostPayloadModel = {active, marketId, name, typeOfBrandId, brandIds: Array.from(new Set(brands).values())};
            axios.post(API_SUPPLIER_CREATE, payload)
                .then(() => {
                    enqueueSnackbar(`${translate({id: 'supplier.created'})}`, {variant: 'success', persist: false});
                    history.push(paths.suppliers);
                })
                .catch((e) => {
                    if (e.response.status === 409) {
                        notifyError('supplier.errorDuplicate');
                    } else {
                        console.log(e);
                        notifyError('supplier.errorCreate');
                    }
                })
                .finally(() => setIsLoading(false));
        }
    };

    const canSave = (): boolean => {
        if (supplier) {
            if (!supplier.name || !supplier.typeOfBrandId) {
                return false;
            }
            return true;
        }
        return false;
    };

    const handleBrandSelect = (selectedBrand) => {
        if (!brands2Add.find((item) => item.id === selectedBrand.id)) {
            const newBrands = [...brands2Add];
            newBrands.push({id: selectedBrand.id, name: selectedBrand.name});
            setBrands2Add(newBrands);
        }
    };

    const removeBrand = (id: string, isStructuredBrand: boolean = false) => {
        const newSupplier: supplier = {...supplier};
        if (isStructuredBrand) {
            newSupplier.structuredBrandIds = supplier.structuredBrandIds.filter((item) => item !== id);
        } else {
            newSupplier.brandIds = supplier.brandIds.filter((item) => item !== id);
        }
        setSupplier(newSupplier);
    };

    const removeNewBrand = (id: string) => setBrands2Add(brands2Add.filter((item) => item.id !== id));

    return (
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionRow">
                    <LoadingOverlay show={isLoading}/>
                    <div className="_directionCol _fullHeight _fullWidth">
                        <PaperX className="weldBottom">
                            <div className="_header">
                                <FormattedMessage id="b.supplier"/>
                            </div>
                            <div className="_formRowDouble">
                                <MarketDisplay marketId={supplier ? supplier.marketId : ''}/>
                            </div>
                            <div className="_formRow">
                                <TextField variant="outlined"
                                           label={translate({id: 'b.name'})}
                                           value={supplier ? supplier.name : ''}
                                           required
                                           onChange={(e) => updateSupplier('name', e.target.value)}
                                           disabled={!isAllowed}
                                />
                            </div>
                            <div className="_formRowDouble">
                                <TypeOfBrandSelector value={supplier ? supplier.typeOfBrandId : ''}
                                                     isReadOnly={!isAllowed}
                                                     onChange={(id) => updateSupplier('typeOfBrandId', id)}
                                                     required
                                />
                            </div>
                            <div className="_formRowDouble">
                                <ActiveCheckbox value={supplier ? supplier.active : true} onChange={updateSupplier} disabled={!isAllowed}/>
                            </div>
                            <div className="_bold">
                                <FormattedMessage id="b.brands"/>:
                            </div>
                        </PaperX>
                        <PaperX className="weldTop _scrollY _fullHeight">
                            <div>
                                {brands2Add.map((item) => <BrandDisplay brand={item}
                                                                        onDelete={(id) => removeNewBrand(id)}
                                                                        key={item.id}
                                />)}
                            </div>
                            <div>
                                {supplier?.brandIds.map((item) => <BrandSmartDisplay id={item}
                                                                                     isReadOnly={!isAllowed}
                                                                                     onDelete={(id) => removeBrand(id)}
                                                                                     key={item}/>)}
                                {supplier?.structuredBrandIds.map((item) => <StructuredBrandSmartDisplay id={item}
                                                                                                         isReadOnly={!isAllowed}
                                                                                                         onDelete={(id) => removeBrand(id, true)}
                                                                                                         key={item}
                                />)}
                            </div>
                        </PaperX>
                    </div>
                    <PaperX className="_fullHeight _fullWidth">
                        <div className="_formRow">
                            {supplier && isAllowed && <BrandSearch onBrandSelect={handleBrandSelect} queryParams={{marketId: supplier.marketId, active: true}}/>}
                        </div>
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        {isAllowed && <Button color="primary" variant="contained" onClick={handleSaveClick} disabled={!canSave()}>{translate({id: 'a.save'})}</Button>}
                        <Link to={paths.suppliers}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
}

export default SupplierDetails;