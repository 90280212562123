/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {API_GET_SUPPLIERS} from 'config/api/constants';
import {paths} from 'paths';
import {specialRoles} from 'shared/models/permissions.model';
import {supplier} from 'shared/models/supplier.model';
import {IRootState} from 'shared/reducers';
import {useFormatMessage} from 'utils/translate';
import {responseValidation} from 'utils/responseValidation';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';
import {ButtonClose, ButtonNew} from 'components/Buttons';
import {MarketSelector} from 'components/Selectors';
import AgTable from 'components/AgTable/AgTable';
import {ActiveFlagCell} from 'components/AgTable/renderers';

const Suppliers = ({history}) => {
    const translate = useFormatMessage();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [suppliers, setSuppliers] = useState<supplier[]>([]);
    const countryMarket = useSelector((state: IRootState) => state.userProfile.countryMarket);
    const userRoles = useSelector((state: IRootState) => state.userPermissions.specialRoles);

    const isAllowed: boolean = userRoles.includes(specialRoles.PRODUCT_MANAGEMENT);

    useEffect(() => {
        getSuppliers();
    }, [countryMarket]);

    const getSuppliers = () => {
        setIsLoading(true);
        setSuppliers([]);
        axios.get<supplier[]>(API_GET_SUPPLIERS(countryMarket.market))
            .then((response) => {
                if (responseValidation(response.data)) {
                    setSuppliers(response.data);
                } else {
                    enqueueSnackbar(`${translate({id: 'supplier.errors'})}`, {variant: 'error', persist: false});
                }
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar(`${translate({id: 'supplier.errors'})}`, {variant: 'error', persist: false});
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const suppliersColumnDefs: ColDef[] = [
        { field: 'active', headerName: translate({ id: 'a.active' }), pinned: 'left', width: 80, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>), suppressMenu: true},
        { field: 'name', headerName: translate({ id: 'b.name' }), flex: 1, sortable: true,
            floatingFilter: true,
            filter: 'agTextColumnFilter',
            suppressMenu: true,
            floatingFilterComponentParams: {suppressFilterButton:true}
        }
    ];

    const goToDetails = (row: supplier) => history.push(`${paths.supplierDetails}?id=${row.id}`);

    return (
        <div className="viewRoot">
            <div className="viewport">
                <div className="viewContainer _directionCol">
                    <LoadingOverlay show={isLoading}/>
                    <PaperX>
                        <div className="_halfWidth">
                            <MarketSelector/>
                        </div>
                    </PaperX>
                    <PaperX className="_fullWidth _fullHeight _fullTable">
                        <AgTable
                            rowData={suppliers}
                            columnDefs={suppliersColumnDefs}
                            onRowClicked = {(rowData) => goToDetails(rowData.data)}
                        />
                    </PaperX>
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        {isAllowed && 
                            <Link to={paths.supplierDetails}>
                                <ButtonNew/>
                            </Link>}
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
}

export default Suppliers;