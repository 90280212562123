import {
    AccountTreeTwoTone,
    BugReport,
    BurstMode,
    ChangeHistory,
    CloudUpload,
    Dashboard,
    ListAlt,
    PhotoSizeSelectSmall,
    PictureInPicture,
    PlayCircle,
    PlayLesson,
    Radio,
    StarOutline,
    Timeline,
} from '@mui/icons-material';
import { ReactElement } from 'react';
import { AnyAction } from 'redux';

import { paths } from 'paths';
import { leafletUploadDialogToggle } from 'shared/reducers/leafletUpload';
import { spotUploadDialogToggle } from 'shared/reducers/spotUpload';

export type NavigationItem = {
    path: string,
    params?: string,
    action?: AnyAction,
    labelId: string,
    icon: ReactElement,
    id?: dialogIdentifier | menuItemIdentifier;
};

export type NavigationSection = {
    headingLabelId: string,
    items: NavigationItem[],
};

export enum dialogIdentifier {
    leafletUpload = 'leafletUpload',
    spotUpload = 'spotUpload'
}

export enum menuItemIdentifier {
    mediumVerification = 'mediumVerification'
}

 export const getNavigationSections = (hasPersonalStatisticsRoles: boolean): NavigationSection[] => [
    {
        headingLabelId: 'nav.leaflet',
        items: [
            {
                path: paths.home,
                action: leafletUploadDialogToggle(true),
                labelId: 'leafletUploadNavButton.leafletUploadCTA',
                icon: <CloudUpload color="primary" />,
                id: dialogIdentifier.leafletUpload
            },
            {
                path: paths.printMediaOverview,
                labelId: 'printMediaOverview.overview',
                icon: <ListAlt color="primary" />,
            },
            {
                path: paths.framing,
                params: '?canChangeCountry=true',
                labelId: 'nav.framingCTA',
                icon: <PhotoSizeSelectSmall color="primary" />,
            },
        ],
    },
    {
        headingLabelId: 'b.media',
        items: [
            {
                path: paths.home,
                action: spotUploadDialogToggle(true),
                labelId: 'spotUploadNavButton.spotUploadCTA',
                icon: <CloudUpload color="primary" />,
                id: dialogIdentifier.spotUpload
            },
            {
                path: paths.spotOverview,
                labelId: 'nav.mediumSpot',
                icon: <Radio color="primary" />,
            },
            {
                path: paths.spotHeaderData,
                labelId: 'nav.mediumVerification',
                icon: <PhotoSizeSelectSmall color="primary" />,
                id: menuItemIdentifier.mediumVerification
            },
        ],
    },
    {
        headingLabelId: 'nav.advertisement',
        items: [
            {
                path: paths.describeFrame,
                params: '?canChangeCountry=true',
                labelId: 'printMediaOverview.describeFrame',
                icon: <BurstMode color="primary" />,
            },
            {
                path: paths.describeSpot,
                labelId: 'media.describeMedia',
                icon: <PlayLesson color="primary" />,
            },
            {
                path: paths.advertisementsOverview,
                labelId: 'nav.overviewAdvertisements',
                icon: <ListAlt color="primary" />,
            },
            {
                path: paths.advertisementsSpotOverview,
                labelId: 'nav.overviewAdvertisementsSpot',
                icon: <PlayCircle color="primary" />,
            },
            {
                path: paths.advertisementsOverviewPromotions,
                labelId: 'nav.overviewAdvertisementsPromo',
                icon: <PictureInPicture color="primary" />,
            },
        ],
    },
    {
        headingLabelId: 'nav.quality',
        items: [
            {
                path: paths.reportedIssues,
                labelId: 'nav.qualityBrowse',
                icon: <BugReport color="primary" />,
            },
            {
                path: paths.myAdvertisements,
                labelId: 'nav.myAdvertisements',
                icon: <StarOutline color="primary" />,
            },
            ...(hasPersonalStatisticsRoles
                ? 
                [
                    {
                        path: paths.myTeamsAdvertisements,
                        labelId: 'nav.myTeamsAdvertisements',
                        icon: <ChangeHistory color="primary" />,
                    }
                ] : []),
            {
                path: paths.statistics,
                labelId: 'nav.overviewQuality',
                icon: <Timeline color="primary" />,
            },
            {
                path: paths.gtin,
                labelId: 'b.gtin',
                icon: <Dashboard color="primary" />,
            },
        ],
    },
    {
        headingLabelId: 'nav.masterData',
        items: [
            {
                path: paths.masterData,
                labelId: 'nav.masterData',
                icon: <AccountTreeTwoTone color="primary" />,
            }
        ],
    },
];
