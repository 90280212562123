import { useState } from 'react';
import { Explore } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { useFormatMessage } from 'utils/translate';
import { NavigationMenu } from './NavigationMenu';

export const NavigationMenuButton = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const translate = useFormatMessage();

    return (
        <>
            <IconButton
                color="primary"
                className="navigationMenuBtn"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }}
                title={translate({ id: 'nav.goTo' })}
            >
                <Explore />
            </IconButton>
            { anchorEl ? <NavigationMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)} /> : null }
        </>
    );
};
