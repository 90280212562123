/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import {Button} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {paths} from 'paths';
import {API_DICTIONARY_BY_ID, API_STATUSES} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {dictionary} from 'shared/models/dictionaries.model';
import {specialRoles} from 'shared/models/permissions.model';
import {getId} from 'utils/routing';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {useFormatMessage} from 'utils/translate';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import {PaperX} from 'components/PaperX';
import AgTable from 'components/AgTable/AgTable';
import {ActiveFlagCell, DictionaryElementCell} from 'components/AgTable/renderers';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {DictionarySelector} from 'components/Selectors';
import {ColDef} from "ag-grid-community/dist/lib/entities/colDef";

export const languages: string[] = ['bg', 'cs', 'de', 'de_AT', 'de_CH', 'fr', 'hr', 'it', 'hu', 'nl', 'pl', 'ro', 'sk', 'sl'];

const defaultLeafletLangPerCountry = {
    'AT': 'de',
    'BE': 'fr',
    'BG': 'bg',
    'CH': 'de',
    'CZ': 'cs',
    'DE': 'de',
    'HR': 'hr',
    'HU': 'hu',
    'LU': 'fr',
    'MD': 'ro',
    'PL': 'pl',
    'RO': 'ro',
    'SK': 'sk',
    'SI': 'sl'
};

export const getDefaultLeafletLanguageForCountry = (countryId: string): string => defaultLeafletLangPerCountry[countryId] || defaultLeafletLangPerCountry.DE;

const Dictionary = ({history}) => {
    const translate = useFormatMessage();
    const dictionaryAPIGet = useApi('get', {id: '', elements: [], translations: {}}, {errMsg: 'dictionary.err'});

    const [dictId, setDictId] = useState<string>('');

    const userRoles = useSelector((state: IRootState) => state.userPermissions.specialRoles);

    const isAllowed: boolean = userRoles.includes(specialRoles.PRODUCT_CATEGORY_MANAGEMENT);

    useEffect(() => {
        const dictionaryId: string = getId();
        if (dictionaryId) setDictId(dictionaryId);
    }, []);

    useEffect(() => {
        if (dictId) dictionaryAPIGet.call(API_DICTIONARY_BY_ID(dictId));
    }, [dictId]);

    const buildColumnDefs = (): ColDef[] => {
        const defs: ColDef[] = [
            { field: 'active', headerName: translate({ id: 'a.active' }), pinned: 'left', width: 80, cellClass: 'active-flag-cell', cellRenderer: (params => <ActiveFlagCell value={params.value} disabled={true}/>), floatingFilter: false, filter: null},
            { field: 'en', headerName: translate({ id: 'en' }), pinned: 'left', autoHeight: true, cellRenderer: (params) => <DictionaryElementCell dictEl={params.data} lang="en"/>, valueGetter: (params) => params.data.alternatives ? params.data.translations['en'] + params.data.alternatives['en'] : params.data.translations['en']}
        ];
        const otherLangs: ColDef[] = languages.map((lang) => ({
                field: lang,
                headerName: translate({ id: lang }),
                autoHeight: true,
                cellRenderer: (params) => <DictionaryElementCell dictEl={params.data} lang={lang}/>,
                valueGetter: (params) => params.data.alternatives ? params.data.translations[lang] + params.data.alternatives[lang] : params.data.translations[lang]
            }));
        return defs.concat(otherLangs);
    }

    const dictColumnDefs: ColDef[] = buildColumnDefs();

    const goToEdit = (dictElId: string, isAllowed: boolean) => {
        if (isAllowed) history.push(`${paths.dictionaryEdit}?id=${dictElId}`);
    }        

    const handleDictionaryChange = (dictionary: dictionary) => {if(dictionary) setDictId(dictionary.id)};

    return (<div className="viewRoot">
        <div className="viewport">
            <LoadingOverlay show={dictionaryAPIGet.status === API_STATUSES.PENDING}/>
            <div className="viewContainer _directionCol">
                <PaperX>
                    <div className="_halfWidth">
                        <DictionarySelector dictionaryId={dictId} onDictionaryChange={handleDictionaryChange}/>
                    </div>
                </PaperX>
                <PaperX className="_fullHeight _fullTable">
                    <AgTable
                        rowData={dictionaryAPIGet.data.elements}
                        defaultColDef={{
                            suppressMenu: true,
                            floatingFilter: true,
                            filter: 'agTextColumnFilter',
                            suppressMovable: true,
                            floatingFilterComponentParams: {suppressFilterButton:true}
                        }}
                        columnDefs={dictColumnDefs}
                        onRowClicked = {(rowData) => goToEdit(rowData.data.id, isAllowed)}
                    />
                </PaperX>
            </div>
        </div>
        <Footer
            actionsRight={
                <>
                    {isAllowed && <Link to={`${paths.dictionaryEdit}?dictionaryId=${dictId}`} className={!dictId ? 'disabledLink' : ''}>
                        <Button color="primary" variant="contained" size="small" disabled={!dictId}>
                            <FormattedMessage id='dictionary.new'/>
                        </Button>
                    </Link>}
                    <Link to={paths.masterData}>
                        <ButtonClose/>
                    </Link>
                </>
            }
        />
    </div>);
};

export default Dictionary;