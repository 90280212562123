import './AttributesDisplay.scss';
import {characteristicUI} from 'shared/models/product.model';

interface AttributesDisplayProps {
    data: characteristicUI[]
}
const AttributesDisplay = ({data}: AttributesDisplayProps) =>
        <div className="attributesDisplayRoot">
            {data.map(({name, id, value}) => <span title={name} key={id}>{value}</span>)}
        </div>;

export default AttributesDisplay;