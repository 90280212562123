/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {fetchUserPermissions} from 'shared/reducers/userPermissions';

const LoadUserPermissions = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserPermissions());
    }, []);

    return null;
};

export default LoadUserPermissions;