import {useDispatch} from 'react-redux';
import {CloudUpload} from '@mui/icons-material';
import {useFormatMessage} from 'utils/translate';
import {spotUploadDialogToggle} from 'shared/reducers/spotUpload';
import IconTile2 from 'components/IconTile2/IconTile2';

const SpotUploadNavButton = () => {
    const translate = useFormatMessage();
    const dispatch = useDispatch();
    return (<IconTile2 Icon={CloudUpload} title={translate({id: 'spotUploadNavButton.spotUploadCTA'})} onClick={() => dispatch(spotUploadDialogToggle(true))}/>);
};

export default SpotUploadNavButton;