import './MediumTypeSelector.scss';
import FormControl from '@mui/material/FormControl';
import {Checkbox, InputLabel, ListItemIcon, MenuItem, Select} from '@mui/material';
import {useFormatMessage} from 'utils/translate';
import {allMediumTypeTranslations, mediumType} from 'shared/models/leaflet.model';

type mediumTypeSelectorProps = {
    onMediaTypeChange: (mediumType: mediumType) => void,
    required?: boolean,
    mediumTypes?: mediumType[],
    value: mediumType[]
}

interface menuItem {
    label: string;
    value: string;
}

const MediumTypeSelector = ({onMediaTypeChange, required, mediumTypes, value}:mediumTypeSelectorProps) => {
    const translate = useFormatMessage();

    const types: mediumType[] = mediumTypes ? mediumTypes : Object.keys(allMediumTypeTranslations).map(k => mediumType[k]);

    const mediumTypesList: menuItem[] = types.map(translationKey => ({
        label: translate({id: allMediumTypeTranslations[translationKey]}),
        value: translationKey
    })).sort((a, b) => a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1);

    const handleMediumTypeChange = (e) => {
        onMediaTypeChange(e);
    }

    const renderSelection = (selectedMediumTypes: mediumType[]): string => selectedMediumTypes.map(selectedMediumType => translate({id: allMediumTypeTranslations[selectedMediumType]})).join(', ');

    const label: string = translate({id: 'b.mediumType'});

    return (
        <div>
            <FormControl>
                <InputLabel color="primary">{label}</InputLabel>
                <Select
                    label={label}
                    multiple
                    value={value}
                    onChange={handleMediumTypeChange}
                    renderValue={renderSelection}
                    className="mediumTypeSelector"
                >
                    {mediumTypesList.map((item: menuItem) => (
                        <MenuItem key={item.label} value={item.value}>
                            <ListItemIcon>
                                <Checkbox checked={value?.includes(item.value as mediumType)}/>
                            </ListItemIcon>
                            {translate({id: allMediumTypeTranslations[item.value]})}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default MediumTypeSelector;