import './PublicationOverview.scss';
import AgTable from 'components/AgTable/AgTable';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {useFormatMessage} from 'utils/translate';
import {publicationOverviewDataModel} from 'shared/models/leaflet.model';

interface PublicationOverviewProps {
  data: publicationOverviewDataModel[];
}

const PublicationOverview: React.FC<PublicationOverviewProps> = ({data}) => {
    const translate = useFormatMessage();

  const columnDefs: ColDef[] = [
    { field: 'banner', headerName: translate({id: 'b.banner'}), flex: 1},
    { field: 'pressTitle', headerName: translate({id: 'printMediaOverview.pressTitle'}), flex: 1},
    { field: 'date', headerName: translate({id: 'b.date'}), flex: 1},
    { field: 'pageNumber', headerName: translate({id: 'reportedFramesSummary.pageNo'}), width: 100},
    { field: 'numberOfPages', headerName: translate({id: 'printMediaOverview.numberOfPages'}), flex: 1}
];

return (
    <div className={`publicationOverviewRoot ${data.length ? 'isData' : ''}`.trim()}>
        <p className="publicationOverviewTitle">
            {translate({id: 'leafletUpload.publicationOverview' })}
        </p>
        {data.length ? (
            <AgTable
                defaultColDef={{
                    suppressMenu: false,
                    resizable: true,
                    sortable: true,
                }}
                rowData={data}
                columnDefs={columnDefs}
            />
        ) : (
            <p>{translate({id: 'headerData.publicationOverviewNoData'})}</p>
        )}
    </div>
  );
};

export default PublicationOverview;
