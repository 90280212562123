import {useState, useEffect} from 'react';
import {v4 as uuidv4} from 'uuid';

export const useTabId = () => {
  const [tabId, setTabId] = useState<string>('');

  useEffect(() => {
    const id: string = 'tabId';
    let tabId: string = sessionStorage.getItem(id);

    if (!tabId) {
      tabId = uuidv4();
      sessionStorage.setItem(id, tabId);
    }
    setTabId(tabId);
  }, []);
  
  return tabId;
}