/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import axios from 'axios';
import {responseValidation} from 'utils/responseValidation';
import {API_GET_ADVERTISEMENTS_TABLE} from 'config/api/constants';
import {advertisementsTableResponseModel, promotionTableRowBE} from 'shared/models/promotion.model';
import {validity} from 'shared/models/validity.model';
import {IRootState} from 'shared/reducers';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {PaperX} from 'components/PaperX';
import AdvertisementsTable from 'modules/Advertisement/AdvertisementsOverview/AdvertisementsTable';
import {localStorageColumnsKeys} from 'components/AgTable/AgTable';

interface advertisementBrowserSummaryProps {
    currentLeafletId: string,
    currentPage: number,
    selectedFrameId: string,
    onValidityForSelectedFrameIdChange: (validity: validity) => void,
    reload?: boolean
}

const AdvertisementBrowserSummary = (props: advertisementBrowserSummaryProps) => {
    const {currentLeafletId, currentPage, selectedFrameId, onValidityForSelectedFrameIdChange, reload} = props;

    const [promotionsAdvertisements, setPromotionsAdvertisements] = useState<advertisementsTableResponseModel>([]);
    const selectedLanguage = useSelector((state: IRootState) => state.userProfile.langData);
    const [busy, setBusy] = useState<boolean>(false);

    const cancelToken = useRef(null);

    const getAdvertisementsTable = (leafletId: string, pageNumber: number, lang: string) => {
        if (cancelToken.current) cancelToken.current();
        setBusy(true);
        axios.get<advertisementsTableResponseModel>(API_GET_ADVERTISEMENTS_TABLE(leafletId, pageNumber, lang), {
            cancelToken: new axios.CancelToken(
                cancel => (cancelToken.current = cancel)
            )})
            .then((response) => {
                if (responseValidation(response.data)) {
                    setPromotionsAdvertisements(response.data)
                } else {
                    setPromotionsAdvertisements([]);
                }                
            })
            .catch((e) => {
                if (!e.__CANCEL__) {
                    console.log(e);
                }
                setPromotionsAdvertisements([]);
            })
            .finally(() => setBusy(false))
    };

    useEffect(() => {
        if (selectedFrameId) {
            onValidityForSelectedFrameIdChange(getValidityForFrame(promotionsAdvertisements.filter((item) => item.frameId === selectedFrameId)[0]));
        }
    },[promotionsAdvertisements, selectedFrameId])

    useEffect(() => {
        getAdvertisementsTable(currentLeafletId, currentPage, selectedLanguage);
    }, [currentLeafletId, currentPage]);

    useEffect(() => {
        if (reload === true){
            getAdvertisementsTable(currentLeafletId, currentPage, selectedLanguage);
        }
    },[currentPage, currentLeafletId, reload])

    const buildPromotionsAdvertisementsLists = (): advertisementsTableResponseModel => {
        if (selectedFrameId) {
            return promotionsAdvertisements.filter((item) => item.frameId === selectedFrameId);
        }
        return promotionsAdvertisements;
    };

    const getValidityForFrame = (promotion: promotionTableRowBE): validity => {
        const validity: validity = {validFrom: null, validTo: null};
        if (promotion){
            validity.validFrom = promotion.frameValidFrom;
            validity.validTo = promotion.frameValidTo;
        }
        return validity;
    };

    return (
        <PaperX className="_fullHeight _fullTable">
            <LoadingOverlay show={busy}/>
            <AdvertisementsTable
                rowData={buildPromotionsAdvertisementsLists()}
                localStorageColumnsKey={localStorageColumnsKeys.advertisementBrowser}
            />
        </PaperX>
    );
}

export default AdvertisementBrowserSummary;