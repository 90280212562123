import {useSnackbar, VariantType, WithSnackbarProps} from 'notistack';
import {useFormatMessage} from 'utils/translate';

enum variantType {
    'default' = 'default',
    'error' = 'error',
    'info' = 'info',
    'success' = 'success',
    'warning' = 'warning'
}

let useSnackbarRef: WithSnackbarProps;
let translate: (id: {id: string}) => string;

export const SnackbarUtilsConfigurator: React.FC = () => {
    useSnackbarRef = useSnackbar();
    translate = useFormatMessage();
    return null;
  };

const SnackbarUtils = {
    showMessage(translationKey: string, variant: VariantType = variantType.default) {
      useSnackbarRef.enqueueSnackbar(translate({id: translationKey}), {variant});
    },
    error(translationKey: string) {
      this.showMessage(translationKey, variantType.error);
    },
    info(translationKey: string) {
      this.showMessage(translationKey, variantType.info);
    },
    success(translationKey: string) {
      this.showMessage(translationKey, variantType.success);
    },
    warning(translationKey: string) {
      this.showMessage(translationKey, variantType.warning);
    },
};

export default SnackbarUtils;