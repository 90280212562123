/* eslint-disable react-hooks/exhaustive-deps */
import './FileUpload.scss'

import {useCallback, useEffect, useRef, useState} from 'react';
import {Alert, Button, Paper} from '@mui/material';
import {CloudUpload} from '@mui/icons-material';
import {useSnackbar} from 'notistack';
import {useFormatMessage} from 'utils/translate';
import FileUploadOverlay from 'components/FileUpload/FileUploadOverlay/FileUploadOverlay';
import {mediumFileType, mediumType} from 'shared/models/leaflet.model';
import {
  supportedBroadcastOrPublicationFileTypes,
  supportedLeafletFileTypes,
  supportedPressAdvFileTypes,
  supportedSpotFileTypes,
} from './FileExtensions';

type FileUploadProps = {
  onFilesChange: (files: File[]) => void,
  selectedMediumFileType?: mediumFileType,
  selectedMediumType: mediumType,
  simple?: boolean,
  disabled?: boolean
};

const FileUpload = ({onFilesChange, selectedMediumFileType, selectedMediumType, simple, disabled}: FileUploadProps) => {
  const translate = useFormatMessage();
  const { enqueueSnackbar } = useSnackbar();
  const [isDragActive, setIsDragActive] = useState<boolean>(false);
  const fileInput = useRef(null);

  const isValidFileType = (file: File): boolean => {
    if (selectedMediumType === mediumType.LEAFLET) return supportedLeafletFileTypes.includes(file.type);
    else if (selectedMediumType === mediumType.BROADCAST_OVERVIEW || selectedMediumFileType === mediumFileType.PUBLICATION_OVERVIEW) return supportedBroadcastOrPublicationFileTypes.includes(file.type);
    else if (selectedMediumType === mediumType.SPOT) return supportedSpotFileTypes.includes(file.type);
    else return supportedPressAdvFileTypes.includes(file.type);
  };

  const acceptedExtensions = ():string => {
    let extensions: string = '.pdf';
    if (selectedMediumType === mediumType.PRESS_ADVERTISEMENT && selectedMediumFileType !== mediumFileType.PUBLICATION_OVERVIEW) {
      extensions += ', .jpg, .jpeg,';
    }
    else if (selectedMediumType === mediumType.SPOT) {
      extensions = '.mp3, .mp4';
    }
    else if (selectedMediumType === mediumType.BROADCAST_OVERVIEW || (selectedMediumFileType === mediumFileType.PUBLICATION_OVERVIEW && selectedMediumType !== mediumType.LEAFLET)) {
      extensions = '.csv';
    }
    return extensions;
  }

  const handleFiles = useCallback((files: FileList) => {
    const validFiles = Array.from(files).filter(file => isValidFileType(file));
    const invalidFiles = Array.from(files).filter(file => !isValidFileType(file));

    if (invalidFiles.length) {
      enqueueSnackbar(
        `${translate({id: 'fileUpload.formatWarning'}, {files: invalidFiles.map(f => f.name).join(', ')})}`,
        {
          variant: 'warning',
          persist: false
        });
    }

    if (!invalidFiles.length) {
      onFilesChange(validFiles);
    }
  }, [onFilesChange, enqueueSnackbar, translate]);

  const onChangeHandler = (event) => {
    if (event.target.files.length) {
      handleFiles(event.target.files);
    }
  };

  const onDragEnterHandler = useCallback((event) => {
    setIsDragActive(true);
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onDragOverHandler = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onDragLeaveHandler = (event) => {
    setIsDragActive(false);
    event.stopPropagation();
    event.preventDefault();
  };

  const onDropHandler = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();

    const dt = event.dataTransfer;
    const files = dt.files;
    handleFiles(files);

    setIsDragActive(false);
  }, [handleFiles]);

  const uploadBtnClickHandler = () => {
    fileInput.current.click();
  };

  useEffect(() => {
      if (disabled) {
          document.body.removeEventListener('dragenter', onDragEnterHandler);
          document.body.removeEventListener('dragover', onDragOverHandler);
          document.body.removeEventListener('drop', onDropHandler);
      } else {
          document.body.addEventListener('dragenter', onDragEnterHandler);
          document.body.addEventListener('dragover', onDragOverHandler);
          document.body.addEventListener('drop', onDropHandler);
      }

    return () => {
      document.body.removeEventListener('dragenter', onDragEnterHandler);
      document.body.removeEventListener('dragover', onDragOverHandler);
      document.body.removeEventListener('drop', onDropHandler);
    }
  }, [onDragEnterHandler, onDragOverHandler, onDropHandler, disabled]);

  return (
      <Paper elevation={0} className="_fullHeight fileUploadRoot">
          {/* LAYOUT */}
          <div className={`fileUploadContainer ${simple ? 'withoutBorder' : ''} ${disabled ? 'disabled' : ''}`}>
            {disabled ? <Alert severity="info">{translate({id: 'spotUpload.mandatoryFieldsInfo'})}</Alert> : null}
            <div className={`fileUploadContent ${disabled ? 'disabled' : ''}`}>
              {simple ? null : <div className="fileUploadIcon">
                <CloudUpload fontSize='inherit'/>
              </div>}
              {simple ? null : <p className="fileUploadText">
                {translate({id: 'fileUpload.uploadFilePlaceholder'})}
                <span className="fileUploadTextOr"><br/>{translate({id: 'fileUpload.or'})}</span>
              </p>}
              <Button
                onClick={uploadBtnClickHandler}
                variant="contained"
                color="primary"
                size="large"
                startIcon={simple ? <CloudUpload/> : null}
                disabled={disabled}
              >
                {translate({id: 'fileUpload.chooseFiles'})}
              </Button>
            </div>
          </div>

          {
              disabled ? null :
              <>
                  {/* HIDDEN INPUT */}
                  <input
                    className="inputFile"
                    type="file"
                    multiple
                    accept={acceptedExtensions()}
                    ref={fileInput}
                    onChange={onChangeHandler}
                  />

                  {/* DROPZONE */}
                  {isDragActive && <div className="dropZone" onDragLeave={onDragLeaveHandler} />}

                  {/* DRAG OVERLAY */}
                  <FileUploadOverlay show={isDragActive}/>
              </>
          }
      </Paper>
  )
}

export default FileUpload;
