/* eslint-disable react-hooks/exhaustive-deps */
import './MyTeamsAdvertisementsFilters.scss';

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {format, parseISO} from 'date-fns';
import {InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {frameFilters} from 'shared/models/advertisement.model';
import {bannersWithRetailerResponseModel, countryAndBanner} from 'shared/models/banner.model';
import {dateFormat} from 'shared/models/validity.model';
import {IRootState} from 'shared/reducers';
import {isBannerInCountry} from 'shared/reducers/bannersWithRetailer';
import useDebounce from 'utils/debounce';
import {useFormatMessage} from 'utils/translate';
import {getFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {ValiditySelector} from 'components/Selectors';
import {NumberInput} from 'components/Inputs';
import {valueTypes} from 'components/Inputs/NumberInput';
import {tryDate2String} from 'components/OverviewFilters/OverviewFilters';
import CountryBannerSelectorConnected from 'components/Selectors/CountryBannerSelector/CountryBannerSelectorConnected';
import UserFilterField from './UserFilterField/UserFilterField';

interface AdvOverviewPromotionsFiltersProps {
    onFilterChange: (filters: frameFilters) => void
}

const sanitizeFrameFilters = (filters: frameFilters, bannersData: bannersWithRetailerResponseModel): frameFilters => {
    const saneFilters: frameFilters = {};
    if (filters.search && filters.search.length > 2) saneFilters.search = filters.search;
    if (filters.countryId) saneFilters.countryId = filters.countryId;
    if (filters.createdBy) saneFilters.createdBy = filters.createdBy;
    if (filters.lang) saneFilters.lang = filters.lang;
    if (filters.bannerId && isBannerInCountry(filters.bannerId, filters.countryId, bannersData)) saneFilters.bannerId = filters.bannerId;
    if (filters.validFrom) saneFilters.validFrom = filters.validFrom;
    if (filters.validTo) saneFilters.validTo = filters.validTo;
    if (filters.promotionalPrice) {
        saneFilters.promotionalPrice = filters.promotionalPrice;
    }
    return Object.keys(saneFilters).length === 0 ? null : saneFilters;
}

const storageKey = 'filters_advertisementsOverviewPromotionsWithCreator';

const buildFiltersTemplate = (country: string, lang: string): frameFilters => {
    return {
        lang,
        search: '',
        createdBy: null,
        countryId: country,
        bannerId: '',
        validFrom: null,
        validTo: null,
        promotionalPrice: null
    }
};

const MyTeamsAdvertisementsFilters = ({onFilterChange}: AdvOverviewPromotionsFiltersProps) => {
    const translate = useFormatMessage();

    const bannersData = useSelector((state: IRootState) => state.bannersWithRetailer.data);
    const userProfile = useSelector((state: IRootState) => state.userProfile);

    const [filters, setFilters] = useState<frameFilters>(null);
    const [dbncdFilters, setDbncdFilters] = useDebounce(filters, 500);
    const [stringifiedFilters, setStringifiedFilters] = useState<string>(''); // sometimes on filter change sanitize function return identical object (ex. when search string is too short), we dont want to trigger onFilterChange then

    const handleSearchInputChange = (val: string) => setFilters({...filters, search: val});

    useEffect(() => {
        if (filters?.countryId !== userProfile?.countryMarket?.preferredCountry) {
            setFilters({...filters, countryId: userProfile?.countryMarket?.preferredCountry});
        }
    }, [userProfile]);

    const handleCountryBannerChange = (countryBanner: countryAndBanner) => setFilters({
        ...filters,
        bannerId: countryBanner.banner || null,
        countryId: countryBanner.country
    });

    const handleDateFromChange = (validFrom: Date) => {
        const newDate: string = tryDate2String(validFrom);
        setFilters({...filters, validFrom: newDate ? format(validFrom, dateFormat) : ''});
    };

    const handleDateToChange = (validTo: Date) => {
        const newDate: string = tryDate2String(validTo);
        setFilters({...filters, validTo: newDate ? format(validTo, dateFormat) : ''});
    };

    const handlePromoPriceChange = (price: number, error: boolean) => setFilters({...filters, promotionalPrice: error ? null : price, currency: userProfile.currency});

    const handleUserFilterChange = (username: string) => setFilters({
        ...filters,
        createdBy: username
    });

    useEffect(() => {
        const storedFilters: frameFilters = JSON.parse(getFromLocalStorage(storageKey));
        if (storedFilters) {
            storedFilters.lang = userProfile.langData;
            storedFilters.countryId = userProfile.countryMarket.preferredCountry;
            setFilters(storedFilters);
        } else {
            setFilters(buildFiltersTemplate(userProfile.countryMarket.preferredCountry, userProfile.langData));
        }
    }, []);

    // watch filters
    useEffect(() => setDbncdFilters(filters), [filters]);
    useEffect(() => {
        if (dbncdFilters) {
            const saneFilters = sanitizeFrameFilters(dbncdFilters, bannersData);
            const stringifiedSaneFilters = JSON.stringify(saneFilters);
            if (stringifiedSaneFilters !== stringifiedFilters) {
                setStringifiedFilters(stringifiedSaneFilters);
                onFilterChange(saneFilters);
                storeInLocalStorage(storageKey, stringifiedSaneFilters);
            }
        }
    }, [dbncdFilters]);

    return !filters ? null :
        <div className="myTeamsAdvertisementsFiltersContainers">
            <div className="row">
                <TextField onChange={(e) => handleSearchInputChange(e.target.value)}
                           value={filters.search || ''}
                           fullWidth
                           label={translate({id: 'b.product'})}
                           InputProps={{
                               startAdornment: (
                                   <InputAdornment position="start">
                                       <SearchIcon/>
                                   </InputAdornment>
                               )}}
                />
                <CountryBannerSelectorConnected bannerId={filters.bannerId}
                                                onCountryBannerChange={(countryBanner) => handleCountryBannerChange(countryBanner)}/>

                <ValiditySelector id={1}
                                  validityFrom={filters.validFrom ? parseISO(filters.validFrom) : null}
                                  validityTo={filters.validTo ? parseISO(filters.validTo) : null}
                                  labelFrom={translate({id: 'b.validFrom'})}
                                  labelTo={translate({id: 'b.validTo'})}
                                  onValidityFromChange={(id, date) => handleDateFromChange(date)}
                                  onValidityToChange={(id, date) => handleDateToChange(date)}/>
                <NumberInput valueType={valueTypes.float}
                             onChange={(propIdentifier, value, error) => handlePromoPriceChange(value, error)}
                             label="b.promotionalPrice"
                             value={filters.promotionalPrice || null}
                             propIdentifier={'promoPrice'}
                             endAdornment={userProfile.currency} />
                <div>
                    <UserFilterField countryId={filters.countryId}
                                     onUserFilterFieldChange={(username) => handleUserFilterChange(username)}/>
                </div>
            </div>
        </div>;
};

export default MyTeamsAdvertisementsFilters;