/* eslint-disable react-hooks/exhaustive-deps */

import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {Button, Checkbox} from '@mui/material';
import {paths} from 'paths';
import {API_LITERALS, API_GET_LITERAL, API_STATUSES, API_LITERALS_SEARCH} from 'config/api/constants';
import {IRootState} from 'shared/reducers';
import {basic} from 'shared/models/_common.model';
import {literalObject} from 'shared/models/literal.model';
import {dictionaryTranslationRow, translations} from 'shared/models/dictionaries.model';
import {specialRoles} from 'shared/models/permissions.model';
import {useFormatMessage} from 'utils/translate';
import {useApi} from 'utils/axiosHooks/axiosHooks';
import {getId} from 'utils/routing';
import {PaperX} from 'components/PaperX';
import Footer from 'components/Footer';
import ButtonClose from 'components/Buttons/ButtonClose';
import AgTable from 'components/AgTable/AgTable';
import Search from 'components/Search/Search';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {languages} from 'modules/Dictionaries/Dictionary';

const literalTemplate: literalObject = {
    id: '',
    active: false,
    translations: {en: ''}
};

const slimLiteralTemplate: basic = {id: '', name: ''};

export const dictElemColumnDefs = (translate: Function, isAllowed: boolean): ColDef[] => [
    {field: 'lang', headerName: translate({id: 'dictionaryElement.lang'}), cellRenderer: (params) => translate({id: params.value})},
    {field: 'trans', headerName: translate({id: 'dictionaryElement.trans'}), flex: 1, editable: isAllowed ? true : false,
        tooltipValueGetter: () => translate({id: 'dictionaryElement.info'}),
        cellClass: (params) => params.data.lang === 'en' && !params.value ? 'required-cell' : ''
    }
];
export const dictionary2arrayX = (element: translations): dictionaryTranslationRow[] => ['en'].concat(languages).map((lang) => ({lang, trans: element[lang] || ''}));

export const array2dictionary = (dictionaryArray: dictionaryTranslationRow[]): translations => {
    const translations: translations = {en: ''};
    dictionaryArray.forEach((item) => {
        translations[item.lang] = item.trans;
    });
    return translations;
}

const Literal = () => {
    const translate = useFormatMessage();
    const literalsApiGet = useApi('get', {id: null}, {errMsg: 'literals.err'});
    const literalsApiPut = useApi('put', {id: null}, {succMsg: 'literals.succUpdate', errMsg: 'literals.errUpdate'});

    const [selectedLiteralSlim, setSelectedLiteralSlim] = useState<basic>(slimLiteralTemplate);
    const [selectedLiteral, setSelectedLiteral] = useState<literalObject>(literalTemplate);
    const [selectedLiteralData, setSelectedLiteralData] = useState<dictionaryTranslationRow[]>([]);

    const [modified, setModified] = useState(false);
    const [lock4edit, setLock4edit] = useState<boolean>(false);

    const userRoles = useSelector((state: IRootState) => state.userPermissions.specialRoles);

    const isAllowed: boolean = userRoles.includes(specialRoles.PRODUCT_CATEGORY_MANAGEMENT);

    useEffect(() => {
        const literalId: string = getId();
        if (literalId) {
            setSelectedLiteralSlim({id: literalId, name: ''});
        }
    }, []);

    useEffect(() => {
        setModified(false);
        if (selectedLiteralSlim.id) {
            literalsApiGet.call(API_GET_LITERAL(selectedLiteralSlim.id));
        }
    }, [selectedLiteralSlim]);

    useEffect(() => {
        if (literalsApiGet.status === API_STATUSES.IDLE) {
            const data: literalObject = literalsApiGet.data;
            setSelectedLiteral(data);
            setSelectedLiteralData(dictionary2arrayX(data.translations));
        }
    }, [literalsApiGet.status]);

    useEffect(() => {
        if (literalsApiPut.status === API_STATUSES.IDLE) {
            setModified(false);
        }
    }, [literalsApiPut.status])

    const handleSelectionChange = (val: basic) => setSelectedLiteralSlim(val || slimLiteralTemplate);

    const handleCellChange = (event) => {
        const newData = [...selectedLiteralData];
        newData[event.rowIndex] = event.data;
        setSelectedLiteralData(newData);
        setModified(true);
    };

    const handleUpdateClick = () => {
        const saveObject: literalObject = {
            id: selectedLiteral.id,
            active: selectedLiteral.active,
            translations: {en: ''}
        };
        selectedLiteralData.forEach((item) => {
            saveObject.translations[item.lang] = item.trans;
        });
        literalsApiPut.call(`${API_LITERALS}/${selectedLiteral.id}`, saveObject);
    };

    const handleActiveChange = (val: boolean) => {
        const newLiteral: literalObject = {...selectedLiteral};
        newLiteral.active = val;
        setSelectedLiteral(newLiteral);
        setModified(true);
    };

    const cantModify = (): boolean => lock4edit || !modified || selectedLiteralData.find((item) => item.lang === 'en' && item.trans === '') !== undefined;

    const showLoading = (): boolean => literalsApiGet.status === API_STATUSES.PENDING || literalsApiPut.status === API_STATUSES.PENDING;

    return (
        <div className="viewRoot franchiseOwnerRoot">
            <div className="viewport">
                <LoadingOverlay show={showLoading()}/>
                <div className="viewContainer _directionCol">
                    <PaperX>
                        <div className="_halfWidth">
                            <Search onSelectionChange={handleSelectionChange} searchUrl={API_LITERALS_SEARCH} label="literals.label"/>
                        </div>
                    </PaperX>
                    {selectedLiteralSlim.id && <PaperX className="weldBottom">
                        <FormattedMessage id="a.active"/><Checkbox checked={selectedLiteral.active} disabled={!isAllowed} color="primary" onChange={(e) => handleActiveChange(e.target.checked)}/>
                    </PaperX>}
                    {selectedLiteralSlim.id && <PaperX className="_fullHeight _fullTable weldTop">
                        <AgTable
                            rowData={selectedLiteralData}
                            defaultColDef={{
                                suppressMenu: true,
                                suppressMovable: true
                            }}
                            columnDefs={dictElemColumnDefs(translate, isAllowed)}
                            onCellValueChanged={handleCellChange}
                            onCellEditingStarted={() => setLock4edit(true)}
                            onCellEditingStopped={() => setLock4edit(false)}
                            tooltipShowDelay={1000}
                        />
                    </PaperX>}
                </div>
            </div>
            <Footer
                actionsRight={
                    <>
                        {isAllowed && <>
                            <Button variant="contained" color="primary" onClick={handleUpdateClick} disabled={cantModify()}>
                                <FormattedMessage id="a.save"/>
                            </Button>
                            <Link to={paths.literalCreate}>
                                <Button variant="contained" color="primary">
                                    <FormattedMessage id="literals.new"/>
                                </Button>
                            </Link></>}
                        <Link to={paths.masterData}>
                            <ButtonClose/>
                        </Link>
                    </>
                }
            />
        </div>
    );
};

export default Literal;