import {ACTION_TYPES as SPOT_ACTION_TYPES} from './leafletUpload';
export const ACTION_TYPES ={
    LEAFLET_UPLOAD_DIALOG_TOGGLE: 'LEAFLET_UPLOAD_DIALOG_TOGGLE'
};

export const leafletUploadDialogToggle = (dialogOpen: boolean) => ({
    type: ACTION_TYPES.LEAFLET_UPLOAD_DIALOG_TOGGLE,
    payload: {dialogOpen}
});

type leafletUploadDialogState = {
    dialogOpen: boolean
}

const initialState: leafletUploadDialogState = {
    dialogOpen: false
};

export type LeafletUploadState = Readonly<typeof initialState>;

const leafletUploadReducer = (state: LeafletUploadState = initialState, action): LeafletUploadState => {
    switch (action.type) {
        case ACTION_TYPES.LEAFLET_UPLOAD_DIALOG_TOGGLE:
            return {...state, dialogOpen: action.payload.dialogOpen};
        case SPOT_ACTION_TYPES.LEAFLET_UPLOAD_DIALOG_TOGGLE:
            if (!action.payload.dialogOpen) return state;
            return {...state, dialogOpen: false};
        default:
            return state;
    }
};

export default leafletUploadReducer;