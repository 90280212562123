import axios from 'axios';
import {errorsNotToLog, logFailure} from './failureService';
import SnackbarUtils from 'utils/snackbarUtils';

export const setAxiosInterceptors = (loginFn, refreshFn) => {
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response && (error.response.status === 401)) {
            return refreshFn().then((token) => {
                if (token) {
                    updateJWTTokenOnAxios(token);
                    let { method, data, url, params } = error.config;
                    if (data) {
                        try {
                            data = JSON.parse(data);
                        } catch (e) {}
                    }
                    return axios.request({method, data, url, params}); // retry after obtaining new token
                } else {
                    loginFn();
                }
            })
                .catch((e) => {
                    console.log(e)
                    loginFn();
                }) ;
        } else if (error.response && (error.response.status === 403)) {
            SnackbarUtils.error('a.errorNoAccess');
        } else if (error.response && (errorsNotToLog.indexOf(error.response.status) < 0)) {
            logFailure(error);
        }
        return Promise.reject(error);
    });

    axios.interceptors.request.use((response) => {
        return response;
    },  (error) => {
        return Promise.reject(error);
    });
};

export const updateJWTTokenOnAxios = (newToken: string) => axios.defaults.headers.common.Authorization = `Bearer ${newToken}`;