/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {Autocomplete, TextField} from '@mui/material';
import axios from 'axios';
import {AttributeError, AttributeLoading} from 'components/Attribute/AttributeHelpers';
import {API_GET_ADVERTISEMENT_CREATORS} from 'config/api/constants';
import {responseValidation} from 'utils/responseValidation';
import {useFormatMessage} from 'utils/translate';

interface UserFilterFieldProps {
  countryId: string | null;
  onUserFilterFieldChange: (username: string | null) => void
}

const UserFilterField = ({countryId, onUserFilterFieldChange}: UserFilterFieldProps) => {
    const translate = useFormatMessage();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [filteredUsers, setFilteredUsers] = useState<string[]>([]);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);

    useEffect(() => {
        if (!countryId) return;
        setIsLoading(true);
        setIsError(false);
        setSelectedUser(null);
        onUserFilterFieldChange(null);
        axios.get<string[]>(API_GET_ADVERTISEMENT_CREATORS(countryId))
            .then((resp) => {
                if (responseValidation(resp.data)) {
                    setFilteredUsers(resp.data);
                } else {
                    setIsError(true);
                    setFilteredUsers([]);
                }
            })
            .catch((err) => {
                console.error(`Error fetching user lists: ${err}`);
                setIsError(true);
                setFilteredUsers([]);
            })
            .finally(() => setIsLoading(false));
      }, [countryId]);

    const handleAutocompleteChange = (value: string) => {
        setSelectedUser(value);
        onUserFilterFieldChange(value);
    };

    if (isError) return <AttributeError />;
    if (isLoading) return <AttributeLoading />;

    return (
        <div className="userFilterFieldRoot">
            <Autocomplete disabled={!countryId || isLoading}
                          value={selectedUser}
                          options={filteredUsers}
                          onChange={(e, value) => handleAutocompleteChange(value)}
                          renderInput={(params) => <TextField {...params} label={translate({id: 'printMediaOverview.createdBy'})} />}
            />
        </div>

        
    );
};

export default UserFilterField;
