import './AgTable.scss';
import 'ag-grid-enterprise';

import {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {AgGridReact} from 'ag-grid-react';
import {GridApi} from 'ag-grid-community/dist/lib/gridApi';
import {ColDef} from 'ag-grid-community/dist/lib/entities/colDef';
import {IRootState} from 'shared/reducers';
import {getObjectFromLocalStorage, removeFromLocalStorage, storeInLocalStorage} from 'utils/storageUtils';
import {useFormatMessage} from 'utils/translate';
import {AG_GRID_LOCALE_DE, AG_GRID_LOCALE_PL} from './locales';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';

console.warn('Context menu, floating filters and column menu disabled globally, undo where needed');
console.warn('Make sure that each columns has "field" property defined when using "localStorageColumnsKey"');

const viableEventSources = new Set (['autosizeColumns', 'contextMenu', 'toolPanelUi', 'uiColumnDragged']);

const pageSizeOptions: number[] = [100, 200, 300, 500, 1000, 2000, 5000];

const AgTable = (props) => {
    const translate = useFormatMessage();
    const [renderGrid, setRenderGrid] = useState<boolean>(true); // used to redraw grid after columns 'hard reset'
    const [paginationPageSize, setPaginationPageSize] = useState<number>(pageSizeOptions[0]);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const pageSizeSelectorRef = useRef<HTMLDivElement | null>(null);

    const lang = useSelector((state: IRootState) => state.userProfile.langInterface);

    useEffect(() => {
        const paginationPanel: HTMLDivElement | null = document.querySelector('.ag-paging-panel');
        if (paginationPanel && pageSizeSelectorRef.current) {
            paginationPanel.insertBefore(pageSizeSelectorRef.current, paginationPanel.lastChild);
        }
    }, [gridApi]);

    useEffect(() => {
        if (!renderGrid) setRenderGrid(true);
    }, [renderGrid]);

    const handleColumnChange = (event) => {
        if (!props.localStorageColumnsKey) {
            return;
        }
        if ((event.finished !== false && viableEventSources.has(event.source)) || event.type === 'sortChanged') {
            storeInLocalStorage(props.localStorageColumnsKey, JSON.stringify(event.columnApi.getColumnState()));
        }
    }

    const handlePageSizeChange = (event)  => {
        const newPageSize = event.target.value as number;
        setPaginationPageSize(newPageSize);
        if (gridApi) gridApi.paginationSetPageSize(newPageSize);
    };

    const onGridReady = (params) => setGridApi(params.api);

    const getColumnDefs = (): ColDef[] => {
        if (props.localStorageColumnsKey) {
            const storedColumnsState: ColDef[] = getObjectFromLocalStorage(props.localStorageColumnsKey);
            if (storedColumnsState) {
                // applying stored properties
                const newColumnDefs: ColDef[] = props.columnDefs.map((item) => {
                    const storedColumn = storedColumnsState.find((storedItem) => storedItem.colId === item.field);
                    return storedColumn ? {...item, ...storedColumn} : item;
                })
                // reordering columns
                const orderedColumns: ColDef[] = [];
                storedColumnsState.forEach((itemOrderBy) => orderedColumns.push(newColumnDefs.find((itemToFind) => itemOrderBy.colId === itemToFind.field)));
                return orderedColumns;
            }
            return props.columnDefs;
        }
        return props.columnDefs;
    };

    const getMainMenuItems = (params) => {
        if (props.localStorageColumnsKey) {
            // replacing original reset columns with custom that would clear localstorage and reset everything
            const newDefaultItems = params.defaultItems.filter((item) => item !== 'resetColumns');
            newDefaultItems.push({
                name: translate({id: 'agGrid.reset'}),
                action: () => {
                    removeFromLocalStorage(props.localStorageColumnsKey)
                    setRenderGrid(false);
                }})
            return newDefaultItems;
        }
        return params.defaultItems;
    };

    const getLocale = () => {
        if (lang === 'pl') return AG_GRID_LOCALE_PL;
        if (lang === 'de') return AG_GRID_LOCALE_DE;
        return null;
    };

    return (
        <div className="ag-theme-material ag-table-container">
            <div className="ag-table-wrapper">
                {renderGrid
                ?
                    <>
                        <AgGridReact suppressContextMenu={true}
                                                onGridReady={onGridReady}
                                                suppressMenu={true}
                                                floatingFilterComponentParams={{suppressFilterButton: true}}
                                                pagination={props?.pagination}
                                                paginationPageSize={props?.pagination && paginationPageSize}
                                                onColumnResized={handleColumnChange}
                                                onColumnMoved={handleColumnChange}
                                                onColumnVisible={handleColumnChange}
                                                onColumnPinned={handleColumnChange}
                                                onSortChanged={handleColumnChange}
                                                getMainMenuItems={getMainMenuItems}
                                                {...props}
                                                columnDefs={getColumnDefs()}
                                                localeText={getLocale()}
                        />
                        {props?.pagination && <div ref={pageSizeSelectorRef} className="pageSizeContainer">
                            <FormControl variant="outlined" size="small">
                                <InputLabel>{translate({id: 'agGrid.pageSize'})}</InputLabel>
                                <Select value={paginationPageSize}
                                        onChange={handlePageSizeChange}
                                        label={translate({id: 'agGrid.pageSize'})}
                                >
                                    {pageSizeOptions.map((size) => (
                                        <MenuItem key={size} value={size}>
                                            {size}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>}
                    </>
                :
                <AgGridReact/>}
            </div>
        </div>
    );
};

const techFields: string[] = ['selectAll1']; // if column is not provided with field property it will be generated automatically by AgGrid, making it incompatible with any previously saved user's table cfg

//default column cfg for table with column search
export const defaultColCfgWithSearch = {
    sortable: true,
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    floatingFilterComponentParams: {suppressFilterButton:true}
};

export const selectAllColDef : ColDef = {
    checkboxSelection: true,
    field: techFields[0],
    floatingFilter: false,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerClass: '_selectAllHeader',
    resizable: false,
    suppressMenu: true,
    width: 40
};

// moved keys definitions to one place as it is important not to use same key for different configs
export const localStorageColumnsKeys = {
    advertisementsOverview: 'colDef_advertisementsOverview_v6',
    advertisementsSpotsOverview: 'colDef_advertisementsSpotsOverview_v3',
    advertisementsOverviewPromotions: 'colDef_advertisementsOverviewPromotions',
    advertisementsOverviewPromotionsWithCreator: 'colDef_advertisementsOverviewPromotionsWithCreator',
    advertisementBrowser: 'colDef_advertisementBrowser_v8',
    advertisementMedia: 'colDef_advertisementMedia_v2',
    advertisingAreaSummary: 'colDef_advertisingAreaSummary',
    BPCOList: 'colDef_BPCOList_v4',
    BPCODescriptionList: 'colDef_BPCODescriptionList',
    branchesList: 'colDef_branchesList_v2',
    framesOverview: 'colDef_framesOverview_v2',
    franchiseOwnersList: 'colDef_franchiseOwnersList',
    printMediaOverview: 'colDef_printMediaOverview_V2',
    productsList: 'colDef_productsList_v6',
    productsListSearchResults: 'colDef_productsListSearchResults_v9',
    productLineList: 'colDef_productLineList_v2',
    reportedFramesSummary: 'colDef_reportedFramesSummary_v3',
    brandsList: 'colDef_brandsList_v2',
    gtinDetails1: 'colDef_gtinDetails1',
    gtinDetails2: 'colDef_gtinDetails2',
    gtinQsDashboardWithoutGtins: 'colDef_gtinQsDashboardWithoutGtins',
    gtinQsDashboardDuplicatedGtins: 'colDef_gtinQsDashboardDuplicatedGtins_v2',
    productDescriptionList: 'colDef_productDescriptionList_v2',
    productBulkChangeTable: 'colDef_productBulkChange_v2',
    retailerHQList: 'colDef_retailerHQList',
    spotsOverview: 'colDef_spotsOverview_v2',
    promotionsTable: 'colDef_promotionsTable_v3',
    reportedProductsList: 'colDef_reportedProducts',
    productBulkDescriptionChangeTable: 'productBulkDescriptionChangeTable'
} as const
export type localStorageColumnsKey = typeof localStorageColumnsKeys[keyof typeof localStorageColumnsKeys];
export default AgTable;