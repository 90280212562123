/* eslint-disable react-hooks/exhaustive-deps */
import 'components/Snackbar/SnackbarStyles.scss';

import {useEffect, useState} from 'react';
import { IntlProvider } from 'react-intl';
import {Redirect, Route} from 'react-router';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {useSelector} from 'react-redux';
import { IRootState } from 'shared/reducers';
import { SnackbarProvider } from 'notistack';
import {SnackbarUtilsConfigurator} from 'utils/snackbarUtils';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { muiMarkantTheme } from 'muiMarkantTheme';
import { paths } from 'paths';
import lang from 'i18n';
import {protectedRoutesModel} from 'shared/models/routing.model';
import {specialRoles} from 'shared/models/permissions.model';
import { calendarLocales } from 'utils/calendar';
import LoadUserProfile from 'utils/LoadUserProfile';
import LoadUserPermissions from 'utils/LoadUserPermissions';
import SnackbarDismissAction from 'components/Snackbar/SnackbarDismissAction';
import StoragePile from 'components/StoragePile/StoragePile';
import {TitleSetter} from 'components/TitleSetter';
import Dashboard from 'modules/Dashboard';
import { TestView, TestView2 } from 'modules/LocalStoragePoC';
import Framing from './modules/Frames/Framing';
import {HeaderData} from 'modules/Leaflet/HeaderData';
import LeafletBrowserFullScreen from 'modules/Leaflet/LeafletBrowserFullScreen/LeafletBrowserFullScreen';
import {PrintMediaOverview} from 'modules/Leaflet/PrintMediaOverview';
import MasterData from 'modules/MasterData/MasterData';
import {ProductsList, ProductCloneView, ProductNewView} from 'modules/MasterData/Product';
import KeyHierarchy from 'modules/MasterData/KeyHierarchy';
import LeafletUploadDialog from 'modules/Leaflet/LeafletUpload/LeafletUploadDialog';
import SpotUploadDialog from 'modules/Medium/Spot/SpotUpload/SpotUploadDialog';
import {BranchesList, BranchDetails} from 'modules/MasterData/BranchesList';
import {AdvertisingArea, AdvertisingAreasList} from 'modules/MasterData/AdvertisingArea';
import {FranchiseOwner, FranchiseOwnersList} from 'modules/MasterData/FranchiseOwner';
import {Dictionary, DictionaryElement} from 'modules/Dictionaries';
import {LiteralCreate, Literal} from 'modules/Dictionaries/Literals';
import {AttributesBrowser, AttributeCreate} from 'modules/Dictionaries/Attributes';
import {AdvertisementBrowser, AdvertisementBrowserMedia, AdvertisementsOverview, AdvertisementSpotOverview, FrameDescriptionLeafletBrowser, FrameDescription} from 'modules/Advertisement';
import {BrandEditView, BrandNewView, Brands, StructuredBrandsEditView, StructuredBrandNewView} from 'modules/MasterData/Brand';
import {BPCODescriptionView, BPCODescriptionList, BPCOList} from 'modules/MasterData/BPCO';
import {BPCONewView, BPCOEditView} from 'modules/MasterData/BPCO/BPCODetails/index';
import ProductLine from 'modules/MasterData/ProductLine/ProductLine';
import ProductLineList from 'modules/MasterData/ProductLine/ProductLineList';
import Statistics from 'modules/Quality/Statistics/Statistics';
import {
  Category,
  CategoryAttributes,
  CategoriesHierarchy,
  CategoryDetails
} from 'modules/MasterData/Category';
import BrickView from 'modules/MasterData/Category/Brick/BrickView';
import ReportedIssues from 'modules/Quality/ReportedIssues/ReportedIssues';
import ReportedFramesBrowser from 'modules/Quality/ReportedFrames/ReportedFramesBrowser/ReportedFramesBrowser';
import ReportedAdvertisementsBrowser from 'modules/Quality/ReportedAdvertisements/ReportedAdvertisementsBrowser/ReportedAdvertisementsBrowser';
import FixReportedFrames from 'modules/Quality/ReportedFrames/FixReportedFrames/FixReportedFrames';
import ProductEditView from 'modules/MasterData/Product/ProductDetails/ProductEditView';
import AdvOverviewPromotions from 'modules/Advertisement/AdvertisementsOverviewPromotions/AdvOverviewPromotions';
import MediaDescription from 'modules/Advertisement/MediaDescription/MediaDescription';
import ProductDescriptionListView from 'modules/MasterData/ProductDescription/ProductDescriptionListView';
import ProductDescriptionView from 'modules/MasterData/ProductDescription/ProductDescriptionView';
import {ProductDescriptionReplacement} from 'modules/MasterData/ProductDescription/ProductDescriptionReplacement';
import GtinQsDashboard from 'modules/Quality/GtinQsDashboard/GtinQsDashboard';
import {MyAdvertisementsView, MyTeamsAdvertisementsView} from 'modules/Quality';
import OptionsDialog from 'modules/Options/OptionsDialog';
import {RetailerHQDetails, RetailerHQList} from 'modules/MasterData/RetailerHQ';
import {RadioSpotHeaderData, SpotHeaderData, SpotOverview, TvSpotHeaderData} from 'modules/Medium/Spot';
import {SupplierDetails, Suppliers} from "./modules/MasterData/Supplier";
import ProductBulkChange from 'modules/MasterData/Product/ProductBulkChange/ProductBulkChange';
import {ProductBulkSupplier} from 'modules/MasterData/Product/ProductBulkSupplier';
import {ProductBulkDescription} from 'modules/MasterData/Product/ProductBulkDescription';
import {LoadingOverlay} from 'components/LoadingOverlay';
import {useTabId} from 'utils/useTabId';

const createProtectedRoutes = (routes: protectedRoutesModel[], isLoading: boolean, hasRole: Function) => {
  return routes.map(({path, component: Component, role}) => (
    <Route key={path}
           path={path}
           render={(props) => {
             if (isLoading) return <LoadingOverlay show={isLoading} />;
             return hasRole(role) ? <Component {...props}/> : <Redirect to={paths.home}/>;
           }}
    />
  ));
};

const protectedRoutes: protectedRoutesModel[] = [
  {path: paths.productBulkChange, component: ProductBulkChange, role: specialRoles.PRODUCT_MANAGEMENT},
  {path: paths.productBulkDescription, component: ProductBulkDescription, role: specialRoles.PRODUCT_MANAGEMENT},
  {path: paths.productBulkSupplier, component: ProductBulkSupplier, role: specialRoles.PRODUCT_MANAGEMENT},
  {path: paths.productDescriptionReplace, component: ProductDescriptionReplacement, role: specialRoles.PRODUCT_MANAGEMENT},
  {path: paths.productLine, component: ProductLine, role: specialRoles.PRODUCT_MANAGEMENT},
  {path: paths.dictionaryEdit, component: DictionaryElement, role: specialRoles.PRODUCT_CATEGORY_MANAGEMENT},
  {path: paths.literalCreate, component: LiteralCreate, role: specialRoles.PRODUCT_CATEGORY_MANAGEMENT},
  {path: paths.advertisingArea, component: AdvertisingArea, role: specialRoles.RETAILER_HIERARCHY_MANAGEMENT},
  {path: paths.branchDetails, component: BranchDetails, role: specialRoles.RETAILER_HIERARCHY_MANAGEMENT},
  {path: paths.franchiseOwner, component: FranchiseOwner, role: specialRoles.RETAILER_HIERARCHY_MANAGEMENT},
  {path: paths.retailerHQDetails, component: RetailerHQDetails, role: specialRoles.RETAILER_HIERARCHY_MANAGEMENT},
  {path: paths.myTeamsAdvertisements, component: MyTeamsAdvertisementsView, role: specialRoles.PERSONAL_STATISTICS}
];

function App() {
  const userInterfaceLang = useSelector((state: IRootState) => state.userProfile.langInterface);
  const userPermissions = useSelector((state: IRootState) => state.userPermissions);
  const tabId = useTabId();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const hasRole = (role: specialRoles): boolean => userPermissions.specialRoles.includes(role);

  useEffect(() => userPermissions.username && setIsLoading(false), [userPermissions]);

  if (process.env.NODE_ENV === "development" && tabId) console.info(`unique tabId for this session: ${tabId}`);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  return (
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={userInterfaceLang} messages={lang[userInterfaceLang]}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={calendarLocales.en}>
            <ThemeProvider theme={muiMarkantTheme}>
              <CssBaseline/>
              <StoragePile/>
              <LoadUserPermissions/>
              <LoadUserProfile/>
              <OptionsDialog/>
              <Router>
                <TitleSetter />
                <SnackbarProvider
                  action={(key) => <SnackbarDismissAction id={key} />}
                  maxSnack={3}
                >
                  <Switch>
                    {createProtectedRoutes(protectedRoutes, isLoading, hasRole)}
                    <Route path={paths.BPCODescription} component={BPCODescriptionView}/>
                    <Route path={paths.BPCODescriptionList} component={BPCODescriptionList}/>
                    <Route path={paths.BPCODetails} component={BPCOEditView}/>
                    <Route path={paths.BPCODetailsNew} component={BPCONewView}/>
                    <Route path={paths.BPCOList} component={BPCOList}/>
                    <Route path={paths.advertisementBrowser} component={AdvertisementBrowser}/>
                    <Route path={paths.advertisementBrowserMedia} component={AdvertisementBrowserMedia}/>
                    <Route path={paths.advertisementsOverviewPromotions} component={AdvOverviewPromotions}/>
                    <Route path={paths.advertisementsOverview} component={AdvertisementsOverview}/>
                    <Route path={paths.advertisementsSpotOverview} component={AdvertisementSpotOverview}/>
                    <Route path={paths.advertisingAreasList} component={AdvertisingAreasList}/>
                    <Route path={paths.attribute} component={AttributesBrowser}/>
                    <Route path={paths.attributeCreate} component={AttributeCreate}/>
                    <Route path={paths.branchesList} component={BranchesList}/>
                    <Route path={paths.brands} component={Brands}/>
                    <Route path={paths.newBrand} component={BrandNewView}/>
                    <Route path={paths.editBrand} component={BrandEditView}/>
                    <Route path={paths.newStructuredBrand} component={StructuredBrandNewView}/>
                    <Route path={paths.editStructuredBrand} component={StructuredBrandsEditView}/>
                    <Route path={paths.categoriesAttributes} component={CategoryAttributes}/>
                    <Route path={paths.categoriesHierarchy} component={CategoriesHierarchy}/>
                    <Route path={paths.categoryDetails} component={CategoryDetails}/>
                    <Route path={paths.brickDetails} component={BrickView}/>
                    <Route path={paths.category} component={Category}/>
                    <Route path={paths.describeFrameLeafletBrowser} component={FrameDescriptionLeafletBrowser}/>
                    <Route path={paths.describeFrame} component={FrameDescription}/>
                    <Route path={paths.describeSpot} component={MediaDescription}/>
                    <Route path={paths.dictionary} component={Dictionary}/>
                    <Route path={paths.enterHeaderData} component={HeaderData}/>
                    <Route path={paths.fixReportedFrames} component={FixReportedFrames}/>
                    <Route path={paths.framing} component={Framing}/>
                    <Route path={paths.franchiseOwnersList} component={FranchiseOwnersList}/>
                    <Route path={paths.gtin} component={GtinQsDashboard}/>
                    <Route path={paths.keyHierarchy} component={KeyHierarchy}/>
                    <Route path={paths.leafletBrowserFullScreen} component={LeafletBrowserFullScreen}/>
                    <Route path={paths.printMediaOverview} component={PrintMediaOverview}/>
                    <Route path={paths.literal} component={Literal}/>
                    <Route path={paths.localStorage1} component={TestView}/>
                    <Route path={paths.localStorage2} component={TestView2}/>
                    <Route path={paths.masterData} component={MasterData}/>
                    <Route path={paths.myAdvertisements} component={MyAdvertisementsView}/>
                    <Route path={paths.productDescriptionList} component={ProductDescriptionListView}/>
                    <Route path={paths.productDescription} component={ProductDescriptionView}/>
                    <Route path={paths.productDetailsClone} component={ProductCloneView}/>
                    <Route path={paths.productDetailsNew} component={ProductNewView}/>
                    <Route path={paths.productDetails} component={ProductEditView}/>
                    <Route path={paths.productLineList} component={ProductLineList}/>
                    <Route path={paths.productsList} component={ProductsList}/>
                    <Route path={paths.reportedAdvertisementsBrowser} component={ReportedAdvertisementsBrowser}/>
                    <Route path={paths.reportedFramesBrowser} component={ReportedFramesBrowser}/>
                    <Route path={paths.reportedIssues} component={ReportedIssues}/>
                    <Route path={paths.statistics} component={Statistics}/>
                    <Route path={paths.retailerHQList} component={RetailerHQList}/>
                    <Route path={paths.spotOverview} component={SpotOverview}/>
                    <Route path={paths.spotHeaderData} component={SpotHeaderData}/>
                    <Route path={paths.radioSpotHeaderData} component={RadioSpotHeaderData}/>
                    <Route path={paths.tvSpotHeaderData} component={TvSpotHeaderData}/>
                    <Route path={paths.suppliers} component={Suppliers}/>
                    <Route path={paths.supplierDetails} component={SupplierDetails}/>
                    <Route path={paths.home} component={Dashboard}/>
                  </Switch>
                  <SnackbarUtilsConfigurator/>
                  <LeafletUploadDialog/>
                  <SpotUploadDialog/>
                </SnackbarProvider>
              </Router>
            </ThemeProvider>
          </LocalizationProvider>
        </IntlProvider>
      </QueryClientProvider>
  );
}

export default App;
