// convention:
// [prefix.]keyName
//
// use [a.] prefix when key is used throughout whole application for functional items like action buttons (yes, no close), statuses (loading) etc
// use [module/componentName] when key is used for app control purposes only in given component ex. 'upload leaflet'
// use [b] prefix if it is a business/domain knowledge term, doesn't matter if in single component or in many places

const de = {
    'a.2many': 'Zu viele Ergebnisse, passen Sie Ihre Suche an',
    'a.actions': 'Aktionen',
    'a.active': 'Aktiv',
    'a.add': 'Hinzufügen',
    'a.all': 'Alle',
    'a.any': 'Beliebig',
    'a.cancel': 'Abbrechen',
    'a.clear': 'Löschen',
    'a.click2expand': 'Zum Erweitern anklicken',
    'a.close': 'Schließen',
    'a.confirm': 'Bestätigen',
    'a.copy': 'Kopieren',
    'a.details': 'Details',
    'a.edit': 'Bearbeiten',
    'a.editSelected': 'Selektion bearbeiten',
    'a.erase': 'Entfernen',
    'a.error': 'Fehler',
    'a.error2': 'Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten',
    'a.errorNoAccess': 'Sie haben nicht die Erlaubnis, diese Aktion durchzuführen',
    'a.exportExcel': 'Nach Excel exportieren',
    'a.framing': 'Framing',
    'a.inactive': 'Inaktiv',
    'a.invalidAttr': 'Ungültiger Attributwert',
    'a.loading': 'Laden',
    'a.new': 'Neu',
    'a.next': 'Weiter',
    'a.no': 'Nein',
    'a.of': 'Von',
    'a.override': 'Überschreiben',
    'a.overwrite': 'Überschreiben',
    'a.paste': 'Einfügen',
    'a.pasteSelected': 'In Selektion einfügen',
    'a.prev': 'Vorherige',
    'a.progress': 'Fortschritt',
    'a.reason': 'Grund',
    'a.remove': 'Löschen',
    'a.removeSelected': 'Selektion entfernen',
    'a.replace': 'Ersetzen',
    'a.replaceW': 'Ersetzen mit',
    'a.right4context': 'Rechtsklick für Kontextmenü',
    'a.save': 'Speichern',
    'a.saveCopy': 'Speichern Kopie',
    'a.savePrompt': 'Wollen Sie diese Änderungen wirklich speichern?',
    'a.search': 'Suche',
    'a.searchLimit': 'Top 100 Ergebnisse anzeigen',
    'a.select': 'Auswählen',
    'a.selectAll': 'Alle auswählen',
    'a.settings': 'Einstellungen',
    'a.show': 'Anzeigen',
    'a.submit': 'Bestätigen',
    'a.summary': 'Zusammenfassung',
    'a.tooltipClick2Activate': 'Zum Aktivieren klicken',
    'a.tooltipClick2Deactivate': 'Zum Deaktivieren klicken',
    'a.tooltipClck2Remove': 'Zum Entfernen anklicken',
    'a.value': 'Wert',
    'a.valueFrom': 'Von',
    'a.valueTo': 'Bis',
    'a.yes': 'Ja',
    'advertisementsOverview.allAdvertisements': 'Alle Aktionen',
    'advertisementsOverview.manuallyCreatedAdvertisements': 'Manuell kreierte Aktionen',
    'advertisementsOverview.connectedFrames': 'Andere verbundene Frames',
    'advertisementsOverview.disconnectFrameMsg': 'Möchten Sie diesen Frame von den anderen Frames lösen?',
    'advertisementsOverview.deleteFrame': 'Frame löschen',
    'advertisementsOverview.deleteFrameConfirm': 'Möchtest du wirklich den Frame entfernen und die dazu gehörigen Aktionen?',
    'advertisementsOverview.deleteFrameErr': 'Frame löschen nicht möglich',
    'advertisementsOverview.disconnectPage': 'Seiten trennen',
    'advertisementsOverview.disconnectPageMsg': 'Möchten Sie wirklich diese Seite von all ihren Duplikaten trennen?',
    'advertisementsOverview.disconnectPageSucc': 'Seite erfolgreich von den Duplikaten getrennt.',
    'advertisementsOverview.disconnectPageErr': 'Fehler beim Trennen der Verbindungen der Seite zu den Duplikaten.',
    'advertisementsOverview.getIfDuplicatesErr': 'Fehler bei der Prüfung nach Duplikaten.',
    'advertisementsOverview.fetchPromoErr': 'Fehler beim Abrufen von Aktionen',
    'advertisementsOverview.overview': 'Übersicht Werbeaktionen',
    'advertisementsOverview.updateErr': 'Fehler beim Abrufen von Aktionen',
    'advertisementsOverview.updateSucc': 'Aktionen aktualisiert',
    'advertisingArea.cantSetAsRep': 'Diese Filiale ist bereits als repräsentative Filiale für ein anderes Cluster festgelegt',
    'advertisingArea.create': 'Neues Cluster erstellen',
    'advertisingArea.err': 'Fehler beim Cluster erstellen',
    'advertisingArea.invalidRepresentative': 'Die ausgewählte repräsentative Filiale wird  bereits verwendet',
    'advertisingArea.name': 'Cluster Name',
    'advertisingArea.saveErr': 'Fehler beim Cluster speichern ',
    'advertisingArea.setAsRep': 'Als repräsentative Filiale festgelegt',
    'advertisingArea.succ': 'Als Cluster gespeichert',
    'advertisingAreaList.err': 'Fehler beim Laden von Cluster',
    'agGrid.pageSize': 'Anzahl an Seiten',
    'agGrid.reset': 'Spalten zurücksetzen',
    'attributes.activeAssignment': 'Aktive Zuweisung',
    'attributes.err': 'Fehler beim Laden von Eigenschaften',
    'attributes.label': 'Eigenschaften',
    'attributes.literalRemoved': 'Eigenschaftswert entfernt',
    'attributes.name': 'Eigenschaftsname',
    'attributes.new': 'Neue Eigenschaft erstellen',
    'attributes.savePrompt': 'Bei Aktivierung/Deaktivierung von zugewiesenen Attributen und Werten werden  alle zugehörigen Produkte angepasst',
    'attributes.saveErr': 'Fehler beim Speichern einer Eigenschaft',
    'attributes.selectedUnits': 'Ausgewählte Einheiten',
    'attributes.step1': 'Eigenschaft anlegen',
    'attributes.step2': 'Wählen Sie den Eigenschaftstyp',
    'attributes.step3': 'Inhalt festlegen',
    'attributes.succ': 'Eigenschaft gespeichert',
    'attributes.typeBrickDependentSet': 'Brick abhängige Eigenschaftswerte',
    'attributes.type': 'Eigenschaftstyp',
    'attributes.typeNumUnit': 'Numerisch',
    'attributes.typeRangeUnit': 'Numerischer Wertbereich',
    'attributes.typeSet': 'Eigenschaftswerte',
    'attributes.typeTxt': 'Freitext',
    'b.absoluteDiscount': 'Absoluter Rabatt',
    'b.addMainDescription': 'Hauptbeschreibung hinzufügen',
    'b.addAlternativeDescription': 'Alternative Beschreibung hinzufügen',
    'b.address': 'Adresse',
    'b.advertisementProposals': 'Aktionen/Vorschläge',
    'b.advertisingArea': 'Cluster',
    'b.advertisingAreas': 'Cluster',
    'b.alternativeDescription': 'Alternative Beschreibungen',
    'b.banner': 'Banner',
    'b.banners': 'Banner',
    'b.bpco': 'Marken-Warengruppen-Objekte',
    'b.bpcoDescription': 'Marke-Warengruppen-Object Beschreibung',
    'b.bpcoAdded': 'Marken-Warengruppen-Aktion hinzugefügt',
    'b.branches': 'Filialen',
    'b.brand': 'Marke',
    'b.brands': 'Marken',
    'b.capacity': 'Füllmenge',
    'b.capacityUnit': 'Einheit Füllmenge',
    'b.categories': 'Warengruppen',
    'b.category': 'Warengruppe',
    'b.categoryAttributes': 'Eigenschaften der Warengruppe',
    'b.categoryBrick': 'Brick',
    'b.categoryCharacteristics': 'Merkmale der Warengruppe',
    'b.categoryClass': 'Class',
    'b.categoryFamily': 'Family',
    'b.categorySegment': 'Segment',
    'b.characteristics': 'Merkmale',
    'b.city': 'Stadt',
    'b.contentOfTradingUnit': 'Gebindeinhalt',
    'b.contentOfTradingUnitUnit': 'Einheit Gebindeinhalt',
    'b.country': 'Land',
    'b.countryThemedWeek': 'Länderwochen',
    'b.dataLanguage': 'Sprache Dateninhalte',
    'b.date': 'Datum',
    'b.deprecated': 'Veraltet',
    'b.deprecatedCharacteristics': 'Veraltete Merkmale',
    'b.descr': 'Beschreibung',
    'b.descriptionLangErr': 'Es darf nur eine Hauptbeschreibung pro Sprache existieren',
    'b.descriptionReplaced': 'Beschreibung ersetzt',
    'b.descriptionSaveErr': 'Fehler beim Speichern der Beschreibung',
    'b.descriptionSaveSucc': 'Beschreibung wurde gespeichert',
    'b.duplicated': 'Duplikat',
    'b.enterHeaderData': 'Kopfdaten erfassen',
    'b.fileName': 'Dateiname',
    'b.fileType': 'Dateityp',
    'b.franchiseOwner': 'Selbständiger Einzelhändler',
    'b.franchiseOwners': 'Selbständiger Einzelhändler',
    'b.general': 'Allgemeine Informationen',
    'b.gtin': 'GTIN',
    'b.houseNumber': 'Hausnummer',
    'b.industry': 'Branche',
    'b.issuesType': 'Fehlertyp',
    'b.isMarkantRetailPartner': 'Markant Handelspartner',
    'b.keyAccount': 'Key Account',
    'b.leafletValidity': 'Handzettel Gültigkeit',
    'b.latitude': 'Breitengrad',
    'b.location': 'Standort',
    'b.longitude': 'Längengrad',
    'b.mainDescription': 'Hauptbeschreibungen',
    'b.market': 'Markt',
    'b.media': 'Rundfunk',
    'b.mediumType': 'Publikationstyp',
    'b.mpu': 'Mindestabnahme',
    'b.name': 'Name',
    'b.nielsenRegion': 'Nielsen Region',
    'b.pageValidity': 'Seiten Gültigkeit',
    'b.postCode':'Postleitzahl',
    'b.price': 'Preis',
    'b.priceGross': 'Bruttopreis',
    'b.priceNet': 'Nettopreis',
    'b.product': 'Produkt',
    'b.productAdded': 'Produkt hinzugefügt',
    'b.productCopy': 'Kopie des Produkts',
    'b.productDescription': 'Produktbeschreibung',
    'b.productLine': 'Produktlinie',
    'b.promotionCountryWeek': 'Länderwochen',
    'b.promotionQS': 'Gütesiegel',
    'b.promotionSeasonTheme': 'Saison / Thema',
    'b.promotionType': 'Aktionstyp',
    'b.promotionalPrice': 'Aktionspreis',
    'b.promptLockExpire': 'Allgemeine Information: Ihre Zugriffssperre ist aufgrund von Inaktivität abgelaufen. Sie werden in Kürze auf die vorherige Seite weitergeleitet.',
    'b.region': 'Region',
    'b.regularPrice': 'Regulärer Preis',
    'b.relativeDiscount': 'Relativer Rabatt',
    'b.reportedBy': 'Gemeldet von',
    'b.representativeBranch': 'Repräsentative Filiale ',
    'b.retailer': 'Handelsunternehmen',
    'b.retailerHQ': 'Handelszentrale',
    'b.retailers': 'Anbieter',
    'b.street': 'Straße',
    'b.supplier': 'Lieferant',
    'b.suppliers': 'Lieferanten',
    'b.taxRate': 'Steuersatz',
    'b.time': 'Uhrzeit',
    'b.typeOfBranch': 'Filialtyp',
    'b.typeOfBranchFranchise': 'SEH',
    'b.typeOfBranchStd': 'Standard',
    'b.typeOfPackage': 'Verpackungstyp ',
    'b.userInterfaceLanguage': 'Sprache der Benutzeroberfläche',
    'b.unit': 'Einheit',
    'b.variety': 'Variante',
    'b.validFrom': 'Gültig von',
    'b.validTo': 'Gültig bis',
    'banner.duplicate': 'Dieses Banner existiert bereits',
    'banner.err': 'Fehler beim Laden von Banner',
    'banner.publishWithValidity': 'Bei Gültigkeit veröffentlichen',
    'banner.saveErr': 'Fehler beim Speichern des Banners',
    'banner.succ': 'Banner gespeichert',
    'banner.tooltipPublishWithValidity': 'Alle Medien eines Banners mit dieser Kennzeichnung werden frühestens mit Gültigkeitsbeginn zur Auswertung in MPM veröffentlicht. Dabei spielt es keine Rolle, wann das Medium erfasst wird.',
    'bpco.createCopy': 'Kopie der Marken-Warengruppen-Aktion erstellen',
    'bpco.duplicate': 'Das gleiche Marken-Warengruppen-Objekt existiert bereits',
    'bpco.err': 'Fehler beim Laden des Marken-Warengruppen-Objektes ',
    'bpco.errorFetchingCreated': 'Fehler beim Aufrufen der erstellten Beschreibung. Bitte verwenden Sie die Suche, um sie zu finden.',
    'bpco.saveErr': 'Fehler beim Speichern des Marken-Warengruppen-Objektes',
    'bpco.succ': 'Marken-Warengruppen-Objekt gespeichert',
    'branchDetails.cantDeactivate': 'Deaktivierung nicht möglich. Die Filiale ist repräsentativ für ein Werbegebiet',
    'branchDetails.err': 'Fehler beim Laden der Filialinformationen',
    'branchDetails.isRep': 'Kann nicht deaktiviert werden - Filiale ist repräsentativ für ',
    'branchDetails.postCodeError': 'PLZ darf nicht über 15 Zeichen lang sein',
    'branchDetails.saveErr': 'Fehler beim Speichern von Filialinformationen',
    'branchDetails.succ': 'Filialinformationen gespeichert',
    'branchesOf': 'Filialen von ',
    'branchesSelector.avail': 'Verfügbare Filialen',
    'branchesSelector.selected': 'Ausgewählte Filialen',
    'brand.already': 'Marke wird bereits in dieser Marken-Gliederung verwendet',
    'brand.create': 'Neue Marke erstellen',
    'brand.createStructured': 'Neue Marken-Gliederung erstellen',
    'brand.duplicate': 'Marke existiert bereits',
    'brand.duplicateConfirmation1': 'Ähnliche schon existierende Marken wurden für diese Marke gefunden.',
    'brand.duplicateConfirmation2': 'Wollen Sie dennoch diese Marke erstellen?',
    'brand.duplicateFound': 'Ähnliche Marke(n)',
    'brand.edit': 'Marke bearbeiten',
    'brand.editStructured': 'Marken-Gliederung bearbeiten',
    'brand.err': 'Fehler beim Aufrufen Marke',
    'brand.errs': 'Fehler beim Laden von Marken',
    'brand.main': 'Dachmarke',
    'brand.name': 'Markenname',
    'brand.saveErr': 'Fehler beim Speichern der Marke',
    'brand.secondary': 'Untermarken',
    'brand.selectSec': 'Untermarke auswählen',
    'brand.succ': 'Marke gespeichert',
    'brand.struct': 'Marken-Gliederung',
    'broadcastOverview.creativeId': 'Creative-ID',
    'broadcastOverview.channel': 'Sender',
    'broadcastOverview.title': 'Sendeplan',
    'category.alreadySelected': 'Diese Eigenschaft ist bereits ausgewählt',
    'category.alternative': 'Alternative Warengruppe',
    'category.attrUpdateErr': 'Fehler beim Aktualisieren von Kategorieattributen',
    'category.bName': 'Brick Name',
    'category.cName': 'Class name',
    'category.confirmation': 'Die abhängigen Artikel werden der angegebenen Alternative zugeordnet.',
    'category.createErr': 'Fehler beim Erstellen der Warengruppe',
    'category.createSucc': 'Warengruppe erstellt',
    'category.err': 'Fehler beim Aufrufen der Warengruppe',
    'category.expandAll': 'Alle erweitern',
    'category.expandRow': 'Zeile erweitern',
    'category.fName': 'Family name',
    'category.newBrick': 'Brick erstellen',
    'category.newClass': 'Class erstellen',
    'category.newFamily': 'Family erstellen',
    'category.newSegment': 'Segment erstellen',
    'category.parent': 'Übergeordnete Kategorie',
    'category.requireLiterals': 'Eigenschaftszuordnungen erstellen',
    'category.setupBrickDependentAttr': 'Eigenschaftswerte für {attributeName}',
    'category.sName': 'Segment name',
    'category.updateErr': 'Fehler beim Speichern der Kategorie',
    'category.updateSucc': 'Warengruppe aktualisiert',
    'dictionary.dict': 'Wörterbuch',
    'dictionary.err': 'Fehler beim Laden des Wörterbuches',
    'dictionary.new': 'Neues Element zum Wörterbuch hinzufügen',
    'dictionary.saveErr': 'Fehler beim Aktualisieren des Wörterbuches',
    'dictionary.succ': 'Wörterbuch aktualisiert',
    'dictionaryElement.alt': 'Alternative ',
    'dictionaryElement.alternative': 'Alternative ',
    'dictionaryElement.err': 'Fehler beim Laden eines Wörterbuch-Eintrages',
    'dictionaryElement.info': 'Doppelklick zum Bearbeiten',
    'dictionaryElement.info2': 'Englisch und mindestens eine weitere Übersetzung erforderlich',
    'dictionaryElement.lang': 'Sprache',
    'dictionaryElement.trans': 'Übersetzung',
    'fileUpload.chooseFiles': 'Dateien auswählen',
    'fileUpload.formatWarning': 'Dateien {files} haben ungültiges Format',
    'fileUpload.or': 'oder',
    'fileUpload.preventIdenticalRecognition': 'Identische Seitenerkennung unterbinden',
    'fileUpload.selectedFile': 'Ausgewählte Datei',
    'fileUpload.uploadFilePlaceholder': 'Drag and drop datei',
    'filters': 'Filter',
    'filters.clear': 'Filter löschen',
    'fixReportedFrames.cantSubmitEmpty': 'Es sind Frames auf der Seite vorhanden. Diese kann nicht als leere Seite markiert werden',
    'frame.clear': 'Alle Frames löschen',
    'frame.empty': 'Leere Seite',
    'frame.emptyConfirm': 'Es wurden keine Frames erstellt. Möchten Sie diese Seite als "leere Seite" markieren?',
    'frame.err': 'Fehler beim Speichern von Frames',
    'frame.fitToWindow': 'Ansicht anpassen',
    'frame.reviseFramesPrevPage': 'Frames anpassen auf der vorherigen Seite',
    'frame.noPages': 'Keine Seite zum Framen',
    'frame.skip': 'Seite überspringen',
    'frame.skipped': 'Seite übersprungen',
    'frame.submit': 'Frames freigeben',
    'frame.succ': 'Frames gespeichert ',
    'frame.validFrom': 'Frame ist gültig von',
    'frame.validTo': 'Frame ist gültig bis',
    'frame.validity': 'Frame Gültigkeitszeitraum',
    'frameDescription.addFrameValidity': 'Frame Gültigkeitszeitraum hinzufügen',
    'frameDescription.apply2selected': 'Auf ausgewählte Aktionen anwenden',
    'frameDescription.confirmSelectedRemoval': 'Werte aus Aktion entfernen',
    'frameDescription.eraseAction': 'Werte entfernen ausgewählter Aktionen ',
    'frameDescription.eraseAndOverwriteAction': 'Werte entfernen und überschreiben',
    'frameDescription.err': 'Fehler beim Laden Framedaten',
    'frameDescription.errCantReuse': 'Das Produkt konnte nicht hinzugefügt werden. Bitte über die manuelle Suche das Produkt erneut erfassen.',
    'frameDescription.errImg': 'Fehler beim Laden des Framebildes',
    'frameDescription.errNoFrame': 'Kein Frame vorhanden',
    'frameDescription.fetchPromoSugErr': 'Fehler bei der Suche nach ähnlichen Aktionen',
    'frameDescription.fetchPromoSugNothing': 'Passende Aktionen nicht gefunden',
    'frameDescription.fetchPromoSugTitle': 'Aktionsvorschläge',
    'frameDescription.framePrepNotFinished': 'Vorbereitung des Frames noch nicht abgeschlossen. Bitte kommen Sie später wieder oder erfassen Sie den Frame ohne Aktionsvorschläge.',
    'frameDescription.noProposalsForFrame': 'Keine Aktionsvorschläge vorhanden. Fahren Sie mit der Erfassung ohne Vorschläge fort.',
    'frameDescription.report': 'Frame melden',
    'frameDescription.reportAdvertisement': 'Aktion melden',
    'frameDescription.reported': 'Frame gemeldet',
    'frameDescription.reportReason1': 'Frame ist inkorrekt',
    'frameDescription.reportReason2': 'Aktion kann nicht erfasst werden',
    'frameDescription.reportedAdvertisement': 'Aktion gemeldet',
    'frameDescription.saveErr': 'Fehler beim Speichern der Aktion',
    'frameDescription.skip': 'Frame überspringen',
    'frameDescription.skipped': 'Frame übersprungen',
    'frameDescription.succ': 'Aktion erfolgreich erstellt',
    'franchiseOwner.create': 'Neuen Selbständigen Einzelhändler anlegen',
    'franchiseOwner.err': 'Neuen Selbständigen Einzelhändler anlegen',
    'franchiseOwner.name': 'Name des Selbständigen Einzelhändlers ',
    'franchiseOwner.saveErr': 'Fehler beim Speichern des Selbständigen Einzelhändlers',
    'franchiseOwner.succ': 'Selbständiger Einzelhändler gespeichert',
    'franchiseOwnersList.err': 'Fehler beim Laden von Selbständigen Einzelhändlern',
    'gtin.duplicatedGtins': 'GTIN-Duplikate',
    'gtin.productLocked': 'Dieses Produkt ist durch einen anderen Benutzer gesperrt',
    'gtin.errorUploadingFile': 'Fehler beim Hochladen der Datei',
    'gtin.errorUploadingFileExtension': 'Falsches Dateiformat. Bitte .csv hochladen.',
    'gtin.importGtins': 'GTIN importieren',
    'gtin.incorrectGtins': 'Falsche GTIN',
    'gtin.incorrectProducts': 'Falsche Produkte',
    'gtin.numberOfAdjustedProducts': 'Produkte mit geänderter GTIN',
    'gtin.productsWithoutGtins': 'Produkte ohne GTINS',
    'gtin.recordInFile': 'Datensätze in der Datei',
    'headerData.addPageValidity': 'Gültigkeitszeitraum Seite von - bis',
    'headerData.addPageCountryThemedWeeks': 'Länderwochen Seite von - bis',
    'headerData.addPageSeasonTheme': 'Saison-/Themenkennzeichen Seite von - bis',
    'headerData.addPagePromotionType': 'Aktionstyp Seite von - bis',
    'headerData.copied': 'Kopfdaten gespeichert in der Zwischenablage',
    'headerData.copiedErr': 'Keine Daten in der Zwischenablage',
    'headerData.countryThemedWeeksPerPage': 'Länderwochen pro Seite',
    'headerData.duplicateConfirm': 'Wollen Sie wirklich die Kopfdaten speichern?',
    'headerData.duplicateLeaflet': 'Prospekt mit identischen Kopfdaten existiert bereits.',
    'headerData.duplicateLeafletCountryThemedWeeeks': 'Fehler Seitengültigkeit Länderwochen - gleiche Seite wurde mehrfach verwendet: ',
    'headerData.duplicateLeafletPromotionTypePages': 'Fehler Seitengültigkeit Aktionstxp - gleiche Seite wurde mehrfach verwendet: ',
    'headerData.duplicateLeafletThemeSeasonsPages': 'Fehler bei der Seitensaison / dem Seitenthema - dieselbe Seite wird mehrfach verwendet: ',
    'headerData.duplicateLeafletValidityPages': 'Fehler bei der Seitengültigkeit - dieselbe Seite wird mehrfach verwendet: ',
    'headerData.err': 'Fehler beim Laden des Handzettels',
    'headerData.errLeafletValidityPages': 'Fehler bei der Seitengültigkeit - fehlendes Datum',
    'headerData.errLocked': 'Der Handzettel wird bereits von einem anderen Benutzer erfasst.',
    'headerData.invalidPriceMode': 'Ungültiger Preismoduswert',
    'headerData.leafletValidF': 'Handzettel gültig von',
    'headerData.leafletValidT': 'Handzettel gültig bis',
    'headerData.pages': 'Seiten',
    'headerData.pgValidF': 'Seite gültig von',
    'headerData.pgValidT': 'Seite gültig bis',
    'headerData.promotionTypesPerPage': 'Aktionstyp pro Seite',
    'headerData.publicationOverviewNoData': 'Kein Streuplan verfügbar',
    'headerData.saveErr': 'Fehler beim Speichern des Handzettels',
    'headerData.seasonThemePerPage': 'Saison / Thema pro Seite',
    'headerData.selectBranches': 'Filiale auswählen',
    'headerData.selectByAdvArea': 'Filialen aus Cluster wählen',
    'headerData.succ': 'Handzetteldaten gespeichert',
    'headerData.useAdvArea': 'Filialen aus Cluster verwenden {areaName}',
    'headerData.useComboAdvAreas': 'Filialen aus den ausgewählten Clustern verwenden',
    'headerData.validityPerPage': 'Gültigkeitszeitraum pro Seite',
    'hierarchy.banners': 'Banner für {retailerName}',
    'hierarchy.confirmation1': ' wird deaktiviert',
    'hierarchy.confirmation2': 'Verwandte Elemente werden ebenfalls deaktiviert: ',
    'hierarchy.createBanner': 'Banner erstellen',
    'hierarchy.createKeyAccount': 'Key Account erstellen',
    'hierarchy.createRetailer': 'Handelsunternehmen anlegen',
    'hierarchy.emptyList': 'Keine Elemente gefunden',
    'hierarchy.keyAccounts': 'Key Accounts',
    'hierarchy.retailers': 'Handelsunternehmen für {keyAccountName}',
    'industries.err': 'Fehler beim Laden der Brancheninformation',
    'industrySelector.err': 'Fehler beim Laden der Brancheninformation',
    'keyAccount.duplicate': 'Key Account existiert bereits',
    'keyAccount.saveErr': 'Fehler beim Speichern des Key Accounts',
    'keyAccount.succ': 'Key Account gespeichert',
    'language.bg': 'Bulgarisch',
    'language.cs': 'Tchechisch',
    'language.de': 'Deutsch',
    'language.de_AT': 'Österreichisch',
    'language.de_CH': 'Schweizerdeutsch',
    'language.en': 'Englisch',
    'language.fr': 'Französisch',
    'language.hr': 'Kroatisch',
    'language.hu': 'Ungarisch',
    'language.it': 'Italienisch',
    'language.nl': 'Niederländisch',
    'language.pl': 'Polnisch',
    'language.ro': 'Romanisch',
    'language.sk': 'Slowanisch',
    'language.sl': 'Slovenisch',
    'leafletBrowser.goToFrame': 'Gehe zum Frame',
    'leafletBrowser.report': 'Seite melden',
    'leafletBrowser.reported': 'Seite gemeldet',
    'leafletPage.error': 'Fehler beim Laden der Seite',
    'leafletStatus.described': 'Erfasst',
    'leafletStatus.desribingInProgress': 'In Erfassung',
    'leafletStatus.done': 'Fertig',
    'leafletStatus.framed': 'Framed',
    'leafletStatus.framesDescribed': 'Frames erfasst',
    'leafletStatus.paged': 'Paging abgeschlossen',
    'leafletStatus.uploaded': 'Hochgeladen',
    'leafletUpload.publicationOverview': 'Streuplan',
    'leafletUpload.clearAll': 'Alle löschen',
    'leafletUpload.clearUploaded': 'Hochgeladene löschen',
    'leafletUpload.fileSize': 'Dateigröße',
    'leafletUpload.pressAdvertisement': 'Zeitung',
    'leafletUpload.statusCancelled': 'Upload abgebrochen',
    'leafletUpload.statusLoaded': 'Hochgeladen',
    'leafletUpload.statusLoading': 'Upload noch nicht abgeschlossen',
    'leafletUpload.statusNew': 'in der Warteschlange',
    'leafletUpload.uploadFailed': 'Beim Hochladen der Datei ist ein Fehler aufgetreten',
    'leafletUpload.uploadFailedNoPublication': 'Der Streuplan fehlt. Laden Sie diesen zuerst hoch, um mit dem Upload der Annoncen fortzufahren',
    'leafletUpload.uploadFailedInvalidCsv': 'Ungültiges Format in der Datei Streuplan',
    'leafletUpload.uploadFailedMissingDataCsv': 'Fehlende erforderliche Daten in der Datei Streuplan',
    'leafletUpload.uploadFailedWrongName': 'Beim Hochladen der Datei ist ein Fehler aufgetreten. Falscher Dateiname',
    'leafletUpload.uploadSuccess': 'Dateien erfolgreich hochgeladen',
    'leafletUploadNavButton.leafletUploadCTA': 'Printmedien hochladen',
    'literals.activeLiterals': 'EG-Werte aktiv',
    'literals.duplicate': 'Dieser Wert existiert bereits',
    'literals.err': 'Fehler beim Laden von Eigenschaftswerten',
    'literals.errCreate': 'Fehler bei der Erstellung von Literalen',
    'literals.errUpdate': 'Fehler beim Aktualisieren der Eigenschaftswerte',
    'literals.inactiveLiterals': 'EG-Werte inaktiv',
    'literals.label': 'Eigenschaftswerte',
    'literals.new': 'Neues Eigenschftswert erstellen',
    'literals.succCreate': 'Eigenschaftswert erstellt',
    'literals.succUpdate': 'Eigenschaftswert aktualisiert',
    'log.copyAll': 'Kopiere alles',
    'log.copyToday': 'Kopieren Sie die heutigen Ereignisse',
    'log.date': 'Ereignisdatum',
    'log.details': 'Ereignisprotokoll',
    'log.header': 'Protokoll der Vorfälle',
    'log.removeAll': 'Alle Einträge entfernen',
    'masterData.BPCO': 'Marken-Warengruppen-Objekt',
    'masterData.attributeError': 'Fehler beim Laden der Eigenschaften',
    'masterData.basicMasterData': 'Basisdaten',
    'masterData.dictionaries': 'Übersetzungen',
    'masterData.productLines': 'Produktlinien',
    'masterData.products': 'Produkte',
    'masterData.retailerHierarchy': 'Key Account/Handelsunternehmen/Banner',
    'media.broadcastOverviewHide': 'Sendeplan ausblenden',
    'media.broadcastOverviewShow': 'Sendeplan einblenden',
    'media.chooseSpotFile': 'Spot zum Hochladen auswählen',
    'media.copyFileName': 'Kopiere Dateinamen',
    'media.delete': 'Spot löschen',
    'media.deleteErr': 'Beim Löschen des Spots ist ein Fehler aufgetreten',
    'media.deleteFile': 'Datei löschen',
    'media.deletePrompt': 'Möchten Sie diesen Spot wirklich löschen?',
    'media.deleteSucc': 'Spot erfolgreich gelöscht',
    'media.describeMedia': 'Medien erfassen',
    'media.errNoMedia': 'Keine Medien verfügbar',
    'media.mediaFileFetchErr': 'Fehler beim Abrufen der Mediendatei',
    'media.spotFetchErr': 'Fehler beim Aufrufen der Daten des Spots',
    'media.radioSpotDescribe': 'Radio-Spot erfassen',
    'media.tvSpotDescribe': 'TV-Spot erfassen',
    'media.spotDescribe': 'Spot erfassen',
    'media.spotFilterStatus': 'Status Spot: ',
    'media.spotLanguage': 'Spot Sprache',
    'media.radioSpotPlayAudio': 'Radiospot abspielen',
    'media.spotSaveErr': 'Fehler beim Speichern des Spots',
    'media.spotSucc': 'Spot gespeichert',
    'media.radioSpotVerify': 'Kopfdaten erfassen',
    'media.tvSpotVerify': 'Kopfdaten erfassen',
    'media.spotVerify': 'Spot überprüfen',
    'media.spotLocked': 'Werbespot ist gesperrt von einem User',
    'media.spotSaveInfo': 'Sie müssen die erforderlichen Felder ausfüllen, um das Transkript speichern zu können.',
    'media.statusDone': 'Fertig',
    'media.statusPending': 'Vorschläge in Arbeit',
    'media.statusRegistered': 'Kopfdaten erforderlich',
    'media.statusVerified': 'Bereit zum Erfassen',
    'media.transcriptProposals': 'Transkriptvorschläge',
    'media.transcription': 'Inhalt',
    'nav.goTo': 'Ansicht wechseln',
    'nav.advertisement': 'Aktion',
    'nav.contextMenuOpenNewTab': 'In neuem Tab öffnen',
    'nav.frame': 'Frame',
    'nav.framingCTA': 'Framing starten',
    'nav.leaflet': 'Handzettel',
    'nav.masterData': 'Stammdaten',
    'nav.mediumSpot': 'Übersicht Spots',
    'nav.mediumVerification': 'Spot überprüfen',
    'nav.myAdvertisements': 'Meine erfassten Aktionen',
    'nav.myTeamsAdvertisements': 'Erfasste Aktionen (Team)',
    'nav.overviewAdvertisements': 'Aktionsübersicht (Printmedien)',
    'nav.overviewAdvertisementsPromo': 'Aktionsübersicht (Artikel)',
    'nav.overviewFrames': 'Frames-Übersicht',
    'nav.overviewQuality': 'Statistiken',
    'nav.overviewAdvertisementsSpot': 'Aktionsübersicht (Spots)',
    'nav.quality': 'Qualität',
    'nav.qualityBrowse': 'QS-Meldungen',
    'nav.showLeaflet': 'Handzettel anzeigen',
    'page.locked': 'Die Seite wird bereits von einem anderen Benutzer erfasst.',
    'printMediaOverview.createdBy': 'Erstellt von',
    'printMediaOverview.creationDate': 'Erstellt',
    'printMediaOverview.currentUsers': 'Aktuelle Bearbeiter',
    'printMediaOverview.deleteConfirmation': 'Wollen Sie diesen Handzettel wirklich löschen?',
    'printMediaOverview.deleteInstruction': 'Zum Bestätigen schreiben Sie bitte "DELETE".',
    'printMediaOverview.delete': 'Handzettel löschen',
    'printMediaOverview.deleteSucc': 'Handzettel erfolgreich gelöscht',
    'printMediaOverview.deleteErr': 'Beim Löschen des Handzettels ist ein Fehler aufgetreten',
    'printMediaOverview.describeFrame': 'Frame erfassen',
    'printMediaOverview.describedFrames': 'Erfasste Frames',
    'printMediaOverview.editFrames': 'Frame bearbeiten',
    'printMediaOverview.editHeaderData': 'Kopfdaten bearbeiten',
    'printMediaOverview.filter': 'Handzettel nach Banner filtern',
    'printMediaOverview.filterBanner': 'Handzettel für Banner anzeigen: ',
    'printMediaOverview.filterLanguage': 'Handzettel in der Sprache: ',
    'printMediaOverview.filterStatus': 'Handzettel im Status: ',
    'printMediaOverview.headerDataCTA': 'Kopfdaten erfassen',
    'printMediaOverview.leafletDescribed': 'Bereit zum Framen',
    'printMediaOverview.leafletFramed': 'Bereit zum Erfassen',
    'printMediaOverview.leafletPaged': 'Kopfdaten erforderlich',
    'printMediaOverview.leafletReady': 'Fertiggestellt',
    'printMediaOverview.leafletType': 'Handzettel-Typ',
    'printMediaOverview.leafletUploaded': 'Paging in Arbeit',
    'printMediaOverview.modifiedBy': 'Bearbeitet von',
    'printMediaOverview.noProgressData': 'Keine Fortschrittsdaten verfügbar',
    'printMediaOverview.numberOfPages': 'Seitenanzahl',
    'printMediaOverview.overview': 'Übersicht Printmedien',
    'printMediaOverview.pressTitle': 'Pressetitel',
    'printMediaOverview.reportedFrames': 'Gemeldete Frames',
    'printMediaOverview.reportedPages': 'Gemeldete Seiten',
    'printMediaOverview.selectLanguage': 'Handzettel-Sprache',
    'printMediaOverview.status': 'Status',
    'printMediaOverview.totalFrames': 'Gesamtanzahl Frames',
    'printMediaOverview.validityDates': 'Gültigkeitszeitraum',
    'product.addGtin': 'GTIN hinzufügen',
    'product.bulk': 'Massenänderung Produkte',
    'product.bulkDescription': 'Massenänderung Produktbeschreibung',
    'product.bulkDescriptionHelp': ' Die angezeigten Attribute kommen von der neu gewählten Brick. Mindestens eines der ausgewählten Produkte hat eine Brick, die nicht mit diesen Attributen der neu gewählten Brick verknüpft sind. Vervollständigen Sie die fehlenden Werte. Die anderen Attribute die mit der aktuellen Brick übereinstimmen, werden automatisch übertragen.',
    'product.bulkDuplicates': 'Diese Massenänderung erzeugt Duplikate ({count}). Produkte werden deaktiviert und ersetzt. Dieser Vorgang ist unumkehrbar.',
    'product.bulkSucc': 'Produkte erfolgreich aktualisiert',
    'product.bulkSucc2': 'Die Aktualisierung der Suche kann einige Zeit dauern.',
    'product.bulkSupplier': 'Marke/Lieferant zuweisen',
    'product.bulkSupplierConfirm': '{sName} wird als Lieferant für {count} Produkte festgelegt',
    'product.bulkUpdate': 'Aktualisierung von {count} Produkten',
    'product.bulkUpdateDescription': 'Aktualisierung von (count) Produkten mit der folgenden Produktbeschreibung',
    'product.chooseDescription': 'Wählen Sie eine Beschreibung',
    'product.confirmReplace': 'Austausch des Produktes bestätigen. Dieser Vorgang kann nicht rückgängig gemacht werden.',
    'product.confirmReplaceDescription': 'Beschreibung des Austauschs bestätigen. Dieser Vorgang kann nicht rückgängig gemacht werden.',
    'product.confirmUpdate': '{count} Produkte werden aktualisiert',
    'product.copyAllGtins': 'Alle GTINs kopieren ',
    'product.copyThisGtin': 'GTIN kopieren',
    'product.createCopy': 'Ähnliches Produkt erstellen',
    'product.duplicate': 'Das gleiche Produkt existiert bereits',
    'product.duplicateConfirmPrompt': 'Das gleiche Produkt existiert bereits. Möchten Sie sie zusammenführen?',
    'product.err': 'Fehler beim Laden des Produktes',
    'product.errImg': 'Fehler beim Laden der Produkt Bilder.',
    'product.errAdding': 'Fehler beim Hinzufügen des Produkts',
    'product.fixReported': 'Produkt wurde angepasst',
    'product.gtinAdded': 'GTIN hinzugefügt',
    'product.gtinErr': 'Ungültige GTIN',
    'product.gtinExists': 'Diese GTIN wird schon verwendet',
    'product.gtinFetchErr': 'Fehler beim Abrufen der GTIN Informationen',
    'product.gtinRemoved': 'GTIN entfernt',
    'product.gtinVerified': 'Geprüft',
    'product.hasIssues': 'Produkt hat ungelöste Probleme',
    'product.hideImages': 'Bilder ausblenden',
    'product.isTemplate': 'Produktvorlage',
    'product.issuesResolved': 'Produktprobleme wurden behoben',
    'product.listErr': 'Fehler beim Laden der Produktliste',
    'product.noImgAvailable': 'Keine Bilder vorhanden',
    'product.relatedPromotions': 'Anzahl Aktionen',
    'product.reported': 'Produkt gemeldet',
    'product.saveErr': 'Fehler beim Speichern des Produktes',
    'product.showAdvertisement': 'Aktionen anzeigen',
    'product.showImages': 'Bilder anzeigen',
    'product.showTemplates': 'Produktvorlagen anzeigen',
    'product.succ': 'Produkt gespeichert',
    'product.succReplaced': 'Produkt wurde ersetzt',
    'product.toReplace': 'Produkt zu ersetzen',
    'productCategories.brick': 'Warengruppe: Brick',
    'productDescription.createNew': 'Neue Produktbeschreibung erstellen',
    'productDescription.createCopy': 'Kopie der Produktbeschreibung erstellen',
    'productDescription.err': 'Fehler beim Laden der Produktbeschreibung',
    'productDescription.listErr': 'Fehler beim Laden der Produktbeschreibungsliste',
    'productDescription.duplicate': 'Die gleiche Produktbeschreibung existiert bereits.',
    'productDescription.replaceErr': 'Fehler beim Ersetzen der Produktbeschreibung',
    'productLine.duplicate': 'Dieselbe Produktlinie existiert bereits',
    'productLine.err': 'Fehler beim Laden der Produktlinie',
    'productLine.listErr': 'Fehler beim Abrufen der Produktlinie Liste',
    'productLine.name': 'Name Produktlinie',
    'productLine.productsOf': 'Produkte von',
    'productLine.saveErr': 'Fehler beim Speichern der Produktlinie',
    'productLine.succ': 'Produktlinie gespeichert',
    'productSearch.alreadySelected': 'Produkt bereits ausgewählt',
    'productSearch.bundling': 'Bundling',
    'productSearch.type': 'Typ',
    'productSearch.giveAway': 'Zugabe',
    'productSearch.giveAwayBundling': 'Zugabe / Bundling',
    'productSearch.createBPCO': 'Neue Marken-Warengruppen-Aktion erstellen',
    'productSearch.clonePromotion': 'Gleiche Aktion nochmal erfassen',
    'productSearch.clonePromotionSelected': 'Ausgewählte Aktionen duplizieren',
    'productSearch.createProduct': 'Neues Produkt erstellen',
    'productSearch.results': 'Gefundene Produkte',
    'productSearch.toggleSearch': 'Nach Marken-Warengruppen-Objekten suchen',
    'productsSelector.avail': 'Verfügbare Produkte',
    'productsSelector.selected': 'Ausgewählte Produkte',
    'promotion.copied': 'Daten in der Zwischenablage gespeichert',
    'promotion.copiedErr': 'Keine Daten in der Zwischenablage',
    'promotion.derivedPropsExplanation': 'Die Werte werden dem Produkt zugeordnet ',
    'promotion.more': 'Und {count} weiter(e)',
    'promotion.overridePropsExplanation': 'Neue Werte für dieses Produkt erfassen',
    'promotion.searchByProduct': 'Suche wurde auf das gewählte Produkt eingegrenzt',
    'promotion.validFrom': 'Aktion gültig von',
    'promotion.validTo': 'Aktion gültig bis',
    'promotion.validity': 'Aktionsgültigkeit',
    'reportedFramesBrowser.fixAdvertisementBtn': 'Fehler beheben',
    'reportedFramesBrowser.fixBtn': 'Korrigieren',
    'reportedFramesSummary.pageNo': 'Seite',
    'reportProduct.label': 'Produkt melden',
    'retailer.duplicate': 'Handelsunternehmen existiert bereits',
    'retailer.saveErr': 'Fehler beim Speichern des Handelsunternehmens',
    'retailer.succ': 'Handelsunternehmen gespeichert',
    'retailerHQ.create': 'Neue Handelszentrale anlegen',
    'retailerHQ.err': 'Fehler beim Aufrufen der Handelszentrale',
    'retailerHQ.name': 'Name der Handelszentrale',
    'retailerHQ.names': 'Handelszentralen',
    'retailerHQ.saveErr': 'Fehler beim Speichern der Handelszentrale',
    'retailerHQ.succ': 'Handelszentrale gespeichert',
    'retailerHQList.err': 'Fehler beim Aufrufen der Handelszentralen',
    'searchProductLine': 'Suche nach Produktlinie',
    'spotUpload.mandatoryFieldsInfo': 'Sie müssen die erforderlichen Felder ausfüllen, um Dateien hochladen zu können.',
    'spotUpload.nothingToVerify': 'nichts zu überprüfen',
    'spotUpload.radioSpot': 'Radio-Spot',
    'spotUpload.tvSpot': 'TV-Spot',
    'spotUpload.spot': 'Spot',
    'spotUpload.statusPreconditionFailed': 'Kein Sendeplan steht zur Verfügung',
    'spotUploadNavButton.spotUploadCTA': 'Spot hochladen',
    'statistics.generalStatistics': 'Statistiken allgemein',
    'statistics.manuallyCreatedPromotions': 'Manuell erstellte Werbeaktionen',
    'statistics.manuallyCreatedPromotionsInfoBtn': 'Um die Daten zu exportieren, den Button  "Exportieren nach Excel" klicken',
    'statistics.media': 'Anzahl Werbeträger',
    'statistics.mediaErr': 'Fehler beim Laden von Mediendaten',
    'statistics.prod': 'Anzahl der Produkte',
    'statistics.promoErr': 'Fehler beim Laden von Aktionsdaten',
    'statistics.wkYr': 'Woche\n\\\nJahr',
    'statistics.selectCountries': 'Länder zum Exportieren auswählen',
    'supplier.created': 'Lieferant erstellt',
    'supplier.error': 'Fehler beim Abrufen des Lieferanten',
    'supplier.errorCreate': 'Fehler beim Erstellen des Lieferanten',
    'supplier.errorDuplicate': 'Lieferant existiert bereits',
    'supplier.errors': 'Fehler beim Abrufen der Lieferanten',
    'supplier.errorUpdate': 'Fehler beim Aktualisieren des Lieferanten',
    'supplier.noSupAvailable': 'Keine Lieferanten verfügbar',
    'supplier.search': 'Lieferantensuche',
    'supplier.select': 'Lieferant auswählen',
    'supplier.selectAndBrand': 'Marke/Lieferant auswählen',
    'supplier.updated': 'Lieferant aktualisiert',
    'supplier.usageCount': 'Nutzung',
    'timeRange.currentWeek': 'Aktuelle Woche',
    'timeRange.today': 'Heute',
    'timeRange.yesterday': 'Gestern',
    'typeOfBranchSelector.err': 'Fehler beim Laden von Filialtyp',
    'v.notANumber': 'Der angegebene Wert ist keine Zahl',
    'v.invalidChars': 'Nur Ziffern und -+*()/,. sind erlaubt',
    'v.invalidFormulaProduct': 'Das Ergebnis der Formel ist nicht korrekt',
    'v.outOfRange': 'Der angegebene Wert liegt außerhalb des zulässigen Bereichs',
    'v.paramsRequired': 'Preis oder Rabatt erforderlich',
    'v.promoLessThanRegular': 'Der Aktionspreis muss unter dem regulären Preis liegen',
    'v.requiredIsEmpty': 'Erforderlich',
    'v.zeroNegative': 'Der angegebene Wert ist Null oder negativ',
    bg: 'Български',
    cs: 'Český',
    de: 'Deutsch',
    'de_AT': 'Deutsch (AT)',
    'de_CH': 'Deutsch (CH)',
    en: 'English',
    fr: 'Français',
    hr: 'Hrvatski',
    hu: 'Magyar',
    nl: 'Nederlands',
    pl: 'Polski',
    ro: 'Română',
    sk: 'Slovenský',
    sl: 'Slovenščina',
    it: 'Italiano'
  };
  
  export default de;
